import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromMetrics from './metrics.reducer';
import { MetricsEffects } from './metrics.effects';
import { MetricsFacade } from './metrics.facade';
import { MetricsWebsocket } from './metrics.websocket';
import { AnalyticsMetaReducer } from './metrics-meta.reducer';

export const metaReducers: MetaReducer<any>[] = [AnalyticsMetaReducer];

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromMetrics.METRICS_FEATURE_KEY, fromMetrics.metricsReducer, { metaReducers }),
    EffectsModule.forFeature([MetricsEffects]),
  ],
  providers: [MetricsFacade, MetricsWebsocket],
})
export class MetricsModule {}
