import { Injectable } from '@angular/core';
import { WebsocketFacade } from '@app/libs/websocket';
import { LocalStorageKeys } from '@app/shared/interfaces/lib/storage.interface';
import { getBoolean } from '@app/shared/utils';
import { Channel as PusherChannel } from 'pusher-js';

export enum MetricsWebsocketEvent {
  ExportSent = 'metrics.export.sent',
  ExportFailed = 'metrics.export.failed',
}

export interface Channel {
  name: string;
  events?: { name: string; callback: () => void }[];
  registeredChannel?: PusherChannel | undefined;
}

@Injectable()
export class MetricsWebsocket {
  constructor(private websocketFacade: WebsocketFacade) {}

  init() {
    const isCS = getBoolean(localStorage.getItem(LocalStorageKeys.IsCS));
    if (isCS) {
      return;
    }

    const userChannel = this.websocketFacade.getUserChannelName();

    if (userChannel) {
      this.registerChannelEvents(userChannel, [
        {
          name: MetricsWebsocketEvent.ExportSent,
          callback: () => {
            console.log('MetricsWebsocketEvent.ExportSent');
          },
        },
      ]);
    }
  }

  registerChannelEvents(channelName: string, events: Channel['events']) {
    const channel = this.websocketFacade.findChannel(channelName);

    if (channel && events) {
      events.forEach((event) => {
        console.log(channel, event);
        if (channel && channel.registeredChannel) {
          channel.registeredChannel.bind(event.name, (e: any) => {
            console.log(`${channel.name} with event ${event.name} fired`, e);
            event.callback();
          });
        }
      });
    }
  }
}
