import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as MetricsActions from './metrics.actions';
import * as MetricsSelectors from './metrics.selectors';
import { Filter } from '@app/shared/interfaces/lib/filter.interface';
import { Currency, MetricPayload, Metrics, SaveMetricViewPayload } from './metrics.models';

@Injectable()
export class MetricsFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  // Currencies
  currenciesLoadingState$ = this.store.pipe(select(MetricsSelectors.getCurrenciesLoadingState));
  currencies$ = this.store.pipe(select(MetricsSelectors.getCurrencies));
  defaultCurrency$ = this.store.pipe(select(MetricsSelectors.getDefaultCurrency));
  updateDefaultCurrencyLoadingState$ = this.store.pipe(select(MetricsSelectors.updateDefaultCurrencyLoadingState));
  conversionRates$ = this.store.pipe(select(MetricsSelectors.getConversionRates));

  // Reservations
  exportReservationMetricsLoadingState$ = this.store.pipe(select(MetricsSelectors.exportReservationMetricsLoadingState));
  reservationCountLoadingState$ = this.store.pipe(select(MetricsSelectors.getReservationCountLoadingState));
  reservationCount$ = this.store.pipe(select(MetricsSelectors.getReservationCount));

  reservationViewsLoadingState$ = this.store.pipe(select(MetricsSelectors.getReservationViewsLoadingState));
  reservationViews$ = this.store.pipe(select(MetricsSelectors.getReservationViews));
  reservationViewsMetaPagination$ = this.store.pipe(select(MetricsSelectors.getReservationViewsMetaPagination));
  activeReservationView$ = this.store.pipe(select(MetricsSelectors.getActiveReservationView));
  saveReservationViewLoadingState$ = this.store.pipe(select(MetricsSelectors.saveReservationViewLoadingState));
  updateReservationViewLoadingState$ = this.store.pipe(select(MetricsSelectors.updateReservationViewLoadingState));
  deleteReservationViewLoadingState$ = this.store.pipe(select(MetricsSelectors.deleteReservationViewLoadingState));

  reservationFiltersLoadingState$ = this.store.pipe(select(MetricsSelectors.getReservationFiltersLoadingState));
  reservationFilters$ = this.store.pipe(select(MetricsSelectors.getReservationFilters));

  reservationAppliedFilters$ = this.store.pipe(select(MetricsSelectors.getReservationAppliedFilters));
  reservationDetailsLoadingState$ = this.store.pipe(select(MetricsSelectors.getReservationDetailsLoadingState));
  reservationDetails$ = this.store.pipe(select(MetricsSelectors.getReservationDetails));
  reservationStatisticsLoadingState$ = this.store.pipe(select(MetricsSelectors.getReservationStatisticsLoadingState));
  reservationStatistics$ = this.store.pipe(select(MetricsSelectors.getReservationStatistics));
  reservationDataTooLarge$ = this.store.pipe(select(MetricsSelectors.getReservationDataTooLargeMeta));
  reservationStatisticsConversionRates$ = this.store.pipe(select(MetricsSelectors.getReservationStatisticsConversionRates));
  reservationGraphsLoadingState$ = this.store.pipe(select(MetricsSelectors.getReservationGraphsLoadingState));
  reservationGraphs$ = this.store.pipe(select(MetricsSelectors.getReservationGraphs));

  // Earlier Exports
  earlierExportsLoadingState$ = this.store.pipe(select(MetricsSelectors.getEarlierExportsLoadingState));
  earlierExports$ = this.store.pipe(select(MetricsSelectors.getEarlierExports));
  earlierExportsMetaPagination$ = this.store.pipe(select(MetricsSelectors.getEarlierExportsMetaPagination));

  // Taxes
  taxesViewsLoadingState$ = this.store.pipe(select(MetricsSelectors.getTaxesViewsLoadingState));
  taxesViews$ = this.store.pipe(select(MetricsSelectors.getTaxesViews));
  taxesViewsMetaPagination$ = this.store.pipe(select(MetricsSelectors.getTaxesViewsMetaPagination));
  activeTaxesView$ = this.store.pipe(select(MetricsSelectors.getActiveTaxesView));
  saveTaxesViewLoadingState$ = this.store.pipe(select(MetricsSelectors.saveTaxesViewLoadingState));
  updateTaxesViewLoadingState$ = this.store.pipe(select(MetricsSelectors.updateTaxesViewLoadingState));
  deleteTaxesViewLoadingState$ = this.store.pipe(select(MetricsSelectors.deleteTaxesViewLoadingState));
  exportTaxesMetricsLoadingState$ = this.store.pipe(select(MetricsSelectors.exportTaxesMetricsLoadingState));
  taxesCountLoadingState$ = this.store.pipe(select(MetricsSelectors.getTaxesCountLoadingState));
  taxesCount$ = this.store.pipe(select(MetricsSelectors.getTaxesCount));
  taxesFiltersLoadingState$ = this.store.pipe(select(MetricsSelectors.getTaxesFiltersLoadingState));
  taxesFilters$ = this.store.pipe(select(MetricsSelectors.getTaxesFilters));
  taxesAppliedFilters$ = this.store.pipe(select(MetricsSelectors.getTaxesAppliedFilters));
  taxesDetailsLoadingState$ = this.store.pipe(select(MetricsSelectors.getTaxesDetailsLoadingState));
  taxesDetails$ = this.store.pipe(select(MetricsSelectors.getTaxesDetails));
  taxesStatisticsLoadingState$ = this.store.pipe(select(MetricsSelectors.getTaxesStatisticsLoadingState));
  taxesStatistics$ = this.store.pipe(select(MetricsSelectors.getTaxesStatistics));
  taxesDataTooLarge$ = this.store.pipe(select(MetricsSelectors.getTaxesDataTooLargeMeta));
  taxesGraphsLoadingState$ = this.store.pipe(select(MetricsSelectors.getTaxesGraphsLoadingState));
  taxesGraphs$ = this.store.pipe(select(MetricsSelectors.getTaxesGraphs));
  taxesSummaryLoadingState$ = this.store.pipe(select(MetricsSelectors.getTaxesSummaryLoadingState));
  taxesSummary$ = this.store.pipe(select(MetricsSelectors.getTaxesSummary));

  // Reviews
  reviewsViewsLoadingState$ = this.store.pipe(select(MetricsSelectors.getReviewsViewsLoadingState));
  reviewsViews$ = this.store.pipe(select(MetricsSelectors.getReviewsViews));
  reviewsViewsMetaPagination$ = this.store.pipe(select(MetricsSelectors.getReviewsViewsMetaPagination));
  activeReviewsView$ = this.store.pipe(select(MetricsSelectors.getActiveReviewsView));
  saveReviewsViewLoadingState$ = this.store.pipe(select(MetricsSelectors.saveReviewsViewLoadingState));
  updateReviewsViewLoadingState$ = this.store.pipe(select(MetricsSelectors.updateReviewsViewLoadingState));
  deleteReviewsViewLoadingState$ = this.store.pipe(select(MetricsSelectors.deleteReviewsViewLoadingState));

  exportReviewsMetricsLoadingState$ = this.store.pipe(select(MetricsSelectors.exportReviewsMetricsLoadingState));
  reviewsCountLoadingState$ = this.store.pipe(select(MetricsSelectors.getReviewsCountLoadingState));
  reviewsCount$ = this.store.pipe(select(MetricsSelectors.getReviewsCount));
  reviewsFiltersLoadingState$ = this.store.pipe(select(MetricsSelectors.getReviewsFiltersLoadingState));
  reviewsFilters$ = this.store.pipe(select(MetricsSelectors.getReviewsFilters));
  reviewsAppliedFilters$ = this.store.pipe(select(MetricsSelectors.getReviewsAppliedFilters));
  reviewsDetailsLoadingState$ = this.store.pipe(select(MetricsSelectors.getReviewsDetailsLoadingState));
  reviewsDetails$ = this.store.pipe(select(MetricsSelectors.getReviewsDetails));
  reviewsStatisticsLoadingState$ = this.store.pipe(select(MetricsSelectors.getReviewsStatisticsLoadingState));
  reviewsStatistics$ = this.store.pipe(select(MetricsSelectors.getReviewsStatistics));
  reviewsDataTooLarge$ = this.store.pipe(select(MetricsSelectors.getReviewsDataTooLargeMeta));
  reviewsGraphsLoadingState$ = this.store.pipe(select(MetricsSelectors.getReviewsGraphsLoadingState));
  reviewsGraphs$ = this.store.pipe(select(MetricsSelectors.getReviewsGraphs));

  // Tasks
  tasksViewsLoadingState$ = this.store.pipe(select(MetricsSelectors.getTasksViewsLoadingState));
  tasksViews$ = this.store.pipe(select(MetricsSelectors.getTasksViews));
  tasksViewsMetaPagination$ = this.store.pipe(select(MetricsSelectors.getTasksViewsMetaPagination));
  activeTasksView$ = this.store.pipe(select(MetricsSelectors.getActiveTasksView));
  saveTasksViewLoadingState$ = this.store.pipe(select(MetricsSelectors.saveTasksViewLoadingState));
  updateTasksViewLoadingState$ = this.store.pipe(select(MetricsSelectors.updateTasksViewLoadingState));
  deleteTasksViewLoadingState$ = this.store.pipe(select(MetricsSelectors.deleteTasksViewLoadingState));
  exportTasksMetricsLoadingState$ = this.store.pipe(select(MetricsSelectors.exportTasksMetricsLoadingState));
  tasksCountLoadingState$ = this.store.pipe(select(MetricsSelectors.getTasksCountLoadingState));
  tasksCount$ = this.store.pipe(select(MetricsSelectors.getTasksCount));
  tasksFiltersLoadingState$ = this.store.pipe(select(MetricsSelectors.getTasksFiltersLoadingState));
  tasksFilters$ = this.store.pipe(select(MetricsSelectors.getTasksFilters));
  tasksAppliedFilters$ = this.store.pipe(select(MetricsSelectors.getTasksAppliedFilters));
  tasksDetailsLoadingState$ = this.store.pipe(select(MetricsSelectors.getTasksDetailsLoadingState));
  tasksDetails$ = this.store.pipe(select(MetricsSelectors.getTasksDetails));

  constructor(private readonly store: Store) {}

  loadCurrencies() {
    this.store.dispatch(MetricsActions.loadCurrencies());
  }

  updateDefaultCurrency(payload: Currency, metricsToReload: Metrics) {
    this.store.dispatch(MetricsActions.updateDefaultCurrency({ payload, metricsToReload }));
  }

  loadReservationCount(oneFilePerProperty: boolean) {
    this.store.dispatch(
      MetricsActions.loadReservationCount({
        oneFilePerProperty,
      })
    );
  }

  exportReservationMetrics(payload: MetricPayload) {
    this.store.dispatch(
      MetricsActions.exportReservationMetrics({
        payload,
      })
    );
  }

  loadReservationViews() {
    this.store.dispatch(MetricsActions.loadReservationViews());
  }

  saveReservationView(payload: SaveMetricViewPayload) {
    this.store.dispatch(MetricsActions.saveReservationView({ payload }));
  }
  updateReservationView(uuid: string, payload: SaveMetricViewPayload) {
    this.store.dispatch(MetricsActions.updateReservationView({ uuid, payload }));
  }

  deleteReservationView(uuid: string) {
    this.store.dispatch(MetricsActions.deleteReservationView({ uuid }));
  }

  switchActiveReservationView(selectedViewUUID: string) {
    this.store.dispatch(MetricsActions.switchReservationView({ payload: selectedViewUUID }));
  }

  clearActiveReservationView() {
    this.store.dispatch(MetricsActions.clearReservationView());
  }

  loadReservationFilters() {
    this.store.dispatch(MetricsActions.loadReservationFilters());
  }

  addReservationFilter(filter: Filter) {
    this.store.dispatch(MetricsActions.addReservationFilter({ filter }));
  }

  applyReservationFilter(filter: Filter) {
    this.store.dispatch(MetricsActions.applyReservationFilter({ filter }));
  }

  removeReservationFilter(filter: Filter) {
    this.store.dispatch(MetricsActions.removeReservationFilter({ filter }));
  }

  loadReservationDetails(payload: MetricPayload, page: number) {
    this.store.dispatch(
      MetricsActions.loadReservationDetails({
        payload,
        page,
      })
    );
  }

  loadReservationStatistics(payload: MetricPayload) {
    this.store.dispatch(
      MetricsActions.loadReservationStatistics({
        payload,
      })
    );
  }

  loadReservationGraphs(payload: MetricPayload) {
    this.store.dispatch(
      MetricsActions.loadReservationGraphs({
        payload,
      })
    );
  }

  loadEarlierExports(page: number) {
    this.store.dispatch(
      MetricsActions.loadEarlierExports({
        page,
      })
    );
  }

  downloadExport(uuid: string, filename: string) {
    this.store.dispatch(
      MetricsActions.downloadExport({
        uuid,
        filename,
      })
    );
  }

  // Taxes
  loadTaxesViews() {
    this.store.dispatch(MetricsActions.loadTaxesViews());
  }

  saveTaxesView(payload: SaveMetricViewPayload) {
    this.store.dispatch(MetricsActions.saveTaxesView({ payload }));
  }
  updateTaxesView(uuid: string, payload: SaveMetricViewPayload) {
    this.store.dispatch(MetricsActions.updateTaxesView({ uuid, payload }));
  }

  deleteTaxesView(uuid: string) {
    this.store.dispatch(MetricsActions.deleteTaxesView({ uuid }));
  }

  switchActiveTaxesView(selectedViewUUID: string) {
    this.store.dispatch(MetricsActions.switchTaxesView({ payload: selectedViewUUID }));
  }

  clearActiveTaxesView() {
    this.store.dispatch(MetricsActions.clearTaxesView());
  }

  loadTaxesCount(oneFilePerProperty: boolean) {
    this.store.dispatch(
      MetricsActions.loadTaxesCount({
        oneFilePerProperty,
      })
    );
  }

  exportTaxesMetrics(payload: MetricPayload) {
    this.store.dispatch(
      MetricsActions.exportTaxesMetrics({
        payload,
      })
    );
  }

  loadTaxesFilters() {
    this.store.dispatch(MetricsActions.loadTaxesFilters());
  }

  addTaxesFilter(filter: Filter) {
    this.store.dispatch(MetricsActions.addTaxesFilter({ filter }));
  }

  applyTaxesFilter(filter: Filter) {
    this.store.dispatch(MetricsActions.applyTaxesFilter({ filter }));
  }

  removeTaxesFilter(filter: Filter) {
    this.store.dispatch(MetricsActions.removeTaxesFilter({ filter }));
  }

  loadTaxesSummary(payload: MetricPayload, page: number) {
    this.store.dispatch(
      MetricsActions.loadTaxesSummary({
        payload,
        page,
      })
    );
  }

  loadTaxesDetails(payload: MetricPayload, page: number) {
    this.store.dispatch(
      MetricsActions.loadTaxesDetails({
        payload,
        page,
      })
    );
  }

  loadTaxesStatistics(payload: MetricPayload) {
    this.store.dispatch(
      MetricsActions.loadTaxesStatistics({
        payload,
      })
    );
  }

  loadTaxesGraphs(payload: MetricPayload) {
    this.store.dispatch(
      MetricsActions.loadTaxesGraphs({
        payload,
      })
    );
  }

  // Reviews
  loadReviewsViews() {
    this.store.dispatch(MetricsActions.loadReviewsViews());
  }

  saveReviewsView(payload: SaveMetricViewPayload) {
    this.store.dispatch(MetricsActions.saveReviewsView({ payload }));
  }
  updateReviewsView(uuid: string, payload: SaveMetricViewPayload) {
    this.store.dispatch(MetricsActions.updateReviewsView({ uuid, payload }));
  }

  deleteReviewsView(uuid: string) {
    this.store.dispatch(MetricsActions.deleteReviewsView({ uuid }));
  }

  switchActiveReviewsView(selectedViewUUID: string) {
    this.store.dispatch(MetricsActions.switchReviewsView({ payload: selectedViewUUID }));
  }
  clearActiveReviewsView() {
    this.store.dispatch(MetricsActions.clearReviewsView());
  }

  loadReviewsCount(oneFilePerProperty: boolean) {
    this.store.dispatch(
      MetricsActions.loadReviewsCount({
        oneFilePerProperty,
      })
    );
  }

  exportReviewsMetrics(payload: MetricPayload) {
    this.store.dispatch(
      MetricsActions.exportReviewsMetrics({
        payload,
      })
    );
  }

  loadReviewsFilters() {
    this.store.dispatch(MetricsActions.loadReviewsFilters());
  }

  addReviewsFilter(filter: Filter) {
    this.store.dispatch(MetricsActions.addReviewsFilter({ filter }));
  }

  applyReviewsFilter(filter: Filter) {
    this.store.dispatch(MetricsActions.applyReviewsFilter({ filter }));
  }

  removeReviewsFilter(filter: Filter) {
    this.store.dispatch(MetricsActions.removeReviewsFilter({ filter }));
  }

  loadReviewsDetails(payload: MetricPayload, page: number) {
    this.store.dispatch(
      MetricsActions.loadReviewsDetails({
        payload,
        page,
      })
    );
  }

  loadReviewsStatistics(payload: MetricPayload) {
    this.store.dispatch(
      MetricsActions.loadReviewsStatistics({
        payload,
      })
    );
  }

  loadReviewsGraphs(payload: MetricPayload) {
    this.store.dispatch(
      MetricsActions.loadReviewsGraphs({
        payload,
      })
    );
  }

  // Tasks
  loadTasksViews() {
    this.store.dispatch(MetricsActions.loadTasksViews());
  }

  saveTasksView(payload: SaveMetricViewPayload) {
    this.store.dispatch(MetricsActions.saveTasksView({ payload }));
  }
  updateTasksView(uuid: string, payload: SaveMetricViewPayload) {
    this.store.dispatch(MetricsActions.updateTasksView({ uuid, payload }));
  }

  deleteTasksView(uuid: string) {
    this.store.dispatch(MetricsActions.deleteTasksView({ uuid }));
  }

  switchActiveTasksView(selectedViewUUID: string) {
    this.store.dispatch(MetricsActions.switchTasksView({ payload: selectedViewUUID }));
  }
  clearActiveTasksView() {
    this.store.dispatch(MetricsActions.clearTasksView());
  }

  loadTasksCount(oneFilePerProperty: boolean) {
    this.store.dispatch(
      MetricsActions.loadTasksCount({
        oneFilePerProperty,
      })
    );
  }

  exportTasksMetrics(payload: MetricPayload) {
    this.store.dispatch(
      MetricsActions.exportTasksMetrics({
        payload,
      })
    );
  }

  loadTasksFilters() {
    this.store.dispatch(MetricsActions.loadTasksFilters());
  }

  addTasksFilter(filter: Filter) {
    this.store.dispatch(MetricsActions.addTasksFilter({ filter }));
  }

  applyTasksFilter(filter: Filter) {
    this.store.dispatch(MetricsActions.applyTasksFilter({ filter }));
  }

  removeTasksFilter(filter: Filter) {
    this.store.dispatch(MetricsActions.removeTasksFilter({ filter }));
  }

  loadTasksDetails(payload: MetricPayload, page: number) {
    this.store.dispatch(
      MetricsActions.loadTasksDetails({
        payload,
        page,
      })
    );
  }
}
