import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SegmentEvent } from '@app/shared/interfaces';

import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'sbnb-segment-events-page',
  template: `
    <div class="max-w-[750px] m-auto flex flex-col gap-3">
      <h1 class="text-center text-white">Segment events from frontend application</h1>
      <div class="w-full flex items-center justify-center bg-grape-darker sticky top-0 z-10 py-2 box-shadow-heavy">
        <mat-form-field class="!text-white nopadding">
          <mat-label>Search events</mat-label>
          <mat-icon matPrefix>search</mat-icon>
          <input [formControl]="searchControl" type="text" matInput placeholder="Search..." />
        </mat-form-field>
      </div>
      <div class="w-full bg-smoke-lighter rounded-md p-4">
        <div class="flex flex-col gap-2 px-3 max-w-[450px] ">
          <div *ngFor="let event of filteredEvents$ | async">
            <sbnb-copy-to-clipboard [content]="event">{{ event }}</sbnb-copy-to-clipboard>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SegmentEventsPageComponent implements OnInit {
  public events = Object.entries(SegmentEvent)
    .sort((a, b) => a[1].localeCompare(b[1]))
    .map(([key, value]) => value);

  public searchControl = new FormControl('');
  public filteredEvents$ = this.searchControl.valueChanges.pipe(
    startWith(''),
    debounceTime(300),
    distinctUntilChanged(),
    map((search?: string) => {
      if (!search?.length) {
        return this.events;
      }
      const modSearch = search.toLowerCase().replace(/[-_\s]/g, '');
      return this.events.filter((value) =>
        value
          .toLowerCase()
          .replace(/[-_\s]/g, '')
          .includes(modSearch)
      );
    })
  );
  constructor() {}

  ngOnInit(): void {}
}
