import { Filter, FilterColumnKey, FilterInterval, FilterKey, FilterStrategy } from "@app/shared/interfaces/lib/filter.interface";

export interface MetricCurrencies {
  currencies: Currency[];
  default_currency: string | null;
}

export interface Currency {
  currency: string;
  symbol?: string;
}

export interface DataTooLargeMeta {
  data_too_large: boolean;
}

export enum Metrics {
  Reservations = 'Reservations',
  Taxes = 'Taxes',
  Reviews = 'Reviews',
  Tasks = 'Tasks',
  EarlierExports = 'Earlier Exports',
}

export enum MetricKey {
  Reservations = 'reservations',
  Taxes = 'taxes',
  Reviews = 'reviews',
  Tasks = 'tasks',
}

export interface MetricFiltersResponse {
  filters: Filter[];
  key: MetricKey;
}

export interface MetricFilterLabelPayload {
  payload: {
    column: FilterColumnKey;
    interval_type: FilterInterval;
    strategy: FilterStrategy;
  };
}

export interface MetricFilterLabelResponse {
  has_payload?: boolean;
  label: string;
  values: string[];
}
export interface MetricDetailsTable {
  [key: string]: string;
}

export interface MetricConversionRate {
  base_currency: string;
  to_currency: string;
  rate: string;
}

// Reservations

export interface MetricPayload {
  one_file_per_property?: boolean;
  include_kpis?: boolean;
  filters: {
    [key in FilterKey]: unknown;
  };
}

export interface ExportMetrics {
  uuid: string;
  status: string;
  type: string;
  email: string;
  scope: {
    date: {
      column: FilterColumnKey;
      strategy: FilterStrategy;
    };
    include_unlisted: boolean;
  };
  scope_for_humans: {
    label: string;
    filters: {
      date: {
        has_payload: boolean;
        label: string;
        values: string[];
      };
      include_unlisted: {
        has_payload: boolean;
        label: string;
        values: string[];
      };
    };
  };
  scope_for_tooltip: {
    label: string;
    filters: {
      date: {
        label: string;
        values: string[];
      };
      include_unlisted: {
        label: string;
        values: string[];
      };
    };
  };
  one_file_per_property: boolean;
  include_kpis?: boolean;
  file_name: string;
  file_size: string;
  sent_at: string;
  failed_at: string;
}

export interface MetricStatistics {
  statistics: MetricStatistic[];
  conversion_rates: MetricConversionRate[];
}

export interface MetricStatistic {
  identifier: string;
  title: string;
  value: string;
  previous_value: string;
  difference: number;
  tooltip: string;
}

export interface MetricGraphs {
  graphs: MetricGraph[];
  conversion_rates: MetricConversionRate[];
}

export interface MetricGraph {
  title: string;
  total: number;
  values: {
    title: string;
    value: number;
    previous_value?: number;
    difference: number;
    formatted?: string;
  }[];
}

export interface MetricView {
  uuid: string;
  name: string;
  key: MetricKey; // type of metric eg reservations

  filters: Filter[];
  publicly_available?: boolean;
  public_link?: string;

  created_at?: string;
  updated_at?: string;
}

export interface SaveMetricViewPayload extends MetricPayload {
  name: string;
  publicly_available: boolean;
}

export enum ExportType {
  Single = 'single',
  Multiple = 'multiple',
}

// Earlier Exports
export enum ExportStatus {
  Ready = 'ready',
}

export interface MetricEarlierExports {
  uuid: string;
  status: ExportStatus;
  type: string;
  email: string;
  scope: {
    [key in FilterKey]: unknown;
  };
  scope_for_humans: {
    label: string;
    filters: {
      [key in FilterKey]: unknown;
    };
  };
  scope_for_tooltip: {
    label: string;
    filters: {
      [key in FilterKey]: {
        label: string;
        values: string[];
      };
    };
  };
  one_file_per_property: boolean;
  file_name: string;
  file_size: number;
  sent_at: string;
  failed_at: string;
  created_at: string;
}
