import { createAction, props } from '@ngrx/store';
import {
  ExportMetrics,
  MetricEarlierExports,
  MetricDetailsTable,
  MetricFilterLabelPayload,
  MetricFilterLabelResponse,
  MetricFiltersResponse,
  MetricGraphs,
  MetricPayload,
  MetricStatistics,
  MetricCurrencies,
  Currency,
  Metrics,
  DataTooLargeMeta,
  MetricView,
  SaveMetricViewPayload,
} from './metrics.models';
import { ApiErrorResponse, ApiResponse } from '@app/shared/interfaces/lib/api.interface';
import { Pagination } from '@app/shared/interfaces/lib/pagination.interface';
import { Filter } from '@app/shared/interfaces/lib/filter.interface';

// Currencies
export const loadCurrencies = createAction('[Metrics] Load Currencies');
export const loadCurrenciesSuccess = createAction('[Metrics] Load Currencies Success', props<{ response: ApiResponse<MetricCurrencies> }>());
export const loadCurrenciesFailure = createAction('[Metrics] Load Currencies Failure', props<{ response: ApiErrorResponse }>());

export const updateDefaultCurrency = createAction('[Metrics] Update Default Currency', props<{ payload: Currency; metricsToReload: Metrics }>());
export const updateDefaultCurrencySuccess = createAction(
  '[Metrics] Update Default Success',
  props<{ response: ApiResponse<Currency>; metricsToReload: Metrics }>()
);
export const updateDefaultCurrencyFailure = createAction('[Metrics] Update Default Failure', props<{ response: ApiErrorResponse }>());

// Reservations
export const loadReservationViews = createAction('[Metrics] Load Reservation Views');
export const loadReservationViewsSuccess = createAction(
  '[Metrics] Load Reservation Views Success',
  props<{ response: ApiResponse<MetricView[], { pagination: Pagination }> }>()
);
export const loadReservationViewsFailure = createAction('[Metrics] Load Reservation Views Failure', props<{ response: ApiErrorResponse }>());

export const saveReservationView = createAction('[Metrics] Save Reservation View', props<{ payload: SaveMetricViewPayload }>());
export const saveReservationViewsSuccess = createAction('[Metrics] Save Reservation View Success', props<{ response: ApiResponse<MetricView> }>());
export const saveReservationViewsFailure = createAction('[Metrics] Save Reservation View Failure', props<{ response: ApiErrorResponse }>());

export const switchReservationView = createAction('[Metrics] Switch Reservation View', props<{ payload: MetricView['uuid'] }>());
export const clearReservationView = createAction('[Metrics] Clear Reservation View');

export const updateReservationView = createAction('[Metrics] Update Reservation View', props<{ uuid: string; payload: SaveMetricViewPayload }>());
export const updateReservationViewSuccess = createAction('[Metrics] Update Reservation View Success', props<{ response: ApiResponse<MetricView> }>());
export const updateReservationViewFailure = createAction('[Metrics] Update Reservation View Failure', props<{ response: ApiErrorResponse }>());

export const deleteReservationView = createAction('[Metrics] Delete Reservation View', props<{ uuid: string }>());
export const deleteReservationViewSuccess = createAction('[Metrics] Delete Reservation View Success', props<{ uuid: string; response: unknown }>());
export const deleteReservationViewFailure = createAction('[Metrics] Delete Reservation View Failure', props<{ response: ApiErrorResponse }>());

export const reloadReservationMetrics = createAction('[Metrics] Reload Reservation Metrics');
export const loadReservationFilters = createAction('[Metrics] Load Reservation Filters');
export const loadReservationFiltersSuccess = createAction(
  '[Metrics] Load Reservation Filters Success',
  props<{ response: ApiResponse<MetricFiltersResponse> }>()
);
export const loadReservationFiltersFailure = createAction('[Metrics] Load Reservation Filters Failure', props<{ response: ApiErrorResponse }>());
export const addReservationFilter = createAction('[Metrics] Add Reservation Filter', props<{ filter: Filter }>());
export const bulkApplyReservationFilters = createAction('[Metrics] Bulk Apply Reservation Filter', props<{ filters: Filter[] }>());
export const applyReservationFilter = createAction('[Metrics] Apply Reservation Filter', props<{ filter: Filter }>());
export const removeReservationFilter = createAction('[Metrics] Remove Reservation Filter', props<{ filter: Filter }>());
export const loadReservationFilterLabel = createAction(
  '[Metrics] Load Reservation Filter Label',
  props<{ filter: Filter; payload: MetricFilterLabelPayload }>()
);
export const loadReservationFilterLabelSuccess = createAction(
  '[Metrics] Load Reservation Filter Label Success',
  props<{ filter: Filter; response: ApiResponse<MetricFilterLabelResponse> }>()
);
export const loadReservationFilterLabelFailure = createAction(
  '[Metrics] Load Reservation Filter Label Failure',
  props<{ response: ApiErrorResponse }>()
);
export const loadReservationCount = createAction('[Metrics] Load Reservation Count', props<{ oneFilePerProperty: boolean }>());
export const loadReservationCountSuccess = createAction('[Metrics] Load Reservation Count Success', props<{ response: number }>());
export const loadReservationCountFailure = createAction('[Metrics] Load Reservation Count Failure', props<{ response: ApiErrorResponse }>());
export const loadReservationDetails = createAction('[Metrics] Load Reservation Details', props<{ payload: MetricPayload; page?: number }>());
export const loadReservationDetailsSuccess = createAction(
  '[Metrics] Load Reservation Details Success',
  props<{ response: ApiResponse<MetricDetailsTable[], { pagination: Pagination }> }>()
);
export const loadReservationDetailsFailure = createAction('[Metrics] Load Reservation Details Failure', props<{ response: ApiErrorResponse }>());
export const loadReservationStatistics = createAction('[Metrics] Load Reservation Statistics', props<{ payload: MetricPayload }>());
export const loadReservationStatisticsSuccess = createAction(
  '[Metrics] Load Reservation Statistics Success',
  props<{ response: ApiResponse<MetricStatistics, DataTooLargeMeta> }>()
);
export const loadReservationStatisticsFailure = createAction(
  '[Metrics] Load Reservation Statistics Failure',
  props<{ response: ApiErrorResponse }>()
);
export const loadReservationGraphs = createAction('[Metrics] Load Reservation Graphs', props<{ payload: MetricPayload }>());
export const loadReservationGraphsSuccess = createAction(
  '[Metrics] Load Reservation Graphs Success',
  props<{ response: ApiResponse<MetricGraphs> }>()
);
export const loadReservationGraphsFailure = createAction('[Metrics] Load Reservation Graphs Failure', props<{ response: ApiErrorResponse }>());
export const exportReservationMetrics = createAction('[Metrics] Export Reservation Metrics', props<{ payload: MetricPayload }>());
export const exportReservationMetricsSuccess = createAction(
  '[Metrics] Export Reservation Metrics Success',
  props<{ response: ApiResponse<ExportMetrics> }>()
);
export const exportReservationMetricsFailure = createAction('[Metrics] Export Reservation Metrics Failure', props<{ response: ApiErrorResponse }>());

// Earlier Exports
export const loadEarlierExports = createAction('[Metrics] Load Earlier Exports', props<{ page?: number }>());
export const loadEarlierExportsSuccess = createAction(
  '[Metrics] Load Earlier Exports Success',
  props<{ response: ApiResponse<MetricEarlierExports[], { pagination: Pagination }> }>()
);
export const loadEarlierExportsFailure = createAction('[Metrics] Load Earlier Exports Failure', props<{ response: ApiErrorResponse }>());
export const downloadExport = createAction('[Metrics] Download Export', props<{ uuid: string; filename: string }>());
export const downloadExportSuccess = createAction('[Metrics] Download Export Success', props<{ response: any }>());
export const downloadExportFailure = createAction('[Metrics] Download Export Failure', props<{ response: ApiErrorResponse }>());

// Taxes
export const loadTaxesViews = createAction('[Metrics] Load Taxes Views');
export const loadTaxesViewsSuccess = createAction(
  '[Metrics] Load Taxes Views Success',
  props<{ response: ApiResponse<MetricView[], { pagination: Pagination }> }>()
);
export const loadTaxesViewsFailure = createAction('[Metrics] Load Taxes Views Failure', props<{ response: ApiErrorResponse }>());

export const saveTaxesView = createAction('[Metrics] Save Taxes View', props<{ payload: SaveMetricViewPayload }>());
export const saveTaxesViewsSuccess = createAction('[Metrics] Save Taxes View Success', props<{ response: ApiResponse<MetricView> }>());
export const saveTaxesViewsFailure = createAction('[Metrics] Save Taxes View Failure', props<{ response: ApiErrorResponse }>());

export const switchTaxesView = createAction('[Metrics] Switch Taxes View', props<{ payload: MetricView['uuid'] }>());
export const clearTaxesView = createAction('[Metrics] Clear Taxes View');

export const updateTaxesView = createAction('[Metrics] Update Taxes View', props<{ uuid: string; payload: SaveMetricViewPayload }>());
export const updateTaxesViewSuccess = createAction('[Metrics] Update Taxes View Success', props<{ response: ApiResponse<MetricView> }>());
export const updateTaxesViewFailure = createAction('[Metrics] Update Taxes View Failure', props<{ response: ApiErrorResponse }>());

export const deleteTaxesView = createAction('[Metrics] Delete Taxes View', props<{ uuid: string }>());
export const deleteTaxesViewSuccess = createAction('[Metrics] Delete Taxes View Success', props<{ uuid: string; response: unknown }>());
export const deleteTaxesViewFailure = createAction('[Metrics] Delete Taxes View Failure', props<{ response: ApiErrorResponse }>());

export const reloadTaxesMetrics = createAction('[Metrics] Reload Taxes Metrics');
export const loadTaxesCount = createAction('[Metrics] Load Taxes Count', props<{ oneFilePerProperty: boolean }>());
export const loadTaxesCountSuccess = createAction('[Metrics] Load Taxes Count Success', props<{ response: number }>());
export const loadTaxesCountFailure = createAction('[Metrics] Load Taxes Count Failure', props<{ response: ApiErrorResponse }>());

export const exportTaxesMetrics = createAction('[Metrics] Export Taxes Metrics', props<{ payload: MetricPayload }>());
export const exportTaxesMetricsSuccess = createAction('[Metrics] Export Taxes Metrics Success', props<{ response: ApiResponse<ExportMetrics> }>());
export const exportTaxesMetricsFailure = createAction('[Metrics] Export Taxes Metrics Failure', props<{ response: ApiErrorResponse }>());

export const loadTaxesFilters = createAction('[Metrics] Load Taxes Filters');
export const loadTaxesFiltersSuccess = createAction(
  '[Metrics] Load Taxes Filters Success',
  props<{ response: ApiResponse<MetricFiltersResponse> }>()
);
export const loadTaxesFiltersFailure = createAction('[Metrics] Load Taxes Filters Failure', props<{ response: ApiErrorResponse }>());
export const addTaxesFilter = createAction('[Metrics] Add Taxes Filter', props<{ filter: Filter }>());
export const bulkApplyTaxesFilters = createAction('[Metrics] Bulk Apply Taxes Filter', props<{ filters: Filter[] }>());
export const applyTaxesFilter = createAction('[Metrics] Apply Taxes Filter', props<{ filter: Filter }>());
export const removeTaxesFilter = createAction('[Metrics] Remove Taxes Filter', props<{ filter: Filter }>());
export const loadTaxesFilterLabel = createAction('[Metrics] Load Taxes Filter Label', props<{ filter: Filter; payload: MetricFilterLabelPayload }>());
export const loadTaxesFilterLabelSuccess = createAction(
  '[Metrics] Load Taxes Filter Label Success',
  props<{ filter: Filter; response: ApiResponse<MetricFilterLabelResponse> }>()
);
export const loadTaxesFilterLabelFailure = createAction('[Metrics] Load Taxes Filter Label Failure', props<{ response: ApiErrorResponse }>());
export const loadTaxesStatistics = createAction('[Metrics] Load Taxes Statistics', props<{ payload: MetricPayload }>());
export const loadTaxesStatisticsSuccess = createAction(
  '[Metrics] Load Taxes Statistics Success',
  props<{ response: ApiResponse<MetricStatistics, DataTooLargeMeta> }>()
);
export const loadTaxesStatisticsFailure = createAction('[Metrics] Load Taxes Statistics Failure', props<{ response: ApiErrorResponse }>());
export const loadTaxesGraphs = createAction('[Metrics] Load Taxes Graphs', props<{ payload: MetricPayload }>());
export const loadTaxesGraphsSuccess = createAction('[Metrics] Load Taxes Graphs Success', props<{ response: ApiResponse<MetricGraphs> }>());
export const loadTaxesGraphsFailure = createAction('[Metrics] Load Taxes Graphs Failure', props<{ response: ApiErrorResponse }>());
export const loadTaxesDetails = createAction('[Metrics] Load Taxes Details', props<{ payload: MetricPayload; page?: number }>());
export const loadTaxesDetailsSuccess = createAction(
  '[Metrics] Load Taxes Details Success',
  props<{ response: ApiResponse<MetricDetailsTable[], { pagination: Pagination }> }>()
);
export const loadTaxesDetailsFailure = createAction('[Metrics] Load Taxes Details Failure', props<{ response: ApiErrorResponse }>());

export const loadTaxesSummary = createAction('[Metrics] Load Taxes Summary', props<{ payload: MetricPayload; page?: number }>());
export const loadTaxesSummarySuccess = createAction(
  '[Metrics] Load Taxes Summary Success',
  props<{ response: ApiResponse<MetricDetailsTable[], { pagination: Pagination }> }>()
);
export const loadTaxesSummaryFailure = createAction('[Metrics] Load Taxes Summary Failure', props<{ response: ApiErrorResponse }>());

// Reviews
export const loadReviewsViews = createAction('[Metrics] Load Reviews Views');
export const loadReviewsViewsSuccess = createAction(
  '[Metrics] Load Reviews Views Success',
  props<{ response: ApiResponse<MetricView[], { pagination: Pagination }> }>()
);
export const loadReviewsViewsFailure = createAction('[Metrics] Load Reviews Views Failure', props<{ response: ApiErrorResponse }>());

export const saveReviewsView = createAction('[Metrics] Save Reviews View', props<{ payload: SaveMetricViewPayload }>());
export const saveReviewsViewsSuccess = createAction('[Metrics] Save Reviews View Success', props<{ response: ApiResponse<MetricView> }>());
export const saveReviewsViewsFailure = createAction('[Metrics] Save Reviews View Failure', props<{ response: ApiErrorResponse }>());

export const switchReviewsView = createAction('[Metrics] Switch Reviews View', props<{ payload: MetricView['uuid'] }>());
export const clearReviewsView = createAction('[Metrics] Clear Reviews View');

export const updateReviewsView = createAction('[Metrics] Update Reviews View', props<{ uuid: string; payload: SaveMetricViewPayload }>());
export const updateReviewsViewSuccess = createAction('[Metrics] Update Reviews View Success', props<{ response: ApiResponse<MetricView> }>());
export const updateReviewsViewFailure = createAction('[Metrics] Update Reviews View Failure', props<{ response: ApiErrorResponse }>());

export const deleteReviewsView = createAction('[Metrics] Delete Reviews View', props<{ uuid: string }>());
export const deleteReviewsViewSuccess = createAction('[Metrics] Delete Reviews View Success', props<{ uuid: string; response: unknown }>());
export const deleteReviewsViewFailure = createAction('[Metrics] Delete Reviews View Failure', props<{ response: ApiErrorResponse }>());

export const reloadReviewsMetrics = createAction('[Metrics] Reload Reviews Metrics');
export const loadReviewsCount = createAction('[Metrics] Load Reviews Count', props<{ oneFilePerProperty: boolean }>());
export const loadReviewsCountSuccess = createAction('[Metrics] Load Reviews Count Success', props<{ response: number }>());
export const loadReviewsCountFailure = createAction('[Metrics] Load Reviews Count Failure', props<{ response: ApiErrorResponse }>());

export const exportReviewsMetrics = createAction('[Metrics] Export Reviews Metrics', props<{ payload: MetricPayload }>());
export const exportReviewsMetricsSuccess = createAction(
  '[Metrics] Export Reviews Metrics Success',
  props<{ response: ApiResponse<ExportMetrics> }>()
);
export const exportReviewsMetricsFailure = createAction('[Metrics] Export Reviews Metrics Failure', props<{ response: ApiErrorResponse }>());

export const loadReviewsFilters = createAction('[Metrics] Load Reviews Filters');
export const loadReviewsFiltersSuccess = createAction(
  '[Metrics] Load Reviews Filters Success',
  props<{ response: ApiResponse<MetricFiltersResponse> }>()
);
export const loadReviewsFiltersFailure = createAction('[Metrics] Load Reviews Filters Failure', props<{ response: ApiErrorResponse }>());
export const addReviewsFilter = createAction('[Metrics] Add Reviews Filter', props<{ filter: Filter }>());
export const bulkApplyReviewsFilters = createAction('[Metrics] Bulk Apply Reviews Filter', props<{ filters: Filter[] }>());
export const applyReviewsFilter = createAction('[Metrics] Apply Reviews Filter', props<{ filter: Filter }>());
export const removeReviewsFilter = createAction('[Metrics] Remove Reviews Filter', props<{ filter: Filter }>());
export const loadReviewsFilterLabel = createAction(
  '[Metrics] Load Reviews Filter Label',
  props<{ filter: Filter; payload: MetricFilterLabelPayload }>()
);
export const loadReviewsFilterLabelSuccess = createAction(
  '[Metrics] Load Reviews Filter Label Success',
  props<{ filter: Filter; response: ApiResponse<MetricFilterLabelResponse> }>()
);
export const loadReviewsFilterLabelFailure = createAction('[Metrics] Load Reviews Filter Label Failure', props<{ response: ApiErrorResponse }>());
export const loadReviewsStatistics = createAction('[Metrics] Load Reviews Statistics', props<{ payload: MetricPayload }>());
export const loadReviewsStatisticsSuccess = createAction(
  '[Metrics] Load Reviews Statistics Success',
  props<{ response: ApiResponse<MetricStatistics, DataTooLargeMeta> }>()
);
export const loadReviewsStatisticsFailure = createAction('[Metrics] Load Reviews Statistics Failure', props<{ response: ApiErrorResponse }>());
export const loadReviewsGraphs = createAction('[Metrics] Load Reviews Graphs', props<{ payload: MetricPayload }>());
export const loadReviewsGraphsSuccess = createAction('[Metrics] Load Reviews Graphs Success', props<{ response: ApiResponse<MetricGraphs> }>());
export const loadReviewsGraphsFailure = createAction('[Metrics] Load Reviews Graphs Failure', props<{ response: ApiErrorResponse }>());
export const loadReviewsDetails = createAction('[Metrics] Load Reviews Details', props<{ payload: MetricPayload; page?: number }>());
export const loadReviewsDetailsSuccess = createAction(
  '[Metrics] Load Reviews Details Success',
  props<{ response: ApiResponse<MetricDetailsTable[], { pagination: Pagination }> }>()
);
export const loadReviewsDetailsFailure = createAction('[Metrics] Load Reviews Details Failure', props<{ response: ApiErrorResponse }>());

// Tasks
export const loadTasksViews = createAction('[Metrics] Load Tasks Views');
export const loadTasksViewsSuccess = createAction(
  '[Metrics] Load Tasks Views Success',
  props<{ response: ApiResponse<MetricView[], { pagination: Pagination }> }>()
);
export const loadTasksViewsFailure = createAction('[Metrics] Load Tasks Views Failure', props<{ response: ApiErrorResponse }>());

export const saveTasksView = createAction('[Metrics] Save Tasks View', props<{ payload: SaveMetricViewPayload }>());
export const saveTasksViewsSuccess = createAction('[Metrics] Save Tasks View Success', props<{ response: ApiResponse<MetricView> }>());
export const saveTasksViewsFailure = createAction('[Metrics] Save Tasks View Failure', props<{ response: ApiErrorResponse }>());

export const switchTasksView = createAction('[Metrics] Switch Tasks View', props<{ payload: MetricView['uuid'] }>());
export const clearTasksView = createAction('[Metrics] Clear Tasks View');

export const updateTasksView = createAction('[Metrics] Update Tasks View', props<{ uuid: string; payload: SaveMetricViewPayload }>());
export const updateTasksViewSuccess = createAction('[Metrics] Update Tasks View Success', props<{ response: ApiResponse<MetricView> }>());
export const updateTasksViewFailure = createAction('[Metrics] Update Tasks View Failure', props<{ response: ApiErrorResponse }>());

export const deleteTasksView = createAction('[Metrics] Delete Tasks View', props<{ uuid: string }>());
export const deleteTasksViewSuccess = createAction('[Metrics] Delete Tasks View Success', props<{ uuid: string; response: unknown }>());
export const deleteTasksViewFailure = createAction('[Metrics] Delete Tasks View Failure', props<{ response: ApiErrorResponse }>());

export const reloadTasksMetrics = createAction('[Metrics] Reload Tasks Metrics');
export const loadTasksCount = createAction('[Metrics] Load Tasks Count', props<{ oneFilePerProperty: boolean }>());
export const loadTasksCountSuccess = createAction('[Metrics] Load Tasks Count Success', props<{ response: number }>());
export const loadTasksCountFailure = createAction('[Metrics] Load Tasks Count Failure', props<{ response: ApiErrorResponse }>());

export const exportTasksMetrics = createAction('[Metrics] Export Tasks Metrics', props<{ payload: MetricPayload }>());
export const exportTasksMetricsSuccess = createAction('[Metrics] Export Tasks Reviews Success', props<{ response: ApiResponse<ExportMetrics> }>());
export const exportTasksMetricsFailure = createAction('[Metrics] Export Tasks Reviews Failure', props<{ response: ApiErrorResponse }>());

export const loadTasksFilters = createAction('[Metrics] Load Tasks Filters');
export const loadTasksFiltersSuccess = createAction(
  '[Metrics] Load Tasks Filters Success',
  props<{ response: ApiResponse<MetricFiltersResponse> }>()
);
export const loadTasksFiltersFailure = createAction('[Metrics] Load Tasks Filters Failure', props<{ response: ApiErrorResponse }>());
export const addTasksFilter = createAction('[Metrics] Add Tasks Filter', props<{ filter: Filter }>());
export const bulkApplyTasksFilters = createAction('[Metrics] Bulk Apply Tasks Filter', props<{ filters: Filter[] }>());
export const applyTasksFilter = createAction('[Metrics] Apply Tasks Filter', props<{ filter: Filter }>());
export const removeTasksFilter = createAction('[Metrics] Remove Tasks Filter', props<{ filter: Filter }>());
export const loadTasksFilterLabel = createAction('[Metrics] Load Tasks Tasks Label', props<{ filter: Filter; payload: MetricFilterLabelPayload }>());
export const loadTasksFilterLabelSuccess = createAction(
  '[Metrics] Load Tasks Filter Label Success',
  props<{ filter: Filter; response: ApiResponse<MetricFilterLabelResponse> }>()
);
export const loadTasksFilterLabelFailure = createAction('[Metrics] Load Tasks Filter Label Failure', props<{ response: ApiErrorResponse }>());
export const loadTasksDetails = createAction('[Metrics] Load Tasks Details', props<{ payload: MetricPayload; page?: number }>());
export const loadTasksDetailsSuccess = createAction(
  '[Metrics] Load Tasks Details Success',
  props<{ response: ApiResponse<MetricDetailsTable[], { pagination: Pagination }> }>()
);
export const loadTasksDetailsFailure = createAction('[Metrics] Load Tasks Details Failure', props<{ response: ApiErrorResponse }>());
