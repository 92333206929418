<div fxLayout="column" fxLayoutAlign="space-between">
  <div fxFlex>
    <h3>{{ dialogData.action || 'Delete' }} {{ dialogData.name }}</h3>

    <ul *ngIf="dialogData.list && dialogData.list.length > 0">
      <li *ngFor="let item of dialogData.list">
        {{ item }}
      </li>
    </ul>

    <p data-testid="confirmation-message" class="strong text-night">
      Are you sure you want to {{ (dialogData.action | lowercase) || 'delete' }} {{ dialogData.name }}{{ identifier }}?
    </p>

    <div *ngIf="dialogData.additionalText" class="additionalText" [innerHTML]="dialogData.additionalText"></div>
  </div>

  <div fxLayoutAlign="end center" fxLayoutGap="8px" style="margin-top: 16px">
    <button type="submit" mat-flat-button class="button__secondary" (click)="cancelClicked()">Cancel</button>
    <button type="submit" mat-flat-button (click)="okClicked()">{{ dialogData.action || 'Delete' }}</button>
  </div>
</div>
