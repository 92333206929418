import { ScrollingModule } from '@angular/cdk/scrolling';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule, LAYOUT_CONFIG } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AdditionalGuestsBadgeComponent } from '@app/shared/components/additional-guests-badge/additional-guests-badge.component';
import { AppVersionComponent } from '@app/shared/components/app-version/app-version.component';
import { ArticleLinkComponent } from '@app/shared/components/article-link/article-link.component';
import { ArticleListComponent } from '@app/shared/components/article-list/article-list.component';
import { ChannelsImageComponent } from '@app/shared/components/channels-image/channels-image.component';
import {
  DeviceUpsellButtonComponent,
  DeviceUpsellButtonContentDirective,
} from '@app/shared/components/device-upsell-button/device-upsell-button.component';
import { DialogRequestAdhocGuestVettingComponent } from '@app/shared/components/dialog-request-adhoc-guest-vetting/dialog-request-adhoc-guest-vetting.component';
import { DialogReviewGuestComponent } from '@app/shared/components/dialog-review-guest/dialog-review-guest.component';
import { GuestPortalSidebarLinkComponent } from '@app/shared/components/guest-portal-sidebar-link/guest-portal-sidebar-link.component';
import { GuestReviewsComponent } from '@app/shared/components/guest-reviews/guest-reviews.component';
import { GuestSummaryComponent } from '@app/shared/components/guest-summary/guest-summary.component';
import { GxSubnavComponent } from '@app/shared/components/gx-subnav/gx-subnav.component';
import { LogoComponent } from '@app/shared/components/logo/logo.component';
import { OnesignalPushNotificationHandlerComponent } from '@app/shared/components/onesignal-push-notification-handler/onesignal-push-notification-handler.component';
import { OperationsSubnavComponent } from '@app/shared/components/operations-subnav/operations-subnav.component';
import { RentalAgreementsDetailsDialogComponent } from '@app/shared/components/rental-agreements-details-dialog/rental-agreements-details-dialog.component';
import { RentalAgreementsSidebarListComponent } from '@app/shared/components/rental-agreements-sidebar-list/rental-agreements-sidebar-list.component';
import { ReservationDetailsComponent } from '@app/shared/components/reservation-details/reservation-details.component';
import { SettingsSmartlocksComponent } from '@app/shared/components/settings-smartlocks/settings-smartlocks.component';
import { SettingsThermostatsComponent } from '@app/shared/components/settings-thermostats/settings-thermostats.component';
import { SupportDocumentationIconComponent } from '@app/shared/components/support-documentation-icon/support-documentation-icon.component';
import { SupportDocumentationTitleComponent } from '@app/shared/components/support-documentation-title/support-documentation-title.component';
import { TaxSelectionComponent } from '@app/shared/components/tax-selection/tax-selection.component';
import { ReceivedReviewComponent } from '@app/shared/components/thread-messages/received-review/received-review.component';
import { YearInReviewCtaComponent } from '@app/shared/components/year-in-review-cta/year-in-review-cta.component';
import { PasteHandlerDirective } from '@app/shared/directives/paste-image-handler.directive';
import { TributeDirective } from '@app/shared/directives/tribute.directive';
import { IconIcalPlatformComponent } from '@app/shared/icons/icon-ical-platform/icon-ical-platform.component';
import { CurrentTimeInTzPipe } from '@app/shared/pipes/current-time-in-tz.pipe';
import { GuestVettingTotalPipe } from '@app/shared/pipes/guest-vetting-total.pipe';
import { IsFuturePipe } from '@app/shared/pipes/is-future';
import { IsPastPipe } from '@app/shared/pipes/is-past';
import { IsUpdatedWithin30DaysPipe } from '@app/shared/pipes/is-updated-within30-days.pipe';
import { LinkifyjsPipe } from '@app/shared/pipes/linkifyj.pipe';
import { UiModule } from '@app/ui';
import { environment } from '@env/environment';
import { FilestackModule } from '@filestack/angular';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AndroidProminentDisclosureComponent } from '../shared/components/android-prominent-disclosure/android-prominent-disclosure.component';
import { ChatgptGenerateButtonComponent } from '../shared/components/chatgpt-generate-button/chatgpt-generate-button.component';
import { CommandKComponent } from '../shared/components/command-k/command-k.component';
import { ContentHeaderComponent } from '../shared/components/content-header/content-header.component';
import { DialogCancelReservationFlowComponent } from '../shared/components/dialog-cancel-reservation-flow/dialog-cancel-reservation-flow.component';
import { DialogCancelReservationInitiatedByComponent } from '../shared/components/dialog-cancel-reservation-initiated-by/dialog-cancel-reservation-initiated-by.component';
import { DialogGuestVettingExtendedDetailsComponent } from '../shared/components/dialog-guest-vetting-extended-details/dialog-guest-vetting-extended-details.component';
import { GuestVettingLineItemComponent } from '../shared/components/guest-vetting-line-item/guest-vetting-line-item.component';
import { GuestVettingStatusComponent } from '../shared/components/guest-vetting-status/guest-vetting-status.component';
import { ImageCarouselComponent } from '../shared/components/image-carousel/image-carousel.component';
import { InputOtpComponent } from '../shared/components/input-otp/input-otp.component';
import { TurnoActivateComponent } from '../shared/components/turno-activate/turno-activate.component';
import { ScrollNearEndDirective } from '../shared/directives/scroll-near-end.directive';
import { RulePlatformScopeIconsComponent } from '../shared/icons/rule-platform-scope-icons/rule-platform-scope-icons.component';
import { AlterationIsAirbnbPipe } from '../shared/pipes/alteration-is-airbnb.pipe';
import { DoesPropertyImageHaveTagsPipe } from '../shared/pipes/does-property-image-have-tags.pipe';
import { SortPropertyImageTagsPipe } from '../shared/pipes/sort-property-image-tags.pipe';
import { AIStateModule } from './+state/AI/AI-state.module';
import { BillingStateModule } from './+state/billing/billing-state.module';
import { ListingsStateModule } from './+state/listings/listings-state.module';
import { OptimizelyStateModule } from './+state/optimizely/optimizely-state.module';
import { PropertiesStateModule } from './+state/properties/properties-state.module';
import { UsersStateModule } from './+state/users/users-state.module';
import { ActivityFeedComponent } from './components/activity-feed/activity-feed.component';
import { AddManualBookingBannerComponent } from './components/add-manual-booking-banner/add-manual-booking-banner.component';
import { AddManualBookingButtonComponent } from './components/add-manual-booking-button/add-manual-booking-button.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { BookingDetailsComponent } from './components/booking-details/booking-details.component';
import { ButtonComponent } from './components/button/button.component';
import { CheckinActivityListComponent } from './components/checkin-activity-list/checkin-activity-list.component';
import { CsDebugItemComponent } from './components/cs-debug-item/cs-debug-item.component';
import { CustomCodeConditionsComponent } from './components/custom-code-conditions/custom-code-conditions.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DevicesSubnavComponent } from './components/devices-subnav/devices-subnav.component';
import { DirectQuoteConfirmComponent } from './components/dialog-add-direct-booking/components/direct-quote-confirm/direct-quote-confirm.component';
import { DirectQuoteGuestDetailsComponent } from './components/dialog-add-direct-booking/components/direct-quote-guest-details/direct-quote-guest-details.component';
import { DirectQuoteHoldDurationComponent } from './components/dialog-add-direct-booking/components/direct-quote-hold-duration/direct-quote-hold-duration.component';
import { DirectQuoteSetAmountComponent } from './components/dialog-add-direct-booking/components/direct-quote-set-amount/direct-quote-set-amount.component';
import { DialogAddDirectQuoteComponent } from './components/dialog-add-direct-booking/dialog-add-direct-quote.component';
import { DialogAddManualBookingComponent } from './components/dialog-add-manual-booking/dialog-add-manual-booking.component';
import { DialogCancelReservationAmountComponent } from './components/dialog-cancel-reservation-amount/dialog-cancel-reservation-amount.component';
import { DialogCancelReservationReviewComponent } from './components/dialog-cancel-reservation-review/dialog-cancel-reservation-review.component';
import { DialogCreateCustomCodeComponent } from './components/dialog-create-custom-code/dialog-create-custom-code.component';
import { DialogDeleteGenericComponent } from './components/dialog-delete-generic/dialog-delete-generic.component';
import { DialogDeleteRuleComponent } from './components/dialog-delete-rule/dialog-delete-rule.component';
import { DialogDeleteSegmentComponent } from './components/dialog-delete-segment/dialog-delete-segment.component';
import { DialogEditAiReviewComponent } from './components/dialog-edit-ai-review/dialog-edit-ai-review.component';
import { DialogEditMessageComponent } from './components/dialog-edit-message/dialog-edit-message.component';
import { DialogEditReviewComponent } from './components/dialog-edit-review/dialog-edit-review.component';
import { DialogHostsComponent } from './components/dialog-hosts/dialog-hosts.component';
import { DialogMutePropertyComponent } from './components/dialog-mute-property/dialog-mute-property.component';
import { DialogPreviewMessageComponent } from './components/dialog-preview-message/dialog-preview-message.component';
import { DialogPropertiesComponent } from './components/dialog-properties/dialog-properties.component';
import { DialogReservationDetailsComponent } from './components/dialog-reservation-details/dialog-reservation-details.component';
import { DialogSaveSegmentComponent } from './components/dialog-save-segment/dialog-save-segment.component';
import { DialogSendAlterationRequestComponent } from './components/dialog-send-alteration-request/dialog-send-alteration-request.component';
import { DialogThreadDetailsComponent } from './components/dialog-thread-details/dialog-thread-details.component';
import { EditorSmartCodesComponent } from './components/editor-smart-codes/editor-smart-codes.component';
import { EmptyStateTemplateComponent } from './components/empty-state-template/empty-state-template.component';
import { FilterComponent } from './components/filter/filter.component';
import { FinancialSectionComponent } from './components/financial-section/financial-section.component';
import { FormEditReviewComponent } from './components/form-edit-review/form-edit-review.component';
import { GuestBannerThreadComponent } from './components/guest-banner-thread/guest-banner-thread.component';
import { GuestBannerComponent } from './components/guest-banner/guest-banner.component';
import { GuestEmailComponent } from './components/guest-email/guest-email.component';
import { HelptextWrapperComponent } from './components/helptext-wrapper/helptext-wrapper.component';
import { HostsComponent } from './components/hosts/hosts.component';
import { ImageGalleryComponent } from './components/image-gallery/image-gallery.component';
import { InsightsComponent } from './components/insights/insights.component';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import { MenuTaskAssignComponent } from './components/menu-task-assign/menu-task-assign.component';
import { MiniCalendarComponent } from './components/mini-calendar/mini-calendar.component';
import { MiniListingImgComponent } from './components/mini-listing-img/mini-listing-img.component';
import { MobileSidebarComponent } from './components/mobile-sidebar/mobile-sidebar.component';
import { NavComponent } from './components/nav/nav.component';
import { NoItemsComponent } from './components/no-items/no-items.component';
import { NoteComponent } from './components/note/note.component';
import { OnboardingBannerComponent } from './components/onboarding-banner/onboarding-banner.component';
import { OnboardingHelperComponent } from './components/onboarding-helper/onboarding-helper.component';
import { OverviewBoxComponent } from './components/overview-box/overview-box.component';
import { PageLevelCtaComponent } from './components/page-level-cta/page-level-cta.component';
import { PageLoadingComponent } from './components/page-loading/page-loading.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PillComponent } from './components/pill/pill.component';
import { PrevNextComponent } from './components/prev-next/prev-next.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { PropertiesComponent } from './components/properties/properties.component';
import { PropertySelectionComponent } from './components/properties/property-selection/property-selection.component';
import { PropertyCardComponent } from './components/property-card/property-card.component';
import { PropertySelectDropdownComponent } from './components/property-select-dropdown/property-select-dropdown.component';
import { ReceivedReviewFormComponent } from './components/received-review-form/received-review-form.component';
import { ReportAProblemButtonComponent } from './components/report-a-problem-button/report-a-problem-button.component';
import { ReservationCardComponent } from './components/reservation-card/reservation-card.component';
import { ResizerComponent } from './components/resizer/resizer.component';
import { ResolveConversationComponent } from './components/resolve-conversation/resolve-conversation.component';
import { ReviewScoresSummaryComponent } from './components/review-scores-summary/review-scores-summary.component';
import { RichTextEditorComponent } from './components/rich-text-editor/rich-text-editor.component';
import { RuleEditorComponent } from './components/rule-editor/rule-editor.component';
import { RuleScopeComponent } from './components/rule-scope/rule-scope.component';
import { RuleSettingsComponent } from './components/rule-settings/rule-settings.component';
import { RuleTimingRecurringComponent } from './components/rule-timing-recurring/rule-timing-recurring.component';
import { RuleTimingComponent } from './components/rule-timing/rule-timing.component';
import { SaveIndicatorComponent } from './components/save-indicator/save-indicator.component';
import { SavedSegmentsComponent } from './components/saved-segments/saved-segments.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { SentimentLabelComponent } from './components/sentiment-label/sentiment-label.component';
import { StarConversationComponent } from './components/star-conversation/star-conversation.component';
import { SubnavComponent } from './components/subnav/subnav.component';
import { TaskListComponent } from './components/task-list/task-list.component';
import { TaskRuleTimingRecurringComponent } from './components/task-rule-timing-recurring/task-rule-timing-recurring.component';
import { TeamIconComponent } from './components/team-icon/team-icon.component';
import { TeamRoleBadgeComponent } from './components/team-role-badge/team-role-badge.component';
import { ThermostatsComponent } from './components/thermostats/thermostats.component';
import { BookingRequestComponent } from './components/thread-messages/booking-request/booking-request.component';
import { IssueDetectedComponent } from './components/thread-messages/issue-detected/issue-detected.component';
import { MessageReactionsComponent } from './components/thread-messages/message-reactions/message-reactions.component';
import { MessageComponent } from './components/thread-messages/message/message.component';
import { PendingQuoteComponent } from './components/thread-messages/pending-quote/pending-quote.component';
import { ReservationIconComponent } from './components/thread-messages/reservation-icon/reservation-icon.component';
import { SendReviewComponent } from './components/thread-messages/send-review/send-review.component';
import { ThreadMessagesComponent } from './components/thread-messages/thread-messages.component';
import { TimestampComponent } from './components/thread-messages/timestamp/timestamp.component';
import { ThreadNotesComponent } from './components/thread-notes/thread-notes.component';
import { ThreadSmartlockCodesComponent } from './components/thread-smartlock-codes/thread-smartlock-codes.component';
import { ThreadThermostatSchedulesComponent } from './components/thread-thermostat-schedules/thread-thermostat-schedules.component';
import { TimePickerComponent } from './components/time-picker/time-picker/time-picker.component';
import { TimelineComponentModule } from './components/timeline/timeline.component.module';
import { ToastContainerComponent } from './components/toast/toast-container.component';
import { ToastComponent } from './components/toast/toast.component';
import { TranslateConversationComponent } from './components/translate-conversation/translate-conversation.component';
import { TruncateComponent } from './components/truncate/truncate.component';
import { UpsellsStatusComponent } from './components/upsells-status/upsells-status.component';
import { VideoCardGridComponent } from './components/video-card-grid/video-card-grid.component';
import { VideoCardComponent } from './components/video-card/video-card.component';
import { CustomerSupportDebugDirective } from './directives/customer-support-debug.directive';
import { IconAgodaComponent } from './icons/icon-agoda/icon-agoda.component';
import { IconAirbnbComponent } from './icons/icon-airbnb/icon-airbnb.component';
import { IconBookingComponent } from './icons/icon-booking/icon-booking.component';
import { IconEmailComponent } from './icons/icon-email/icon-email.component';
import { IconHomeawayComponent } from './icons/icon-homeaway/icon-homeaway.component';
import { IconPhoneComponent } from './icons/icon-phone/icon-phone.component';
import { IconPlatformComponent } from './icons/icon-platform/icon-platform.component';
import { IconSmartbnbComponent } from './icons/icon-smartbnb/icon-smartbnb.component';
import { FullScreenModalComponent } from './layouts/full-screen-modal/full-screen-modal.component';
import { AlterationDisplayGuestsNumbersPipe } from './pipes/alteration-display-guest-numbers/alteration-display-guest-numbers.pipe';
import { AlterationHasNumberOfGuestsChangedPipe } from './pipes/alteration-has-number-of-guests-changed/alteration-has-number-of-guests-changed.pipe';
import { AlterationHaveDatesChangedPipe } from './pipes/alteration-have-dates-changed/alteration-have-dates-changed.pipe';
import { ArrayToListPipe } from './pipes/array-to-list.pipe';
import { BookingQuickSetupFormatIssues } from './pipes/booking-quick-setup-format-issues/booking-quick-setup-format-issues.pipe';
import { BookingQuickSetupGetUniqueIssues } from './pipes/booking-quick-setup-get-unique-issues/booking-quick-setup-get-unique-issues.pipe';
import { ConditionToTextPipe } from './pipes/conditionToText/condition-to-text.pipe';
import { CurrencyCodePipe } from './pipes/currency-code/currency-code.pipe';
import { DoesRuleHavePlatformPipe } from './pipes/doesRuleHavePlatform/does-rule-have-platform.pipe';
import { EventWidthTaskPipe } from './pipes/event-width-task/event-width-task.pipe';
import { EventWidthPipe } from './pipes/event-width/event-width.pipe';
import { FunctionPipe } from './pipes/function/function.pipe';
import { GetPropertyPipe } from './pipes/getProperty/get-property.pipe';
import { HasOverlapWithOtherReservationsPipe } from './pipes/has-overlap-with-other-reservations/has-overlap-with-other-reservations.pipe';
import { HasOverlapWithOtherTasksPipe } from './pipes/has-overlap-with-other-tasks/has-overlap-with-other-tasks.pipe';
import { HasVisibleSegmentsPipe } from './pipes/hasVisibleSegments/has-visible-segments.pipe';
import { HumanizePipe } from './pipes/humanize/humanize.pipe';
import { IncompleteParentCountPipe } from './pipes/incomplete-parent-count.pipe';
import { IsAfterTodayPipe } from './pipes/is-after-today/is-after-today.service';
import { IsAlterationMessagePipe } from './pipes/is-alteration-message.pipe';
import { IsBeforeTodayPipe } from './pipes/is-before-today/is-before-today.pipe';
import { IsChatMessagePipe } from './pipes/is-chat-message.pipe';
import { IsDateSelectedPipe } from './pipes/is-date-selected/is-date-selected.pipe';
import { IsEventMessagePipe } from './pipes/is-event-message.pipe';
import { IsReservationMessagePipe } from './pipes/is-reservation-message.pipe';
import { IsThisMonthPipe } from './pipes/is-this-month/is-this-month.pipe';
import { IsTodayPipe } from './pipes/is-today/is-today.pipe';
import { IsTomorrowPipe } from './pipes/is-tomorrow/is-tomorrow.pipe';
import { IsWeekendPipe } from './pipes/is-weekend/is-weekend.pipe';
import { IsSameDayBookingPipe } from './pipes/isSameDayBooking/is-same-day-booking.pipe';
import { MatchHostSearchCriteriaPipe } from './pipes/matchHostSearchCriteria/match-host-search-criteria.pipe';
import { MatchPlatformSearchCriteriaPipe } from './pipes/matchPlatformSearchCriteria/match-platform-search-criteria.pipe';
import { MatchPropertySearchCriteriaPipe } from './pipes/matchPropertySearchCriteria/match-property-search-criteria.pipe';
import { Nl2brPipe } from './pipes/nl2br/nl2br.pipe';
import { NumNightsPipe } from './pipes/num-nights/num-nights.pipe';
import { PluralizePipe } from './pipes/pluralize.pipe';
import { RemoveMinusSignPipe } from './pipes/remove-minus-sign.pipe';
import { SafePipe } from './pipes/safe/safe.pipe';
import { SecondsToMetricsDisplayPipe } from './pipes/seconds-to-metrics-display.pipe';
import { SecondsToSmartTimePipe } from './pipes/secondsToSmartTime/seconds-to-smart-time.pipe';
import { ShortDateRangePipe } from './pipes/short-date-range.pipe';
import { SortByPipe } from './pipes/sortBy/sort-by.pipe';
import { SubTotalMinusDiscountsPipe } from './pipes/subtotal-minus-discounts/subtotal-minus-discounts.pipe';
import { TaskOverlapPipe } from './pipes/task-overlap/task-overlap.pipe';
import { TaskWidthPipe } from './pipes/task-width/task-width.pipe';
import { TimeAgoPipe } from './pipes/time-ago/time-ago.pipe';
import { ToSentencePipe } from './pipes/to-sentence.pipe';
import { AppliedFilterButtonComponent } from '@app/shared/components/filter-mono/applied-filter-button/applied-filter-button.component';
import { FiltersComponent } from '@app/shared/components/filter-mono/filters.component';
import { FilterMenuDateComponent } from '@app/shared/components/filter-mono/filter-menu-date/filter-menu-date.component';
import { FilterMenuSelectComponent } from '@app/shared/components/filter-mono/filter-menu-select/filter-menu-select.component';
import { IconComponent } from '@app/shared/components/icon-mono/icon.component';
import { MetricWidgetComponent } from '@app/shared/components/metric-widget-mono/metric-widget.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    ChannelsImageComponent,
    DialogReviewGuestComponent,
    LogoComponent,
    YearInReviewCtaComponent,
    GuestVettingTotalPipe,
    GuestPortalSidebarLinkComponent,
    PasteHandlerDirective,
    OperationsSubnavComponent,
    AppVersionComponent,
    DialogRequestAdhocGuestVettingComponent,
    OnesignalPushNotificationHandlerComponent,
    FullScreenModalComponent,
    ThreadNotesComponent,
    HostsComponent,
    DialogHostsComponent,
    NavComponent,
    RuleSettingsComponent,
    RentalAgreementsSidebarListComponent,
    RentalAgreementsDetailsDialogComponent,
    SubnavComponent,
    LanguageSwitcherComponent,
    DialogCreateCustomCodeComponent,
    DialogDeleteRuleComponent,
    TimeAgoPipe,
    PaginationComponent,
    MiniCalendarComponent,
    TimePickerComponent,
    GxSubnavComponent,
    SearchBarComponent,
    FilterComponent,
    DialogSaveSegmentComponent,
    SavedSegmentsComponent,
    ActivityFeedComponent,
    Nl2brPipe,
    HumanizePipe,
    IsSameDayBookingPipe,
    ReservationCardComponent,
    DialogDeleteSegmentComponent,
    MatchPropertySearchCriteriaPipe,
    MatchHostSearchCriteriaPipe,
    MatchPlatformSearchCriteriaPipe,
    DoesRuleHavePlatformPipe,
    DialogEditMessageComponent,
    RuleEditorComponent,
    DialogEditReviewComponent,
    SafePipe,
    ConditionToTextPipe,
    CustomCodeConditionsComponent,
    RuleScopeComponent,
    SaveIndicatorComponent,
    TeamIconComponent,
    DialogDeleteGenericComponent,
    SecondsToSmartTimePipe,
    IsTodayPipe,
    NumNightsPipe,
    IsFuturePipe,
    IsPastPipe,
    IsThisMonthPipe,
    IsWeekendPipe,
    IsTomorrowPipe,
    IsBeforeTodayPipe,
    IsAfterTodayPipe,
    HasOverlapWithOtherTasksPipe,
    HasOverlapWithOtherReservationsPipe,
    EventWidthPipe,
    IsDateSelectedPipe,
    TaskWidthPipe,
    EventWidthTaskPipe,
    TaskOverlapPipe,
    RuleTimingComponent,
    MenuTaskAssignComponent,
    NoItemsComponent,
    HelptextWrapperComponent,
    HasVisibleSegmentsPipe,
    SortByPipe,
    FunctionPipe,
    DialogThreadDetailsComponent,
    PageLoadingComponent,
    MobileSidebarComponent,
    IsUpdatedWithin30DaysPipe,
    IconAirbnbComponent,
    IconHomeawayComponent,
    IconBookingComponent,
    IconAgodaComponent,
    IconSmartbnbComponent,
    IconPlatformComponent,
    IconIcalPlatformComponent,
    PropertiesComponent,
    ThermostatsComponent,
    DialogPropertiesComponent,
    IconPhoneComponent,
    IconEmailComponent,
    TeamRoleBadgeComponent,
    PropertySelectionComponent,
    InsightsComponent,
    GuestBannerComponent,
    AdditionalGuestsBadgeComponent,
    BookingDetailsComponent,
    ThreadMessagesComponent,
    TaskListComponent,
    ThreadSmartlockCodesComponent,
    ThreadThermostatSchedulesComponent,
    ReportAProblemButtonComponent,
    IsTomorrowPipe,
    SecondsToMetricsDisplayPipe,
    RemoveMinusSignPipe,
    CsDebugItemComponent,
    ToastContainerComponent,
    ToastComponent,
    BackButtonComponent,
    DialogPreviewMessageComponent,
    PropertySelectDropdownComponent,
    OverviewBoxComponent,
    CheckinActivityListComponent,
    ButtonComponent,
    PillComponent,
    DialogMutePropertyComponent,
    PropertyCardComponent,
    EmptyStateTemplateComponent,
    OnboardingHelperComponent,
    IncompleteParentCountPipe,
    DialogAddManualBookingComponent,
    DialogAddDirectQuoteComponent,
    AddManualBookingButtonComponent,
    AddManualBookingBannerComponent,
    CurrencyCodePipe,
    GetPropertyPipe,
    IconBookingComponent,
    IconSmartbnbComponent,
    IconPlatformComponent,
    IconIcalPlatformComponent,
    OnboardingBannerComponent,
    DatePickerComponent,
    RuleTimingRecurringComponent,
    ImageGalleryComponent,
    TaskRuleTimingRecurringComponent,
    AlterationHaveDatesChangedPipe,
    AlterationHasNumberOfGuestsChangedPipe,
    AlterationDisplayGuestsNumbersPipe,
    DialogSendAlterationRequestComponent,
    TaxSelectionComponent,
    DialogCancelReservationFlowComponent,
    DialogCancelReservationInitiatedByComponent,
    DialogCancelReservationAmountComponent,
    DialogCancelReservationReviewComponent,
    AlterationIsAirbnbPipe,
    NoteComponent,
    GuestVettingStatusComponent,
    UpsellsStatusComponent,
    DialogGuestVettingExtendedDetailsComponent,
    GuestVettingLineItemComponent,
    ChatgptGenerateButtonComponent,
    TurnoActivateComponent,
    ProgressBarComponent,
    ContentHeaderComponent,
    RulePlatformScopeIconsComponent,
    PageLevelCtaComponent,
    SortPropertyImageTagsPipe,
    DoesPropertyImageHaveTagsPipe,
    CommandKComponent,
    BookingQuickSetupFormatIssues,
    BookingQuickSetupGetUniqueIssues,
    DirectQuoteGuestDetailsComponent,
    DirectQuoteSetAmountComponent,
    DirectQuoteHoldDurationComponent,
    DirectQuoteConfirmComponent,
    SubTotalMinusDiscountsPipe,
    ImageCarouselComponent,
    InputOtpComponent,
    ResizerComponent,
    TruncateComponent,
    AndroidProminentDisclosureComponent,
    PrevNextComponent,
    MiniListingImgComponent,
    SettingsSmartlocksComponent,
    SettingsThermostatsComponent,
    ScrollNearEndDirective,
    TributeDirective,
    DialogReservationDetailsComponent,
    ReservationDetailsComponent,
    GuestReviewsComponent,
    GuestSummaryComponent,
    ReceivedReviewComponent,
    ReceivedReviewFormComponent,
    TimestampComponent,
    MessageComponent,
    MessageReactionsComponent,
    IsChatMessagePipe,
    IsAlterationMessagePipe,
    IsReservationMessagePipe,
    IsEventMessagePipe,
    SendReviewComponent,
    DialogEditAiReviewComponent,
    FormEditReviewComponent,
    PluralizePipe,
    LinkifyjsPipe,
    FinancialSectionComponent,
    RichTextEditorComponent,
    DevicesSubnavComponent,
    DeviceUpsellButtonComponent,
    DeviceUpsellButtonContentDirective,
    BookingRequestComponent,
    PendingQuoteComponent,
    ReservationIconComponent,
    VideoCardComponent,
    VideoCardGridComponent,
    SupportDocumentationTitleComponent,
    SupportDocumentationIconComponent,
    ArticleLinkComponent,
    ArticleListComponent,
    EditorSmartCodesComponent,
    ReviewScoresSummaryComponent,
    CustomerSupportDebugDirective,
    ArrayToListPipe,
    GuestBannerThreadComponent,
    CurrentTimeInTzPipe,
    GuestEmailComponent,
    StarConversationComponent,
    TranslateConversationComponent,
    ResolveConversationComponent,
    ShortDateRangePipe,
    ToSentencePipe,
    IssueDetectedComponent,
    SentimentLabelComponent,
    FiltersComponent,
    FiltersComponent,
    AppliedFilterButtonComponent,
    FilterMenuDateComponent,
    FilterMenuSelectComponent,
    IconComponent,
    MetricWidgetComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    {
      ngModule: FlexLayoutModule,
      providers: [
        {
          provide: LAYOUT_CONFIG,
          useValue: {
            addFlexToParent: true,
            addOrientationBps: false,
            disableDefaultBps: false,
            disableVendorPrefixes: false,
            serverLoaded: false,
            useColumnBasisZero: false,
          },
        },
      ],
    },
    NgxMatSelectSearchModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    RecaptchaModule,
    RecaptchaFormsModule,
    ClipboardModule,
    NgxMatIntlTelInputModule,
    ScrollingModule,
    TextFieldModule,
    SatPopoverModule,
    FilestackModule.forRoot({ apikey: environment.filestack.apiKey }),
    ListingsStateModule,
    PropertiesStateModule,
    UsersStateModule,
    OptimizelyStateModule,
    TimelineComponentModule,
    AIStateModule,
    BillingStateModule,
    UiModule,
  ],
  exports: [
    DialogReviewGuestComponent,
    ChannelsImageComponent,
    LogoComponent,
    YearInReviewCtaComponent,
    OperationsSubnavComponent,
    GuestPortalSidebarLinkComponent,
    DialogRequestAdhocGuestVettingComponent,
    ImageCarouselComponent,
    AppVersionComponent,
    GetPropertyPipe,
    HumanizePipe,
    OnboardingBannerComponent,
    RentalAgreementsSidebarListComponent,
    RentalAgreementsDetailsDialogComponent,
    ThreadNotesComponent,
    IncompleteParentCountPipe,
    AddManualBookingBannerComponent,
    AddManualBookingButtonComponent,
    BackButtonComponent,
    NavComponent,
    HostsComponent,
    ScrollingModule,
    MobileSidebarComponent,
    IsTomorrowPipe,
    PageLoadingComponent,
    SubnavComponent,
    ThreadMessagesComponent,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    ActivityFeedComponent,
    FlexLayoutModule,
    NgxMatSelectSearchModule,
    GxSubnavComponent,
    IsSameDayBookingPipe,
    TranslateModule,
    HttpClientModule,
    HttpClientJsonpModule,
    TimeAgoPipe,
    BookingDetailsComponent,
    SecondsToSmartTimePipe,
    GuestBannerComponent,
    AdditionalGuestsBadgeComponent,
    Nl2brPipe,
    SafePipe,
    ConditionToTextPipe,
    MatchPropertySearchCriteriaPipe,
    MatchHostSearchCriteriaPipe,
    MatchPlatformSearchCriteriaPipe,
    InsightsComponent,
    DoesRuleHavePlatformPipe,
    PaginationComponent,
    MiniCalendarComponent,
    TimePickerComponent,
    ReportAProblemButtonComponent,
    SearchBarComponent,
    FilterComponent,
    SavedSegmentsComponent,
    RuleSettingsComponent,
    ReservationCardComponent,
    GuestEmailComponent,
    RuleEditorComponent,
    DialogCreateCustomCodeComponent,
    DialogDeleteRuleComponent,
    CustomCodeConditionsComponent,
    RuleScopeComponent,
    RecaptchaModule,
    RecaptchaFormsModule,
    SaveIndicatorComponent,
    TaskListComponent,
    ThreadSmartlockCodesComponent,
    ThreadThermostatSchedulesComponent,
    TeamIconComponent,
    ClipboardModule,
    IsTodayPipe,
    NumNightsPipe,
    IsThisMonthPipe,
    IsWeekendPipe,
    IsBeforeTodayPipe,
    HasOverlapWithOtherTasksPipe,
    HasOverlapWithOtherReservationsPipe,
    EventWidthPipe,
    IsDateSelectedPipe,
    TaskWidthPipe,
    EventWidthTaskPipe,
    TaskOverlapPipe,
    RuleTimingComponent,
    RuleTimingRecurringComponent,
    ChatgptGenerateButtonComponent,
    MenuTaskAssignComponent,
    NoItemsComponent,
    HelptextWrapperComponent,
    SortByPipe,
    FunctionPipe,
    NgxMatIntlTelInputModule,
    IconAirbnbComponent,
    IconHomeawayComponent,
    IconBookingComponent,
    IconAgodaComponent,
    IconSmartbnbComponent,
    IconPlatformComponent,
    IconIcalPlatformComponent,
    PropertiesComponent,
    ThermostatsComponent,
    IconPhoneComponent,
    IconEmailComponent,
    TeamRoleBadgeComponent,
    PropertySelectionComponent,
    SecondsToMetricsDisplayPipe,
    RemoveMinusSignPipe,
    CsDebugItemComponent,
    ToastContainerComponent,
    ToastComponent,
    TextFieldModule,
    PropertySelectDropdownComponent,
    SatPopoverModule,
    OverviewBoxComponent,
    CheckinActivityListComponent,
    IsAfterTodayPipe,
    ButtonComponent,
    EmptyStateTemplateComponent,
    OnboardingHelperComponent,
    DatePickerComponent,
    ImageGalleryComponent,
    TaskRuleTimingRecurringComponent,
    FilestackModule,
    FullScreenModalComponent,
    AlterationHaveDatesChangedPipe,
    AlterationHasNumberOfGuestsChangedPipe,
    AlterationDisplayGuestsNumbersPipe,
    TaxSelectionComponent,
    DialogCancelReservationFlowComponent,
    NoteComponent,
    ProgressBarComponent,
    TimelineComponentModule,
    ContentHeaderComponent,
    RulePlatformScopeIconsComponent,
    CommandKComponent,
    PageLevelCtaComponent,
    BookingQuickSetupFormatIssues,
    BookingQuickSetupGetUniqueIssues,
    DirectQuoteGuestDetailsComponent,
    DirectQuoteSetAmountComponent,
    DirectQuoteHoldDurationComponent,
    DirectQuoteConfirmComponent,
    SubTotalMinusDiscountsPipe,
    InputOtpComponent,
    ResizerComponent,
    TruncateComponent,
    AndroidProminentDisclosureComponent,
    PrevNextComponent,
    MiniListingImgComponent,
    SettingsSmartlocksComponent,
    SettingsThermostatsComponent,
    ScrollNearEndDirective,
    TributeDirective,
    ReservationDetailsComponent,
    DialogReservationDetailsComponent,
    IconComponent,
    MetricWidgetComponent,
    UiModule,
    CurrencyCodePipe,
    PluralizePipe,
    LinkifyjsPipe,
    RichTextEditorComponent,
    DevicesSubnavComponent,
    ReceivedReviewComponent,
    ReceivedReviewFormComponent,
    DeviceUpsellButtonComponent,
    DeviceUpsellButtonContentDirective,
    PasteHandlerDirective,
    PendingQuoteComponent,
    ReservationIconComponent,
    VideoCardComponent,
    VideoCardGridComponent,
    SupportDocumentationTitleComponent,
    SupportDocumentationIconComponent,
    ArticleLinkComponent,
    ArticleListComponent,
    GuestVettingStatusComponent,
    UpsellsStatusComponent,
    CustomerSupportDebugDirective,
    GuestBannerThreadComponent,
    CurrentTimeInTzPipe,
    ToSentencePipe,
    FiltersComponent,
    FiltersComponent,
    AppliedFilterButtonComponent,
    FilterMenuDateComponent,
    FilterMenuSelectComponent,
    CurrencyCodePipe,
    IssueDetectedComponent,
    SentimentLabelComponent,
  ],
  providers: [IncompleteParentCountPipe, HumanizePipe, NumNightsPipe, ArrayToListPipe, ToSentencePipe],
})
export class SharedModule {
  constructor(private translate: TranslateService) {
    const browserLang = translate.getBrowserLang();

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en');
  }
}
