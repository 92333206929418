import { Filter } from '@app/shared/interfaces/lib/filter.interface';
import { MetricPayload } from './metrics.models';

export function getMetricPayload(appliedFilters: Filter[] | null): MetricPayload {
  const payload: MetricPayload = {
    one_file_per_property: false,
    filters: {} as MetricPayload['filters'],
  };

  if (appliedFilters) {
    for (let i = 0; i < appliedFilters.length; i++) {
      const filter = appliedFilters[i];
      payload.filters[filter.key] = filter.initial_payload;
    }
  }

  return payload;
}
