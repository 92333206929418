import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable, isDevMode, OnDestroy } from '@angular/core';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { Logger } from '@app/shared/utils';
import { interval, Observable, Subject, throwError, timer } from 'rxjs';
import { catchError, filter, map, take, takeUntil, tap, timeout } from 'rxjs/operators';
import * as Sentry from '../../../sentry';

enum IntercomActions {
  SHOW = 'show',
  SHOW_NEW_MESSAGE = 'showNewMessage',
  SHOW_ARTICLE = 'showArticle',
  START_TOUR = 'startTour',
  SHUTDOWN = 'shutdown',
}

/**
 * Service to interact with Intercom
 * Intercom is loaded through Segment.io and made available on the window object
 */
@Injectable({
  providedIn: 'root',
})
export class IntercomService implements OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(
    private authService: AuthenticationService,
    private breakpointObserver: BreakpointObserver
  ) {
    // Wait for Intercom to be loaded before setting up breakpoint observer
    this.waitForIntercom()
      .pipe(
        take(1),
        tap(() => {
          this.setupBreakpointObserver();
        }),
        catchError(() => {
          // If Intercom fails to load, we'll just skip setting up the breakpoint observer
          return timer(0);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private waitForIntercom(): Observable<void> {
    const TIMEOUT_MS = 10000; // 10 seconds timeout
    const INTERVAL_MS = 100;

    return interval(INTERVAL_MS).pipe(
      filter(() => !!this.intercom),
      map(() => void 0),
      take(1),
      timeout(TIMEOUT_MS),
      tap(() => {
        Logger.log('[Intercom]', 'Intercom loaded successfully');
      }),
      catchError((error) => {
        const message = 'Intercom failed to load - it may be blocked or not available';
        Logger.log('[Intercom]', message, error);
        Sentry.captureException(message);
        return throwError(() => error);
      })
    );
  }

  private setupBreakpointObserver(): void {
    this.breakpointObserver
      .observe('(max-width: 960px)')
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: BreakpointState) => {
        if (result.matches) {
          // Hide intercom on mobile
          Logger.log('[Intercom Action]', '"hideDefaultLauncher"');
          this.hideDefaultLauncher();
        } else {
          // Otherwise show it
          Logger.log('[Intercom Action]', '"showDefaultLauncher"');

          // Even though we're waiting for Intercom to load before calling this, it seems to try opening before Intercom is ready for it
          // Adding a setTimeout to make sure Intercom is ready - hacky but should work
          // There's also no need for intercom to always be there immediately anyway
          setTimeout(() => {
            this.showDefaultLauncher();
          }, 1000);
        }
      });
  }

  public openHelpArticle(articleId: number | string) {
    if (!articleId) {
      return;
    }

    if (!this.intercom || this.authService.isCS()) {
      window.open(`https://my.hospitable.com/support-documentation/article/${articleId}`, '_blank');
      return;
    }

    return this.intercom(IntercomActions.SHOW_ARTICLE, articleId);
  }

  public showNewMessage(message: string) {
    if (isDevMode()) {
      Logger.log('[Intercom Action]', '"showMessage"', `"${message}"`);
    }
    if (!this.intercom || this.authService.isCS()) {
      this.emailFallback(message);
    }
    this.intercom(IntercomActions.SHOW_NEW_MESSAGE, message);
  }

  public investigateSubscriptionIssue() {
    this.showNewMessage(
      "Please help me investigate an issue I'm having with subscribing to Hospitable. Code: subscription_cannot_be_created"
    );
  }

  public show(message = '') {
    if (isDevMode()) {
      Logger.log('[Intercom Action]', '"show"', `"${message}"`);
    }
    if (!this.intercom || this.authService.isCS()) {
      this.emailFallback(message);
      return;
    }

    return this.intercom(IntercomActions.SHOW, message);
  }

  public startTour(tourId: string) {
    if (!this.intercom) {
      Sentry.captureException('Tour failed to load. Intercom may be blocked or is not loaded.');
      return;
    }

    if (this.authService.isCS()) {
      alert('Tour will not load due to CS impersonated login, but does work for regular hosts.');
      return;
    }

    this.intercom(IntercomActions.START_TOUR, tourId);
  }

  public shutdown() {
    if (!this.intercom) {
      return;
    }

    this.intercom(IntercomActions.SHUTDOWN);
  }

  public hideDefaultLauncher(): boolean {
    if (!this.intercom) {
      return false;
    }

    this.intercom('update', {
      hide_default_launcher: true,
    });
    return true;
  }

  public showDefaultLauncher(): boolean {
    if (!this.intercom) {
      return false;
    }

    this.intercom('update', {
      hide_default_launcher: false,
    });
    return true;
  }

  private get intercom() {
    return (window as any).Intercom;
  }

  private get user() {
    return this.authService.getUserDetails();
  }

  private emailFallback(message: string) {
    const userId = this.user?.id ? `User ID: ${this.user.id}` : '';
    const subject = message?.length ? message : 'I need support';
    window.open(`mailto:support@hospitable.com?subject=${subject}. ${userId}`, '_blank');
  }
}
