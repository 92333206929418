import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'sbnb-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
  form: FormGroup;

  @Input('placeholderText') placeholderText: string;
  @Input('debounceTimeMs') debounceTimeMs: number = 400;
  @Input('initialVal') initialVal: string;
  @Input('enterRequiredToSearch') enterRequiredToSearch: boolean = false;
  @Input('disableInput') disableInput: boolean = false;
  @Output() searchChanged: EventEmitter<string> = new EventEmitter();

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.form = this.fb.group({
      search: [this.initialVal],
    });

    if (!this.enterRequiredToSearch) {
      this.form
        .get('search')
        .valueChanges.pipe(debounceTime(this.debounceTimeMs), distinctUntilChanged())
        .subscribe((val) => {
          this.searchInputChange(val);
        });
    }

    if (this.disableInput) {
      this.form.get('search').disable();
    }
  }

  searchInputChange(newValue: string) {
    this.searchChanged.emit(newValue);
  }

  stopClearingInput(event) {
    event.preventDefault();

    if (this.enterRequiredToSearch) {
      this.searchInputChange(this.form.get('search').value);
    }
  }

  clearValue() {
    this.form.get('search').setValue('');
  }
}
