import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType, concatLatestFrom } from '@ngrx/effects';
import * as MetricsActions from './metrics.actions';
import { Action, Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { MetricsService } from './metrics.service';
// import { ApiErrorResponse, ApiResponse, Filter, Pagination } from '@hospitable/shared/interfaces';
import {
  ExportMetrics,
  MetricEarlierExports,
  MetricDetailsTable,
  MetricFilterLabelResponse,
  MetricFiltersResponse,
  MetricPayload,
  MetricGraphs,
  MetricStatistics,
  MetricCurrencies,
  Currency,
  Metrics,
  DataTooLargeMeta,
  MetricView,
} from './metrics.models';
import { MetricsState } from './metrics.reducer';
import * as MetricsSelectors from './metrics.selectors';
import { saveAs } from 'file-saver';
import { ApiErrorResponse, ApiResponse } from '@app/shared/interfaces/lib/api.interface';
import { Pagination } from '@app/shared/interfaces/lib/pagination.interface';
import { Filter } from '@app/shared/interfaces/lib/filter.interface';
import { ToastNotificationsService } from '@app/shared/services/toast-notifications/toast-notifications.service';
// import { NotificationService } from '@hospitable/shared/services';

@Injectable()
export class MetricsEffects {
  loadCurrencies = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.loadCurrencies),
        mergeMap(() => {
          return this.metricsService.loadCurrencies().pipe(
            mergeMap((response: ApiResponse<MetricCurrencies>) => {
              return of(MetricsActions.loadCurrenciesSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.loadCurrenciesFailure({ response: error })))
          );
        })
      )
  );

  updateDefaultCurrency = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.updateDefaultCurrency),
        mergeMap((action) => {
          return this.metricsService.updateDefaultCurrency(action.payload).pipe(
            mergeMap((response: ApiResponse<Currency>) => {
              return of(MetricsActions.updateDefaultCurrencySuccess({ response, metricsToReload: action.metricsToReload }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.updateDefaultCurrencyFailure({ response: error })))
          );
        })
      )
  );

  updateDefaultCurrencySuccess = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.updateDefaultCurrencySuccess),
        mergeMap((action) => {
          switch (action.metricsToReload) {
            case Metrics.Reservations: {
              return of(MetricsActions.reloadReservationMetrics());
            }
            case Metrics.Taxes: {
              return of(MetricsActions.reloadTaxesMetrics());
            }
            case Metrics.Reviews: {
              return of(MetricsActions.reloadReviewsMetrics());
            }
            case Metrics.Tasks: {
              return of(MetricsActions.reloadTasksMetrics());
            }
          }
          return EMPTY;
        })
      )
  );

  loadReservationCount$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.loadReservationCount),
        concatLatestFrom(() => this.store.select(MetricsSelectors.getReservationAppliedFilters)),
        mergeMap(([action, appliedFilters]) => {
          const payload: MetricPayload = {
            one_file_per_property: action.oneFilePerProperty ? action.oneFilePerProperty : false,
            filters: {} as MetricPayload['filters'],
          };

          if (appliedFilters) {
            for (let i = 0; i < appliedFilters.length; i++) {
              const filter = appliedFilters[i];
              payload.filters[filter.key] = filter.initial_payload;
            }
          }

          return this.metricsService.loadReservationCount(payload).pipe(
            mergeMap((response: number) => {
              return of(MetricsActions.loadReservationCountSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.loadReservationCountFailure({ response: error })))
          );
        })
      )
  );

  exportReservationMetrics$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.exportReservationMetrics),
        mergeMap((action) =>
          this.metricsService.exportReservationMetrics(action.payload).pipe(
            mergeMap((response: ApiResponse<ExportMetrics>) => {
              return of(MetricsActions.exportReservationMetricsSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.exportReservationMetricsFailure({ response: error })))
          )
        )
      )
  );

  exportMetricsNotification$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(
          MetricsActions.exportReservationMetricsSuccess,
          MetricsActions.exportTaxesMetricsSuccess,
          MetricsActions.exportReviewsMetricsSuccess,
          MetricsActions.exportTasksMetricsSuccess
        ),
        map((action) => action.response),
        mergeMap((response) => {
          if (response && response.data) {
            const { email } = response.data;
            this.notificationService.notificationOpen(`Metric export sent to ${email}`);
          }

          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  exportMetricsFailureNotification$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(
          MetricsActions.exportReservationMetricsFailure,
          MetricsActions.exportTaxesMetricsFailure,
          MetricsActions.exportReviewsMetricsFailure,
          MetricsActions.exportTasksMetricsFailure
        ),
        map((action) => action.response),
        mergeMap((response) => {
          if (response && response.error) {
            const { message } = response.error;
            this.notificationService.notificationOpen(message || 'We are unable to send you an export of these metrics at this time.');
          }

          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  loadReservationFilters$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.loadReservationFilters),
        mergeMap((action) =>
          this.metricsService.loadReservationFilters().pipe(
            mergeMap((response: ApiResponse<MetricFiltersResponse>) => {
              return of(MetricsActions.loadReservationFiltersSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.loadReservationFiltersFailure({ response: error })))
          )
        )
      )
  );

  loadReservationFiltersSuccess$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.loadReservationFiltersSuccess),
        mergeMap((action) => {
          const { data } = action.response;
          if (data.filters) {
            const { filters } = data;
            const filtersWithInitialPayload = filters.filter((filter) => {
              return filter.initial_payload_for_humans && filter.initial_payload_for_humans.has_payload;
            });

            if (filtersWithInitialPayload && filtersWithInitialPayload.length > 0) {
              return of(MetricsActions.bulkApplyReservationFilters({ filters: filtersWithInitialPayload }));
            }
          }

          return EMPTY;
        })
      )
  );

  viewSavedNotification$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(
          MetricsActions.saveReservationViewsSuccess,
          MetricsActions.saveTaxesViewsSuccess,
          MetricsActions.saveReviewsViewsSuccess,
          MetricsActions.saveTasksViewsSuccess
        ),
        map((action) => action.response),
        mergeMap((response) => {
          if (response) {
            this.notificationService.notificationOpen('View successfully saved');
          }

          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  loadReservationViews$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.loadReservationViews),
        mergeMap((action) =>
          this.metricsService.loadReservationViews().pipe(
            mergeMap((response: ApiResponse<MetricView[], { pagination: Pagination }>) => {
              return of(MetricsActions.loadReservationViewsSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.loadReservationViewsFailure({ response: error })))
          )
        )
      )
  );

  switchReservationView$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.switchReservationView),
        concatLatestFrom(() => this.store.select(MetricsSelectors.getReservationViews)),
        mergeMap(([action, views]) => {
          if (views && views.length > 0) {
            const view = views.filter((v: MetricView) => v.uuid === action.payload);
            if (view && view[0]) {
              const { filters } = view[0];
              const filtersWithInitialPayload = filters.filter((filter: Filter) => {
                return filter.initial_payload_for_humans && filter.initial_payload_for_humans.has_payload;
              });
              if (filtersWithInitialPayload && filtersWithInitialPayload.length > 0) {
                return of(MetricsActions.bulkApplyReservationFilters({ filters: filtersWithInitialPayload }));
              }
            }
          }

          return EMPTY;
        })
      )
  );

  saveReservationView$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.saveReservationView),
        mergeMap((action) =>
          this.metricsService.saveReservationView(action.payload).pipe(
            mergeMap((response: ApiResponse<MetricView>) => {
              return of(MetricsActions.saveReservationViewsSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.saveReservationViewsFailure({ response: error })))
          )
        )
      )
  );

  updateReservationView$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.updateReservationView),
        mergeMap((action) =>
          this.metricsService.updateReservationView(action.uuid, action.payload).pipe(
            mergeMap((response: ApiResponse<MetricView>) => {
              return of(MetricsActions.updateReservationViewSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.updateReservationViewFailure({ response: error })))
          )
        )
      )
  );

  deleteReservationView$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.deleteReservationView),
        mergeMap((action) =>
          this.metricsService.deleteView(action.uuid).pipe(
            mergeMap((response: ApiResponse<unknown>) => {
              return of(MetricsActions.deleteReservationViewSuccess({ uuid: action.uuid, response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.deleteReservationViewFailure({ response: error })))
          )
        )
      )
  );

  applyReservationFilter$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.applyReservationFilter),
        mergeMap((action) => {
          const payload = action.filter.initial_payload;
          return of(MetricsActions.loadReservationFilterLabel({ filter: action.filter, payload }));
        })
      )
  );

  reloadReservationData$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(
          MetricsActions.reloadReservationMetrics,
          MetricsActions.bulkApplyReservationFilters,
          MetricsActions.applyReservationFilter,
          MetricsActions.removeReservationFilter
        ),
        concatLatestFrom(() => this.store.select(MetricsSelectors.getReservationAppliedFilters)),
        mergeMap(([action, appliedFilters]) => {
          const payload: MetricPayload = {
            one_file_per_property: false,
            filters: {} as MetricPayload['filters'],
          };

          if (appliedFilters) {
            for (let i = 0; i < appliedFilters.length; i++) {
              const filter = appliedFilters[i];
              payload.filters[filter.key] = filter.initial_payload;
            }
          }

          return of(
            MetricsActions.loadReservationDetails({
              payload,
            }),
            MetricsActions.loadReservationStatistics({
              payload,
            }),
            MetricsActions.loadReservationGraphs({
              payload,
            })
          );
        })
      )
  );

  loadReservationFilterLabel$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.loadReservationFilterLabel),
        mergeMap((action) =>
          this.metricsService.loadReservationFilterLabel(action.filter.key, action.payload).pipe(
            mergeMap((response: ApiResponse<MetricFilterLabelResponse>) => {
              return of(MetricsActions.loadReservationFilterLabelSuccess({ filter: action.filter, response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.loadReservationFilterLabelFailure({ response: error })))
          )
        )
      )
  );

  loadReservationDetails$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.loadReservationDetails),
        mergeMap((action) =>
          this.metricsService.loadReservationDetails(action.payload, action.page).pipe(
            mergeMap((response: ApiResponse<MetricDetailsTable[], { pagination: Pagination }>) => {
              return of(MetricsActions.loadReservationDetailsSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.loadReservationDetailsFailure({ response: error })))
          )
        )
      )
  );

  loadReservationStatistics$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.loadReservationStatistics),
        mergeMap((action) =>
          this.metricsService.loadReservationStatistics(action.payload).pipe(
            mergeMap((response: ApiResponse<MetricStatistics, DataTooLargeMeta>) => {
              return of(MetricsActions.loadReservationStatisticsSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.loadReservationStatisticsFailure({ response: error })))
          )
        )
      )
  );

  loadReservationGraphs$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.loadReservationGraphs),
        mergeMap((action) =>
          this.metricsService.loadReservationGraphs(action.payload).pipe(
            mergeMap((response: ApiResponse<MetricGraphs>) => {
              return of(MetricsActions.loadReservationGraphsSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.loadReservationGraphsFailure({ response: error })))
          )
        )
      )
  );

  // Earlier Exports
  loadEarlierExports$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.loadEarlierExports),
        mergeMap((action) =>
          this.metricsService.loadEarlierExports(action.page).pipe(
            mergeMap((response: ApiResponse<MetricEarlierExports[], { pagination: Pagination }>) => {
              return of(MetricsActions.loadEarlierExportsSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.loadEarlierExportsFailure({ response: error })))
          )
        )
      )
  );

  downloadExport$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.downloadExport),
        mergeMap((action) =>
          this.metricsService.downloadExport(action.uuid).pipe(
            mergeMap((response: any) => {
              const { body } = response;
              const nameArr = action.filename.split('/');
              const name = nameArr[nameArr.length - 1];
              saveAs(body, name);

              return of(MetricsActions.downloadExportSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.downloadExportFailure({ response: error })))
          )
        )
      )
  );

  // Taxes
  loadTaxesViews$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.loadTaxesViews),
        mergeMap((action) =>
          this.metricsService.loadTaxesViews().pipe(
            mergeMap((response: ApiResponse<MetricView[], { pagination: Pagination }>) => {
              return of(MetricsActions.loadTaxesViewsSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.loadTaxesViewsFailure({ response: error })))
          )
        )
      )
  );

  switchTaxesView$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.switchTaxesView),
        concatLatestFrom(() => this.store.select(MetricsSelectors.getTaxesViews)),
        mergeMap(([action, views]) => {
          if (views && views.length > 0) {
            const view = views.filter((v: MetricView) => v.uuid === action.payload);
            if (view && view[0]) {
              const { filters } = view[0];
              const filtersWithInitialPayload = filters.filter((filter: Filter) => {
                return filter.initial_payload_for_humans && filter.initial_payload_for_humans.has_payload;
              });
              if (filtersWithInitialPayload && filtersWithInitialPayload.length > 0) {
                return of(MetricsActions.bulkApplyTaxesFilters({ filters: filtersWithInitialPayload }));
              }
            }
          }

          return EMPTY;
        })
      )
  );

  saveTaxesView$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.saveTaxesView),
        mergeMap((action) =>
          this.metricsService.saveTaxesView(action.payload).pipe(
            mergeMap((response: ApiResponse<MetricView>) => {
              return of(MetricsActions.saveTaxesViewsSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.saveTaxesViewsFailure({ response: error })))
          )
        )
      )
  );

  updateTaxesView$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.updateTaxesView),
        mergeMap((action) =>
          this.metricsService.updateTaxesView(action.uuid, action.payload).pipe(
            mergeMap((response: ApiResponse<MetricView>) => {
              return of(MetricsActions.updateTaxesViewSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.updateTaxesViewFailure({ response: error })))
          )
        )
      )
  );

  deleteTaxesView$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.deleteTaxesView),
        mergeMap((action) =>
          this.metricsService.deleteView(action.uuid).pipe(
            mergeMap((response: ApiResponse<unknown>) => {
              return of(MetricsActions.deleteTaxesViewSuccess({ uuid: action.uuid, response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.deleteTaxesViewFailure({ response: error })))
          )
        )
      )
  );

  loadTaxesCount$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.loadTaxesCount),
        concatLatestFrom(() => this.store.select(MetricsSelectors.getTaxesAppliedFilters)),
        mergeMap(([action, appliedFilters]) => {
          const payload: MetricPayload = {
            one_file_per_property: action.oneFilePerProperty ? action.oneFilePerProperty : false,
            filters: {} as MetricPayload['filters'],
          };

          if (appliedFilters) {
            for (let i = 0; i < appliedFilters.length; i++) {
              const filter = appliedFilters[i];
              payload.filters[filter.key] = filter.initial_payload;
            }
          }

          return this.metricsService.loadTaxesCount(payload).pipe(
            mergeMap((response: number) => {
              return of(MetricsActions.loadTaxesCountSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.loadTaxesCountFailure({ response: error })))
          );
        })
      )
  );

  exportTaxesMetrics$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.exportTaxesMetrics),
        mergeMap((action) =>
          this.metricsService.exportTaxesMetrics(action.payload).pipe(
            mergeMap((response: ApiResponse<ExportMetrics>) => {
              return of(MetricsActions.exportTaxesMetricsSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.exportTaxesMetricsFailure({ response: error })))
          )
        )
      )
  );

  loadTaxesFilters$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.loadTaxesFilters),
        mergeMap((action) =>
          this.metricsService.loadTaxesFilters().pipe(
            mergeMap((response: ApiResponse<MetricFiltersResponse>) => {
              return of(MetricsActions.loadTaxesFiltersSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.loadTaxesFiltersFailure({ response: error })))
          )
        )
      )
  );

  loadTaxesFiltersSuccess$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.loadTaxesFiltersSuccess),
        mergeMap((action) => {
          const { data } = action.response;
          if (data.filters) {
            const { filters } = data;
            const filtersWithInitialPayload = filters.filter((filter) => {
              return filter.initial_payload_for_humans && filter.initial_payload_for_humans.has_payload;
            });

            if (filtersWithInitialPayload && filtersWithInitialPayload.length > 0) {
              return of(MetricsActions.bulkApplyTaxesFilters({ filters: filtersWithInitialPayload }));
            }
          }

          return EMPTY;
        })
      )
  );

  applyTaxesFilter$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.applyTaxesFilter),
        mergeMap((action) => {
          const payload = action.filter.initial_payload;
          return of(MetricsActions.loadTaxesFilterLabel({ filter: action.filter, payload }));
        })
      )
  );

  reloadTaxesData$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(
          MetricsActions.reloadTaxesMetrics,
          MetricsActions.bulkApplyTaxesFilters,
          MetricsActions.applyTaxesFilter,
          MetricsActions.removeTaxesFilter
        ),
        concatLatestFrom(() => this.store.select(MetricsSelectors.getTaxesAppliedFilters)),
        mergeMap(([action, appliedFilters]) => {
          const payload: MetricPayload = {
            one_file_per_property: false,
            filters: {} as MetricPayload['filters'],
          };

          if (appliedFilters) {
            for (let i = 0; i < appliedFilters.length; i++) {
              const filter = appliedFilters[i];
              payload.filters[filter.key] = filter.initial_payload;
            }
          }

          return of(
            MetricsActions.loadTaxesDetails({
              payload,
            }),
            MetricsActions.loadTaxesSummary({
              payload,
            }),
            MetricsActions.loadTaxesStatistics({
              payload,
            }),
            MetricsActions.loadTaxesGraphs({
              payload,
            })
          );
        })
      )
  );

  loadTaxesFilterLabel$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.loadTaxesFilterLabel),
        mergeMap((action) =>
          this.metricsService.loadTaxesFilterLabel(action.filter.key, action.payload).pipe(
            mergeMap((response: ApiResponse<MetricFilterLabelResponse>) => {
              return of(MetricsActions.loadTaxesFilterLabelSuccess({ filter: action.filter, response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.loadTaxesFilterLabelFailure({ response: error })))
          )
        )
      )
  );

  loadTaxesDetails$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.loadTaxesDetails),
        mergeMap((action) =>
          this.metricsService.loadTaxesDetails(action.payload, action.page).pipe(
            mergeMap((response: ApiResponse<MetricDetailsTable[], { pagination: Pagination }>) => {
              return of(MetricsActions.loadTaxesDetailsSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.loadTaxesDetailsFailure({ response: error })))
          )
        )
      )
  );

  loadTaxesSummary$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.loadTaxesSummary),
        mergeMap((action) =>
          this.metricsService.loadTaxesSummary(action.payload, action.page).pipe(
            mergeMap((response: ApiResponse<MetricDetailsTable[], { pagination: Pagination }>) => {
              return of(MetricsActions.loadTaxesSummarySuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.loadTaxesSummaryFailure({ response: error })))
          )
        )
      )
  );

  loadTaxesStatistics$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.loadTaxesStatistics),
        mergeMap((action) =>
          this.metricsService.loadTaxesStatistics(action.payload).pipe(
            mergeMap((response: ApiResponse<MetricStatistics, DataTooLargeMeta>) => {
              return of(MetricsActions.loadTaxesStatisticsSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.loadTaxesStatisticsFailure({ response: error })))
          )
        )
      )
  );

  loadTaxesGraphs$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.loadTaxesGraphs),
        mergeMap((action) =>
          this.metricsService.loadTaxesGraphs(action.payload).pipe(
            mergeMap((response: ApiResponse<MetricGraphs>) => {
              return of(MetricsActions.loadTaxesGraphsSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.loadTaxesGraphsFailure({ response: error })))
          )
        )
      )
  );

  // Reviews
  loadReviewsViews$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.loadReviewsViews),
        mergeMap((action) =>
          this.metricsService.loadReviewsViews().pipe(
            mergeMap((response: ApiResponse<MetricView[], { pagination: Pagination }>) => {
              return of(MetricsActions.loadReviewsViewsSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.loadReviewsViewsFailure({ response: error })))
          )
        )
      )
  );

  switchReviewsView$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.switchReviewsView),
        concatLatestFrom(() => this.store.select(MetricsSelectors.getReviewsViews)),
        mergeMap(([action, views]) => {
          if (views && views.length > 0) {
            const view = views.filter((v: MetricView) => v.uuid === action.payload);
            if (view && view[0]) {
              const { filters } = view[0];
              const filtersWithInitialPayload = filters.filter((filter: Filter) => {
                return filter.initial_payload_for_humans && filter.initial_payload_for_humans.has_payload;
              });
              if (filtersWithInitialPayload && filtersWithInitialPayload.length > 0) {
                return of(MetricsActions.bulkApplyReviewsFilters({ filters: filtersWithInitialPayload }));
              }
            }
          }

          return EMPTY;
        })
      )
  );

  saveReviewsView$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.saveReviewsView),
        mergeMap((action) =>
          this.metricsService.saveReviewsView(action.payload).pipe(
            mergeMap((response: ApiResponse<MetricView>) => {
              return of(MetricsActions.saveReviewsViewsSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.saveReviewsViewsFailure({ response: error })))
          )
        )
      )
  );

  updateReviewsView$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.updateReviewsView),
        mergeMap((action) =>
          this.metricsService.updateReviewsView(action.uuid, action.payload).pipe(
            mergeMap((response: ApiResponse<MetricView>) => {
              return of(MetricsActions.updateReviewsViewSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.updateReviewsViewFailure({ response: error })))
          )
        )
      )
  );

  deleteReviewsView$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.deleteReviewsView),
        mergeMap((action) =>
          this.metricsService.deleteView(action.uuid).pipe(
            mergeMap((response: ApiResponse<unknown>) => {
              return of(MetricsActions.deleteReviewsViewSuccess({ uuid: action.uuid, response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.deleteReviewsViewFailure({ response: error })))
          )
        )
      )
  );

  loadReviewsCount$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.loadReviewsCount),
        concatLatestFrom(() => this.store.select(MetricsSelectors.getReviewsAppliedFilters)),
        mergeMap(([action, appliedFilters]) => {
          const payload: MetricPayload = {
            one_file_per_property: action.oneFilePerProperty ? action.oneFilePerProperty : false,
            filters: {} as MetricPayload['filters'],
          };

          if (appliedFilters) {
            for (let i = 0; i < appliedFilters.length; i++) {
              const filter = appliedFilters[i];
              payload.filters[filter.key] = filter.initial_payload;
            }
          }

          return this.metricsService.loadReviewsCount(payload).pipe(
            mergeMap((response: number) => {
              return of(MetricsActions.loadReviewsCountSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.loadReviewsCountFailure({ response: error })))
          );
        })
      )
  );

  exportReviewsMetrics$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.exportReviewsMetrics),
        mergeMap((action) =>
          this.metricsService.exportReviewsMetrics(action.payload).pipe(
            mergeMap((response: ApiResponse<ExportMetrics>) => {
              return of(MetricsActions.exportReviewsMetricsSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.exportReviewsMetricsFailure({ response: error })))
          )
        )
      )
  );

  loadReviewsFilters$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.loadReviewsFilters),
        mergeMap((action) =>
          this.metricsService.loadReviewsFilters().pipe(
            mergeMap((response: ApiResponse<MetricFiltersResponse>) => {
              return of(MetricsActions.loadReviewsFiltersSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.loadReviewsFiltersFailure({ response: error })))
          )
        )
      )
  );

  loadReviewsFiltersSuccess$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.loadReviewsFiltersSuccess),
        mergeMap((action) => {
          const { data } = action.response;
          if (data.filters) {
            const { filters } = data;
            const filtersWithInitialPayload = filters.filter((filter) => {
              return filter.initial_payload_for_humans && filter.initial_payload_for_humans.has_payload;
            });

            if (filtersWithInitialPayload && filtersWithInitialPayload.length > 0) {
              return of(MetricsActions.bulkApplyReviewsFilters({ filters: filtersWithInitialPayload }));
            }
          }

          return EMPTY;
        })
      )
  );

  applyReviewsFilter$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.applyReviewsFilter),
        mergeMap((action) => {
          const payload = action.filter.initial_payload;
          return of(MetricsActions.loadReviewsFilterLabel({ filter: action.filter, payload }));
        })
      )
  );

  reloadReviewsData$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(
          MetricsActions.reloadReviewsMetrics,
          MetricsActions.bulkApplyReviewsFilters,
          MetricsActions.applyReviewsFilter,
          MetricsActions.removeReviewsFilter
        ),
        concatLatestFrom(() => this.store.select(MetricsSelectors.getReviewsAppliedFilters)),
        mergeMap(([action, appliedFilters]) => {
          const payload: MetricPayload = {
            one_file_per_property: false,
            filters: {} as MetricPayload['filters'],
          };

          if (appliedFilters) {
            for (let i = 0; i < appliedFilters.length; i++) {
              const filter = appliedFilters[i];
              payload.filters[filter.key] = filter.initial_payload;
            }
          }

          return of(
            MetricsActions.loadReviewsDetails({
              payload,
            }),
            MetricsActions.loadReviewsStatistics({
              payload,
            }),
            MetricsActions.loadReviewsGraphs({
              payload,
            })
          );
        })
      )
  );

  loadReviewsFilterLabel$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.loadReviewsFilterLabel),
        mergeMap((action) =>
          this.metricsService.loadReviewsFilterLabel(action.filter.key, action.payload).pipe(
            mergeMap((response: ApiResponse<MetricFilterLabelResponse>) => {
              return of(MetricsActions.loadReviewsFilterLabelSuccess({ filter: action.filter, response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.loadReviewsFilterLabelFailure({ response: error })))
          )
        )
      )
  );

  loadReviewsDetails$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.loadReviewsDetails),
        mergeMap((action) =>
          this.metricsService.loadReviewsDetails(action.payload, action.page).pipe(
            mergeMap((response: ApiResponse<MetricDetailsTable[], { pagination: Pagination }>) => {
              return of(MetricsActions.loadReviewsDetailsSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.loadReviewsDetailsFailure({ response: error })))
          )
        )
      )
  );

  loadReviewsStatistics$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.loadReviewsStatistics),
        mergeMap((action) =>
          this.metricsService.loadReviewsStatistics(action.payload).pipe(
            mergeMap((response: ApiResponse<MetricStatistics, DataTooLargeMeta>) => {
              return of(MetricsActions.loadReviewsStatisticsSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.loadReviewsStatisticsFailure({ response: error })))
          )
        )
      )
  );

  loadReviewsGraphs$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.loadReviewsGraphs),
        mergeMap((action) =>
          this.metricsService.loadReviewsGraphs(action.payload).pipe(
            mergeMap((response: ApiResponse<MetricGraphs>) => {
              return of(MetricsActions.loadReviewsGraphsSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.loadReviewsGraphsFailure({ response: error })))
          )
        )
      )
  );

  // Tasks
  loadTasksViews$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.loadTasksViews),
        mergeMap((action) =>
          this.metricsService.loadTasksViews().pipe(
            mergeMap((response: ApiResponse<MetricView[], { pagination: Pagination }>) => {
              return of(MetricsActions.loadTasksViewsSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.loadTasksViewsFailure({ response: error })))
          )
        )
      )
  );

  switchTasksView$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.switchTasksView),
        concatLatestFrom(() => this.store.select(MetricsSelectors.getTasksViews)),
        mergeMap(([action, views]) => {
          if (views && views.length > 0) {
            const view = views.filter((v: MetricView) => v.uuid === action.payload);
            if (view && view[0]) {
              const { filters } = view[0];
              const filtersWithInitialPayload = filters.filter((filter: Filter) => {
                return filter.initial_payload_for_humans && filter.initial_payload_for_humans.has_payload;
              });
              if (filtersWithInitialPayload && filtersWithInitialPayload.length > 0) {
                return of(MetricsActions.bulkApplyTasksFilters({ filters: filtersWithInitialPayload }));
              }
            }
          }

          return EMPTY;
        })
      )
  );

  saveTasksView$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.saveTasksView),
        mergeMap((action) =>
          this.metricsService.saveTasksView(action.payload).pipe(
            mergeMap((response: ApiResponse<MetricView>) => {
              return of(MetricsActions.saveTasksViewsSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.saveTasksViewsFailure({ response: error })))
          )
        )
      )
  );

  updateTasksView$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.updateTasksView),
        mergeMap((action) =>
          this.metricsService.updateTasksView(action.uuid, action.payload).pipe(
            mergeMap((response: ApiResponse<MetricView>) => {
              return of(MetricsActions.updateTasksViewSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.updateTasksViewFailure({ response: error })))
          )
        )
      )
  );

  deleteTasksView$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.deleteTasksView),
        mergeMap((action) =>
          this.metricsService.deleteView(action.uuid).pipe(
            mergeMap((response: ApiResponse<unknown>) => {
              return of(MetricsActions.deleteTasksViewSuccess({ uuid: action.uuid, response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.deleteTasksViewFailure({ response: error })))
          )
        )
      )
  );

  loadTasksCount$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.loadTasksCount),
        concatLatestFrom(() => this.store.select(MetricsSelectors.getTasksAppliedFilters)),
        mergeMap(([action, appliedFilters]) => {
          const payload: MetricPayload = {
            one_file_per_property: action.oneFilePerProperty ? action.oneFilePerProperty : false,
            filters: {} as MetricPayload['filters'],
          };

          if (appliedFilters) {
            for (let i = 0; i < appliedFilters.length; i++) {
              const filter = appliedFilters[i];
              payload.filters[filter.key] = filter.initial_payload;
            }
          }

          return this.metricsService.loadTasksCount(payload).pipe(
            mergeMap((response: number) => {
              return of(MetricsActions.loadTasksCountSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.loadTasksCountFailure({ response: error })))
          );
        })
      )
  );

  exportTasksMetrics$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.exportTasksMetrics),
        mergeMap((action) =>
          this.metricsService.exportTasksMetrics(action.payload).pipe(
            mergeMap((response: ApiResponse<ExportMetrics>) => {
              return of(MetricsActions.exportTasksMetricsSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.exportTasksMetricsFailure({ response: error })))
          )
        )
      )
  );

  loadTasksFilters$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.loadTasksFilters),
        mergeMap((action) =>
          this.metricsService.loadTasksFilters().pipe(
            mergeMap((response: ApiResponse<MetricFiltersResponse>) => {
              return of(MetricsActions.loadTasksFiltersSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.loadTasksFiltersFailure({ response: error })))
          )
        )
      )
  );

  loadTasksFiltersSuccess$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.loadTasksFiltersSuccess),
        mergeMap((action) => {
          const { data } = action.response;
          if (data.filters) {
            const { filters } = data;
            const filtersWithInitialPayload = filters.filter((filter) => {
              return filter.initial_payload_for_humans && filter.initial_payload_for_humans.has_payload;
            });

            if (filtersWithInitialPayload && filtersWithInitialPayload.length > 0) {
              return of(MetricsActions.bulkApplyTasksFilters({ filters: filtersWithInitialPayload }));
            }
          }

          return EMPTY;
        })
      )
  );

  applyTasksFilter$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.applyTasksFilter),
        mergeMap((action) => {
          const payload = action.filter.initial_payload;
          return of(MetricsActions.loadTasksFilterLabel({ filter: action.filter, payload }));
        })
      )
  );

  reloadTasksData$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(
          MetricsActions.reloadTasksMetrics,
          MetricsActions.bulkApplyTasksFilters,
          MetricsActions.applyTasksFilter,
          MetricsActions.removeTasksFilter
        ),
        concatLatestFrom(() => this.store.select(MetricsSelectors.getTasksAppliedFilters)),
        mergeMap(([action, appliedFilters]) => {
          const payload: MetricPayload = {
            one_file_per_property: false,
            filters: {} as MetricPayload['filters'],
          };

          if (appliedFilters) {
            for (let i = 0; i < appliedFilters.length; i++) {
              const filter = appliedFilters[i];
              payload.filters[filter.key] = filter.initial_payload;
            }
          }

          return of(
            MetricsActions.loadTasksDetails({
              payload,
            })
          );
        })
      )
  );

  loadTasksFilterLabel$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.loadTasksFilterLabel),
        mergeMap((action) =>
          this.metricsService.loadTasksFilterLabel(action.filter.key, action.payload).pipe(
            mergeMap((response: ApiResponse<MetricFilterLabelResponse>) => {
              return of(MetricsActions.loadTasksFilterLabelSuccess({ filter: action.filter, response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.loadTasksFilterLabelFailure({ response: error })))
          )
        )
      )
  );

  loadTasksDetails$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MetricsActions.loadTasksDetails),
        mergeMap((action) =>
          this.metricsService.loadTasksDetails(action.payload, action.page).pipe(
            mergeMap((response: ApiResponse<MetricDetailsTable[], { pagination: Pagination }>) => {
              return of(MetricsActions.loadTasksDetailsSuccess({ response }));
            }),
            catchError((error: ApiErrorResponse) => of(MetricsActions.loadTasksDetailsFailure({ response: error })))
          )
        )
      )
  );

  constructor(
    private readonly actions$: Actions,
    private metricsService: MetricsService,
    private store: Store<MetricsState>,
    private notificationService: ToastNotificationsService
  ) {}
}
