import { createFeatureSelector, createSelector } from '@ngrx/store';
import { METRICS_FEATURE_KEY, MetricsState } from './metrics.reducer';
// import { DonutChart, DonutChartLegend, ProgressBar, ProgressBarColour, TableData } from '@app/ui';
import { humanize } from '@app/shared/utils';
import { DonutChart, DonutChartLegend } from '@app/ui/donut-chart/donut-chart.component.interface';
import { ProgressBar, ProgressBarColour } from '@app/ui/progress-bar/progress-bar.component.interface';
import { TableData } from '@app/ui/table/table.component.interface';

// Lookup the 'Metrics' feature state managed by NgRx
export const getMetricsState = createFeatureSelector<MetricsState>(METRICS_FEATURE_KEY);

export const getCurrenciesLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.currencies.loadingState);
export const getCurrencies = createSelector(getMetricsState, (state: MetricsState) =>
  state.currencies.data && state.currencies.data.currencies ? state.currencies.data.currencies : null
);
export const getDefaultCurrency = createSelector(getMetricsState, (state: MetricsState) =>
  state.currencies.data && state.currencies.data.default_currency ? state.currencies.data.default_currency : null
);
export const updateDefaultCurrencyLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.updateDefaultCurrency.loadingState);
export const getConversionRates = createSelector(getMetricsState, (state: MetricsState) => state.conversionRates.data);
export const getReservationCountLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.reservationCount.loadingState);
export const getReservationCount = createSelector(getMetricsState, (state: MetricsState) => state.reservationCount.data);

export const exportReservationMetricsLoadingState = createSelector( 
  getMetricsState,
  (state: MetricsState) => state.exportReservationMetrics.loadingState
);

export const getReservationViewsLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.reservationViews.loadingState);
export const getReservationViews = createSelector(getMetricsState, (state: MetricsState) => state.reservationViews.data);
export const getReservationViewsMetaPagination = createSelector(getMetricsState, (state: MetricsState) =>
  state.reservationViews.meta ? state.reservationViews.meta.pagination : null
);
export const getActiveReservationView = createSelector(getMetricsState, (state: MetricsState) =>
  state.reservationViews.data && state.reservationViews.activeViewUUID
    ? state.reservationViews.data.find((view) => view.uuid === state.reservationViews.activeViewUUID) ?? null
    : null
);
export const saveReservationViewLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.saveReservationView.loadingState);
export const updateReservationViewLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.updateReservationView.loadingState);
export const deleteReservationViewLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.deleteReservationView.loadingState);

export const getReservationDetailsLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.reservationDetails.loadingState);
export const getReservationDetails = createSelector(getMetricsState, (state: MetricsState): TableData | null => {
  const { data, meta } = state.reservationDetails;

  if (data && data.length > 0) {
    const displayedColumns = Object.keys(data[0]);
    const dataSource: TableData['dataSource'] = data.map((obj) => {
      const keys = Object.keys(obj);
      const humanizedData = {} as Record<string, string>;

      keys.forEach((key) => {
        humanizedData[humanize(key)] = obj[key];
      });

      return humanizedData;
    });

    return {
      displayedColumns: displayedColumns.map((column) => humanize(column)),
      dataSource,
      meta,
    };
  }

  return null;
});

export const getReservationFiltersLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.reservationFilters.loadingState);
export const getReservationFilters = createSelector(getMetricsState, (state: MetricsState) => {
  const { filters } = state.reservationFilters.data;

  if (filters) {
    return filters;
    // return filters.filter((filter) => filter.state !== FilterState.HIDDEN);
  }

  return null;
});

export const getReservationFilterByKey = (key: string) =>
  createSelector(getMetricsState, (state: MetricsState) => {
    const { filters } = state.reservationFilters.data;
    if (filters) {
      return filters.find((filter) => filter.key === key);
    }
    return null;
  });

export const getReservationAppliedFilters = createSelector(getMetricsState, (state: MetricsState) => state.reservationFilters.data.appliedFilters);

export const getReservationStatisticsLoadingState = createSelector(
  getMetricsState,
  (state: MetricsState) => state.reservationStatistics.loadingState
);
export const getReservationStatistics = createSelector(getMetricsState, (state: MetricsState) =>
  state.reservationStatistics.data ? state.reservationStatistics.data.statistics : null
);
export const getReservationDataTooLargeMeta = createSelector(getMetricsState, (state: MetricsState) =>
  state.reservationStatistics.meta && state.reservationStatistics.meta.data_too_large ? true : false
);
export const getReservationStatisticsConversionRates = createSelector(getMetricsState, (state: MetricsState) =>
  state.reservationStatistics.data ? state.reservationStatistics.data.conversion_rates : null
);
export const getReservationGraphsLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.reservationGraphs.loadingState);
export const getReservationGraphs = createSelector(getMetricsState, (state: MetricsState): DonutChart[] | null => {
  if (state.reservationGraphs.data && state.reservationGraphs.data.graphs && state.reservationGraphs.data.graphs.length > 0) {
    const charts: DonutChart[] = [];

    for (let i = 0; i < state.reservationGraphs.data.graphs.length; i++) {
      const data = state.reservationGraphs.data.graphs[i];
      const colours: any = {
        airbnb: '#ED3C6A',
        vrbo: '#47C693',
        'booking.com': '#9571C3',
        direct: '#FFC74C',
        manual: '#FADFDF',
      };

      const backgroundColours: string[] = [];
      const values: number[] = [];
      const labels: string[] = [];
      const legend: DonutChartLegend[] = [];

      for (let x = 0; x < data.values.length; x++) {
        const v = data.values[x];
        const colour = colours[v.title.toLowerCase()];
        labels.push(v.title);
        values.push(v.value);
        backgroundColours.push(colour);

        legend.push({
          colour,
          title: v.title,
          value: v.formatted ? v.formatted : v.value.toString(),
        });
      }

      const datasets: DonutChart['datasets'] = [
        {
          data: values,
          backgroundColor: backgroundColours,
        },
      ];

      const chart: DonutChart = {
        title: data.title,
        labels,
        datasets,
        legend,
      };

      charts.push(chart);
    }

    return charts;
  }

  return null;
});

// Earlier Exports
export const getEarlierExportsLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.earlierExports.loadingState);
export const getEarlierExports = createSelector(getMetricsState, (state: MetricsState) => state.earlierExports.data);
export const getEarlierExportsMetaPagination = createSelector(getMetricsState, (state: MetricsState) =>
  state.earlierExports.meta ? state.earlierExports.meta.pagination : null
);

export const downloadExportLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.downloadExport.loadingState);

// Taxes
export const getTaxesViewsLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.taxesViews.loadingState);
export const getTaxesViews = createSelector(getMetricsState, (state: MetricsState) => state.taxesViews.data);
export const getTaxesViewsMetaPagination = createSelector(getMetricsState, (state: MetricsState) =>
  state.taxesViews.meta ? state.taxesViews.meta.pagination : null
);
export const getActiveTaxesView = createSelector(getMetricsState, (state: MetricsState) =>
  state.taxesViews.data && state.taxesViews.activeViewUUID
    ? state.taxesViews.data.find((view) => view.uuid === state.taxesViews.activeViewUUID) ?? null
    : null
);
export const saveTaxesViewLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.saveTaxesView.loadingState);
export const updateTaxesViewLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.updateTaxesView.loadingState);
export const deleteTaxesViewLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.deleteTaxesView.loadingState);

export const getTaxesCountLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.taxesCount.loadingState);
export const getTaxesCount = createSelector(getMetricsState, (state: MetricsState) => state.taxesCount.data);
export const exportTaxesMetricsLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.exportTaxesMetrics.loadingState);

export const getTaxesSummaryLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.taxesSummary.loadingState);
export const getTaxesSummary = createSelector(getMetricsState, (state: MetricsState): TableData | null => {
  const { data, meta } = state.taxesSummary;

  if (data && data.length > 0) {
    const displayedColumns = Object.keys(data[0]);
    const dataSource: TableData['dataSource'] = data.map((obj) => {
      const keys = Object.keys(obj);
      const humanizedData = {} as Record<string, string>;

      keys.forEach((key) => {
        humanizedData[humanize(key)] = obj[key];
      });

      return humanizedData;
    });

    return {
      displayedColumns: displayedColumns.map((column) => humanize(column)),
      dataSource,
      meta,
    };
  }

  return null;
});

export const getTaxesDetailsLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.taxesDetails.loadingState);
export const getTaxesDetails = createSelector(getMetricsState, (state: MetricsState): TableData | null => {
  const { data, meta } = state.taxesDetails;

  if (data && data.length > 0) {
    const displayedColumns = Object.keys(data[0]);
    const dataSource: TableData['dataSource'] = data.map((obj) => {
      const keys = Object.keys(obj);
      const humanizedData = {} as Record<string, string>;

      keys.forEach((key) => {
        humanizedData[humanize(key)] = obj[key];
      });

      return humanizedData;
    });

    return {
      displayedColumns: displayedColumns.map((column) => humanize(column)),
      dataSource,
      meta,
    };
  }

  return null;
});
export const getTaxesFiltersLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.taxesFilters.loadingState);
export const getTaxesFilters = createSelector(getMetricsState, (state: MetricsState) => {
  const { filters } = state.taxesFilters.data;

  if (filters) {
    return filters;
    // return filters.filter((filter) => filter.state !== FilterState.HIDDEN);
  }

  return null;
});
export const getTaxesAppliedFilters = createSelector(getMetricsState, (state: MetricsState) => state.taxesFilters.data.appliedFilters);
export const getTaxesStatisticsLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.taxesStatistics.loadingState);
export const getTaxesStatistics = createSelector(getMetricsState, (state: MetricsState) =>
  state.taxesStatistics.data ? state.taxesStatistics.data.statistics : null
);
export const getTaxesDataTooLargeMeta = createSelector(getMetricsState, (state: MetricsState) =>
  state.taxesStatistics.meta && state.taxesStatistics.meta.data_too_large ? true : false
);
export const getTaxesStatisticsConversionRates = createSelector(getMetricsState, (state: MetricsState) =>
  state.taxesStatistics.data ? state.taxesStatistics.data.conversion_rates : null
);

export const getTaxesGraphsLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.taxesGraphs.loadingState);
export const getTaxesGraphs = createSelector(getMetricsState, (state: MetricsState): DonutChart[] | null => {
  if (state.taxesGraphs.data && state.taxesGraphs.data.graphs && state.taxesGraphs.data.graphs.length > 0) {
    const charts: DonutChart[] = [];

    for (let i = 0; i < state.taxesGraphs.data.graphs.length; i++) {
      const data = state.taxesGraphs.data.graphs[i];
      const colours = ['#ED3C6A', '#47C693', '#9571C3', '#FFC74C', '#FADFDF'];

      const backgroundColours: string[] = [];
      const values: number[] = [];
      const labels: string[] = [];
      const legend: DonutChartLegend[] = [];

      for (let x = 0; x < data.values.length; x++) {
        const v = data.values[x];
        const colour = colours[x];
        labels.push(v.title);
        values.push(v.value);
        backgroundColours.push(colour);

        legend.push({
          colour,
          title: v.title,
          value: v.formatted ? v.formatted : v.value.toString(),
        });
      }

      const datasets: DonutChart['datasets'] = [
        {
          data: values,
          backgroundColor: backgroundColours,
        },
      ];

      const chart: DonutChart = {
        title: data.title,
        labels,
        datasets,
        legend,
      };

      charts.push(chart);
    }

    return charts;
  }

  return null;
});

// Reviews
export const getReviewsViewsLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.reviewsViews.loadingState);
export const getReviewsViews = createSelector(getMetricsState, (state: MetricsState) => state.reviewsViews.data);
export const getReviewsViewsMetaPagination = createSelector(getMetricsState, (state: MetricsState) =>
  state.reviewsViews.meta ? state.reviewsViews.meta.pagination : null
);
export const getActiveReviewsView = createSelector(getMetricsState, (state: MetricsState) =>
  state.reviewsViews.data && state.reviewsViews.activeViewUUID
    ? state.reviewsViews.data.find((view) => view.uuid === state.reviewsViews.activeViewUUID) ?? null
    : null
);
export const saveReviewsViewLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.saveReviewsView.loadingState);
export const updateReviewsViewLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.updateReviewsView.loadingState);
export const deleteReviewsViewLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.deleteReviewsView.loadingState);

export const getReviewsCountLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.reviewsCount.loadingState);
export const getReviewsCount = createSelector(getMetricsState, (state: MetricsState) => state.reviewsCount.data);
export const exportReviewsMetricsLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.exportReviewsMetrics.loadingState);

export const getReviewsDetailsLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.reviewsDetails.loadingState);
export const getReviewsDetails = createSelector(getMetricsState, (state: MetricsState): TableData | null => {
  const { data, meta } = state.reviewsDetails;

  if (data && data.length > 0) {
    const displayedColumns = Object.keys(data[0]);
    const dataSource: TableData['dataSource'] = data.map((obj) => {
      const keys = Object.keys(obj);
      const humanizedData = {} as Record<string, string>;

      keys.forEach((key) => {
        humanizedData[humanize(key)] = obj[key];
      });

      return humanizedData;
    });

    return {
      displayedColumns: displayedColumns.map((column) => humanize(column)),
      dataSource,
      meta,
    };
  }

  return null;
});
export const getReviewsFiltersLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.reviewsFilters.loadingState);
export const getReviewsFilters = createSelector(getMetricsState, (state: MetricsState) => {
  const { filters } = state.reviewsFilters.data;

  if (filters) {
    return filters;
    // return filters.filter((filter) => filter.state !== FilterState.HIDDEN);
  }

  return null;
});
export const getReviewsAppliedFilters = createSelector(getMetricsState, (state: MetricsState) => state.reviewsFilters.data.appliedFilters);
export const getReviewsStatisticsLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.reviewsStatistics.loadingState);
export const getReviewsStatistics = createSelector(getMetricsState, (state: MetricsState) =>
  state.reviewsStatistics.data ? state.reviewsStatistics.data.statistics : null
);
export const getReviewsDataTooLargeMeta = createSelector(getMetricsState, (state: MetricsState) =>
  state.reviewsStatistics.meta && state.reviewsStatistics.meta.data_too_large ? true : false
);
export const getReviewsGraphsLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.reviewsGraphs.loadingState);
export const getReviewsGraphs = createSelector(getMetricsState, (state: MetricsState): ProgressBar[] | null => {
  if (state.reviewsGraphs.data && state.reviewsGraphs.data.graphs && state.reviewsGraphs.data.graphs.length > 0) {
    const progressBars: ProgressBar[] = [];

    for (let i = 0; i < state.reviewsGraphs.data.graphs.length; i++) {
      const data = state.reviewsGraphs.data.graphs[i];

      for (let x = 0; x < data.values.length; x++) {
        const v = data.values[x];
        let colour = ProgressBarColour.Danger;

        if (v.value <= 4 && v.value > 3) {
          colour = ProgressBarColour.Neutral;
        }

        if (v.value > 4) {
          colour = ProgressBarColour.Positive;
        }

        const difference = v.difference;
        const diffColour = difference !== null && difference <= 0 ? ProgressBarColour.Danger : ProgressBarColour.Positive;

        let progressBar: ProgressBar = {
          title: v.title,
          progress: {
            value: (v.value / 5) * 100,
            formatted: v.value.toString(),
            colour,
          },
        };

        if (difference) {
          progressBar = {
            ...progressBar,
            ...{
              difference: {
                value: difference,
                formatted: difference.toString(),
                colour: diffColour,
              },
            },
          };
        }

        progressBars.push(progressBar);
      }
    }

    return progressBars;
  }

  return null;
});

// Tasks
export const getTasksViewsLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.tasksViews.loadingState);
export const getTasksViews = createSelector(getMetricsState, (state: MetricsState) => state.tasksViews.data);
export const getTasksViewsMetaPagination = createSelector(getMetricsState, (state: MetricsState) =>
  state.tasksViews.meta ? state.tasksViews.meta.pagination : null
);
export const getActiveTasksView = createSelector(getMetricsState, (state: MetricsState) =>
  state.tasksViews.data && state.tasksViews.activeViewUUID
    ? state.tasksViews.data.find((view) => view.uuid === state.tasksViews.activeViewUUID) ?? null
    : null
);
export const saveTasksViewLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.saveTasksView.loadingState);
export const updateTasksViewLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.updateTasksView.loadingState);
export const deleteTasksViewLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.deleteTasksView.loadingState);

export const getTasksCountLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.tasksCount.loadingState);
export const getTasksCount = createSelector(getMetricsState, (state: MetricsState) => state.tasksCount.data);
export const exportTasksMetricsLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.exportTasksMetrics.loadingState);

export const getTasksDetailsLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.tasksDetails.loadingState);
export const getTasksDetails = createSelector(getMetricsState, (state: MetricsState): TableData | null => {
  const { data, meta } = state.tasksDetails;

  if (data && data.length > 0) {
    const displayedColumns = Object.keys(data[0]);
    const dataSource: TableData['dataSource'] = data.map((obj) => {
      const keys = Object.keys(obj);
      const humanizedData = {} as Record<string, string>;

      keys.forEach((key) => {
        humanizedData[humanize(key)] = obj[key];
      });

      return humanizedData;
    });

    return {
      displayedColumns: displayedColumns.map((column) => humanize(column)),
      dataSource,
      meta,
    };
  }

  return null;
});
export const getTasksFiltersLoadingState = createSelector(getMetricsState, (state: MetricsState) => state.tasksFilters.loadingState);
export const getTasksFilters = createSelector(getMetricsState, (state: MetricsState) => {
  const { filters } = state.tasksFilters.data;

  if (filters) {
    return filters;
    // return filters.filter((filter) => filter.state !== FilterState.HIDDEN);
  }

  return null;
});
export const getTasksAppliedFilters = createSelector(getMetricsState, (state: MetricsState) => state.tasksFilters.data.appliedFilters);
