<form [formGroup]="form" autocomplete="off">
  <mat-form-field class="fw deep-16px-padding input__mini">
    <sbnb-icon icon="search" class="search__icon text-smoke-darker" [size]="14"></sbnb-icon>
    <input
      autocomplete="false"
      aria-autocomplete="none"
      class="search__text"
      matInput
      formControlName="search"
      name="search"
      [placeholder]="placeholderText || 'Search...'"
      (keydown.enter)="stopClearingInput($event)" />
    <button class="button__clear" *ngIf="form.get('search').value" matSuffix aria-label="Clear" (click)="clearValue()">
      x
    </button>
  </mat-form-field>
</form>
