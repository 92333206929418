export enum LocalStorageKeys {
  AccessToken = 'accessToken',
  RefreshToken = 'refreshToken',
  User = 'user',
  IsCS = 'isCS',
  BookingDotComIntegrationProcess = 'bookingDotComIntegrationProcess',
  DebugModeEnabled = 'debugModeEnabled',
  DemoModeEnabled = 'demoModeEnabled',
  AnalyticsEnabled = 'analyticsEnabled',
  IsTester = 'is-tester',

  // Cached Data
  Teams = 'teams',

  // Connect
  ConnectUserAuthToken = 'connectAuthToken',
  ConnectVendorRedirectUrl = 'connectVendorRedirectUrl',
  ConnectCustomerId = 'connectCustomerId',

  // auth.hospitable
  OAuthLogin = 'oAuthLogin',

  // Guest portal
  GuestPortalReservationUUID = 'guestPortalReservationUUID',
  GuestPortalAuthToken = 'guestPortalAuthToken',
}

export enum SessionStorageKeys {
  // Cached Data
  Teams = 'teams',
  Permissions = 'permissions',
  Optimizely = 'optimizely',
}

export type StorageKey = LocalStorageKeys | SessionStorageKeys;
export enum StorageType {
  LocalStorage = 'localStorage',
  SessionStorage = 'sessionStorage',
}
