import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { MaterialModule } from '../material.module';
import { ValidationMessageComponent } from './validation-message/validation-message.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, FormsModule],
  declarations: [CheckboxComponent, ValidationMessageComponent],
  exports: [CheckboxComponent, ValidationMessageComponent, FormsModule],
})
export class InputsModule {}
