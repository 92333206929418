import { Inject, Injectable } from '@angular/core';
import Pusher, { Channel } from 'pusher-js';
import { WebsocketConfig } from './websocket.interface';
import { WebsocketConfigService } from './websocket-config.service';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  public pusher: Pusher;

  constructor(@Inject(WebsocketConfigService) private config: WebsocketConfig) {}

  connect() {
    if (this.config) {
      this.pusher = new Pusher(this.config.key, this.config.options);

      this.pusher.connection.bind('connected', () => {
        console.log('Pusher status changed to connected');
      });

      this.pusher.connection.bind('disconnected', () => {
        console.log('Pusher status changed to disconnected');
      });
    } else {
      console.error('WebsocketService: connect -> failed due to no config provided');
    }
  }

  registerChannelAndSubscribe(name: string): Channel | undefined {
    if (!this.pusher) {
      this.connect();
    }

    const existingChannel = this.doesChannelExist(name);
    if (existingChannel) {
      existingChannel.reinstateSubscription();
      return existingChannel;
    } else {
      return this.pusher.subscribe(name);
    }
  }

  doesChannelExist(name: string): Channel | undefined {
    const channel = this.pusher.allChannels().find((channel) => channel.name === name);
    return channel ? channel : undefined;
  }

  disconnect() {
    if (this.pusher && this.pusher.disconnect) {
      const channels = this.pusher.allChannels();

      if (channels) {
        channels.forEach((channel) => {
          if (channel && channel.unsubscribe) {
            channel.unsubscribe();
          }
        });
      }
      this.pusher.disconnect();
    }
  }
}
