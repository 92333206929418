import { Injectable } from '@angular/core';
import { Channel as PusherChannel } from 'pusher-js';
import { WebsocketService } from './websocket.service';
import { getBoolean } from '@app/shared/utils';
import { LocalStorageKeys } from '@app/shared/interfaces/lib/storage.interface';

export enum WebsocketChannel {
  Global = 'users',
  User = 'users.',
  Team = 'teams.',
}

export interface Channel {
  name: string;
  events?: { name: string; callback: () => void }[];
  registeredChannel?: PusherChannel | undefined;
}

@Injectable()
export class WebsocketFacade {
  channels: Channel[] = [];

  constructor(private websocketService: WebsocketService) {}

  getUserChannelName(): string | null {
    const userRaw = localStorage.getItem(LocalStorageKeys.User);
    const user = userRaw ? JSON.parse(userRaw) : null;
    const userIdHash = user ? user.id_hash : null;

    return userIdHash ? WebsocketChannel.User + userIdHash : null;
  }

  getTeamChannelName(): string | null {
    const userRaw = localStorage.getItem(LocalStorageKeys.User);
    const user = userRaw ? JSON.parse(userRaw) : null;

    const teamId = user && user.team && user.team.id ? user.team.id : null;

    return teamId ? WebsocketChannel.Team + teamId : null;
  }

  init() {
    const isCS = getBoolean(localStorage.getItem(LocalStorageKeys.IsCS));
    if (isCS) {
      return;
    }

    const userChannel = this.getUserChannelName();
    const teamChannel = this.getTeamChannelName();

    if (userChannel) {
      this.addChannel(userChannel);
    }

    if (teamChannel) {
      this.addChannel(teamChannel);
    }

    this.addChannel(WebsocketChannel.Global);

    this.setupChannels();
  }

  findChannel(channelName: string): Channel | undefined {
    return this.channels ? this.channels.find((channel) => channel.name === channelName) : undefined;
  }

  addChannel(channelName: string) {
    const channel = this.findChannel(channelName);

    if (!channel) {
      this.channels.push({
        name: channelName,
      });
    }
  }

  setupChannels() {
    if (this.channels && this.channels.length > 0) {
      this.channels.forEach((channel) => {
        console.log(`Registering channel ${channel.name}`);
        channel.registeredChannel = this.websocketService.registerChannelAndSubscribe(channel.name);
      });
    }
  }

  registerChannelEvents(channelName: string, events: Channel['events']) {
    const channel = this.findChannel(channelName);

    if (channel && events) {
      events.forEach((event) => {
        if (channel && channel.registeredChannel) {
          channel.registeredChannel.bind(event.name, (e: any) => {
            console.log(`${channel.name} with event ${event.name} fired`, e);
            event.callback();
          });
        }
      });
    }
  }
}
