export enum QueryParams {
  AirbnbError = 'error',
  AirbnbCode = 'code',
  AirbnbState = 'state',
  AirbnbSignup = 'airbnb-signup',
}

export enum RouteParams {
  RedirectUrl = 'redirect_url',
  ClientId = 'client_id',
  ResponseType = 'response_type',
  State = 'state',
  From = 'from',
}

export enum RouteParamFrom {
  Reminder = 'reminder',
}

export enum RedirectActionType {
  URL = 'url',
  Path = 'path',
}

export interface RedirectAction {
  url: string;
  type: RedirectActionType;
  queryParams?: Record<string, unknown>;
}
