import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageLevelErrorComponent } from './page-level-error.component';

@NgModule({
  imports: [CommonModule],
  declarations: [PageLevelErrorComponent],
  exports: [PageLevelErrorComponent],
})
export class PageLevelErrorComponentModule {}
