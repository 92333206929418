import { Injectable } from '@angular/core';
import { ScriptLoaderService } from '@app/shared/services/script-loader/script-loader.service';
import { Logger } from '@app/shared/utils';

@Injectable({ providedIn: 'root' })
export class CometlyService {
  constructor(private scriptLoader: ScriptLoaderService) {}

  // Marketing use the Cometly tool (https://www.cometly.com/)
  // We want to load this only on the signup pages, and your plan page
  // It adds no value for the rest of the app
  init() {
    this.scriptLoader
      .load({
        name: 'Cometly',
        src: 'https://t.cometlytrack.com/e?uid=82ddfd-3377699757000037-cf9753-s',
      })
      .subscribe({
        next: () => {
          Logger.info('Cometly loaded');
          (window as any).comet('init');
        },
        error: () => {
          Logger.log('Cometly failed to load');
        },
      });
  }
}
