import { Host } from './host.interface';
import { Listing } from './listing.interface';

export interface PropertyList {
  id?: number;
  name: string;
  picture: string;
}

export interface RatePlan {
  uuid: string;
  name: string;
  is_primary: boolean;
  multiple_guest_markup: {
    type: 'amount' | 'percent';
    value: number;
  };
}

export interface Property {
  access: string;
  address: string;
  amenities: string[];
  bathrooms: number;
  bedrooms: number;
  beds: number;
  check_in_time: string;
  check_out_time: string;
  city: string;
  cleaning_fee: unknown;
  community_fee: unknown;
  counts: {
    airbnb: number;
    booking: number;
    homeaway: number;
    total: number;
  };
  currency: string;
  edited: {
    access: boolean;
    amenities: boolean;
    bathrooms: boolean;
    bedrooms: boolean;
    beds: boolean;
    check_in_time: boolean;
    check_out_time: boolean;
    garages: boolean;
    gardens: boolean;
    gyms: boolean;
    hot_tubs: boolean;
    house_rules: boolean;
    kitchens: boolean;
    living_rooms: boolean;
    neighborhood_overview: boolean;
    notes: boolean;
    offices: boolean;
    patios: boolean;
    person_capacity: boolean;
    photos: boolean;
    pools: boolean;
    space: boolean;
    summary: boolean;
    transit: boolean;
  };
  garages: number;
  gardens: number;
  guests_included: unknown;
  gyms: number;
  hosts: Host[];
  hot_tubs: number;
  house_rules: string;
  ical_feed: string;
  id: number;
  is_new: boolean;
  kitchens: number;
  languages: unknown;
  lat: string;
  lead_listing: unknown;
  linens_fee: unknown;
  listed: boolean;
  listings: Listing[];
  living_rooms: number;
  lng: string;
  location: PropertyLocation;
  management_fee: unknown;
  monthly_discount: unknown;
  muted: boolean;
  name: string;
  neighborhood_overview: string;
  notes: string;
  occupancy_taxes: unknown;
  offices: number;
  patios: number;
  person_capacity: number;
  photos: unknown[];
  picture: string;
  pools: number;
  price_per_extra_person: unknown;
  property_type: unknown;
  public_name: string;
  resort_fee: unknown;
  space: string;
  state: string;
  street: string;
  summary: string;
  sync: boolean;
  tags: string[];
  thumbnail: string;
  timezone: string;
  transit: string;
  types: string[];
  weekly_discount: unknown;
  zip: string;
  parent_child_type?: string;
  scope_type?: ChannelScopeType;
  calendar_restricted?: boolean;
}

export enum ChannelScopeType {
  Pms = 'pms',
  Operations = 'ops',
}

export interface PropertyLocation {
  apt: string;
  city: string;
  country: string;
  country_name: string;
  lat: string;
  lon: string;
  state: string;
  street: string;
  zipcode: string;
}

export interface GetPropertiesAPIRes {
  data: {
    reset_names: boolean;
    lead_platforms: LeadPlatform[];
  };
  meta: {
    descriptions: {
      reset_names: {
        type: string;
        title: string;
        description: string;
      };
    };
  };
}

export interface LeadPlatform {
  platform: 'airbnb' | 'homeaway' | 'booking';
  default: boolean;
  active: boolean;
}

export interface PropertySettings extends GetPropertiesAPIRes {
  lead_platform?: 'airbnb' | 'homeaway' | 'booking';
}

export interface PropertyMergeCandidate {
  propertyId?: string;
  listingId?: string;
  merge_candidate: string | null;
}

export enum UpdatePropertyOption {
  CreateNewProperty = 'createNewProperty',
  MuteProperty = 'muteProperty',
  UnmuteProperty = 'unmuteProperty',
  MergeProperty = 'mergePropertyWith_',
}

export interface PropertyTag {
  tag: string;
  propertyId?: string;
}

export interface PropertyAvailabilityRules {
  /**
   * inverse of PropertyCheckinCheckoutDays; 0=SUN ... 6=SAT
   */
  restricted_check_in: AvailabilityRule<RestrictedDays>;
  /**
   * inverse of PropertyCheckinCheckoutDays; 0=SUN ... 6=SAT
   */
  restricted_check_out: AvailabilityRule<RestrictedDays>;
  /**
   * PropertyTurnoverDays
   */
  preparation_time: AvailabilityRule<{ days: PreparationTime }>;
  /**
   * PropertyBookingLeadTime
   * Allow 0-24 (hours), then multiples of 24 (24 * [1-30]) 30x24
   * allow_request_to_book: boolean, allow requests to book when reservation lead time is < advance notice
   */
  advance_notice: AvailabilityRule<{ hours: AdvanceNotice; allow_request_to_book: AdvanceNoticeAllowRequestToBook }>;
  /**
   * PropertyRollingAvailability
   * transform days to months; 0 = Dates unavailable by default; valid values: 0 - 24
   */
  availability_window: AvailabilityRule<{ months: AvailabilityWindow }>;
  /**
   * PropertyMaxStay
   * 0 = No max stay; valid values: 1 - 1125
   */
  max_stay: AvailabilityRule<{ days: number }>;
  /**
   * PropertyAllowRtbAboveMaxNights
   * allow_request_to_book: boolean, allow requests to book when night is greater than max_stay
   */
  allow_rtb_above_max_nights: AvailabilityRule<{ allow_rtb_above_max_nights: AllowRtbAboveMaxNights }>;
  /**
   * PropertyMinStay
   * 0 = No min stay; valid values: 1 - 1125
   * custom_days: number[], 0=SUN ... 6=SAT
   * NOTE: In the GET request, we get the custom days as part of the min_stay object,
   * but in the PUT request, we need to send the custom days as a separate field, called min_stay_days.
   */
  min_stay: AvailabilityRule<{ days: number; custom_days: number[] }>;
  /**
   * PropertyMinStayDays
   * 0=SUN ... 6=SAT
   * Only used in the PUT request to update the min_stay_days field.
   */
  min_stay_days?: AvailabilityRule<{ days: number[] }>;
}

interface AvailabilityRule<RuleType> {
  default: RuleType;
  overrides: RuleType[];
}

type RestrictedDays = number[];
type PreparationTime = 0 | 1 | 2 | 3;
type AdvanceNotice = number;
type AdvanceNoticeAllowRequestToBook = boolean;
type AllowRtbAboveMaxNights = boolean;
type AvailabilityWindow = number;

export interface EditablePropertyAvailabilityRules {
  restricted_check_in: RestrictedDays;
  restricted_check_out: RestrictedDays;
  preparation_time: PreparationTime;
  advance_notice: AdvanceNotice;
  advance_notice_allow_request_to_book: AdvanceNoticeAllowRequestToBook;
  availability_window: AvailabilityWindow;
  allow_rtb_above_max_nights: AllowRtbAboveMaxNights;
}

export interface PropertyAvailabilityRulesResponse {
  propertyId: number;
  start: string;
  end: string;
  daysOfTheWeek: number[];
  available?: boolean;
  price?: number;
  lengthOfStay?: number;
  note?: string;
  closed_for_checkin?: boolean;
  closed_for_checkout?: boolean;
}
