<mat-spinner *ngIf="loadingPlatforms" diameter="16"></mat-spinner>
<div
  #container
  *ngIf="!loadingProperties"
  [fxLayout]="container.offsetWidth > 1000 ? 'row' : 'column'"
  fxLayoutGap="24px">
  <div fxFlex class="scope-card" [class.coloured-border]="!platformStagePassed">
    <div class="scope-card-header flex flex-wrap gap-3 items-center justify-between">
      <mat-checkbox [checked]="selectedAllPlatforms" (change)="platformAllCheckboxChange($event)"></mat-checkbox>
      <h4 class="">All platforms</h4>
      <div class="flex-1 flex flex-wrap gap-x-3 items-end justify-end">
        <span class="night-lighter text__small" *ngIf="!selectedAllPlatforms && !airbnbOnly">
          {{ selectedPlatforms?.length }} selected
        </span>
        <!-- <span *ngIf="selectedAllPlatforms">Any (including future)</span> -->
        <span role="button" class="link text__small" (click)="clearPlatforms()">Clear selection</span>
      </div>
    </div>
    <div class="scope-card-content">
      <div>
        <sbnb-search-bar placeholderText="Search..." (searchChanged)="platformsSearchUpdated($event)"></sbnb-search-bar>
      </div>
      <div class="rules__scrollable">
        <mat-spinner *ngIf="loadingPlatforms" diameter="16"></mat-spinner>
        <ng-container *ngFor="let platform of possiblePlatforms">
          <div
            *ngIf="
              (platform.label | matchPlatformSearchCriteria: searchCriteriaPlatforms) &&
              (!airbnbOnly || (airbnbOnly && platform.key === 'airbnb'))
            "
            fxLayout
            fxLayoutGap="4px"
            fxLayoutAlign="start center">
            <mat-checkbox
              [checked]="selectedAllPlatforms || selectedPlatforms.indexOf(platform.key) > -1"
              (change)="platformCheckboxChange(platform, $event)">
              <span fxLayout fxLayoutGap="8px" fxLayoutAlign="start center">
                <div class="host__img">
                  <img
                    [src]="
                      '/assets/iconography/' + (platform.key == 'ical' ? 'manual' : platform.key) + '.svg'
                    " />
                </div>

                <span fxFlex>{{ platform.label }}</span>
              </span>
            </mat-checkbox>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div
    [class.coloured-border]="!hostsStagePassed || hostError"
    [ngClass]="{
      'opacity-100 pointer-events-auto': platformStagePassed,
      'opacity-0 pointer-events-none': !platformStagePassed
    }"
    fxFlex
    class="scope-card">
    <div class="scope-card-header flex flex-wrap gap-3 items-center justify-between">
      <mat-checkbox [checked]="selectedAllHosts" (change)="hostAllCheckboxChange($event)"></mat-checkbox>

      <h4>All hosts</h4>

      <div class="flex-1 flex flex-wrap gap-x-3 items-end justify-end">
        <span class="night-lighter text__small" *ngIf="!selectedAllHosts">
          {{ selectedHosts?.length - numDeletedHosts }} selected
        </span>
        <!-- <span *ngIf="selectedAllHosts">Any (including future)</span> -->
        <span role="button" class="link text__small" (click)="clearHosts()">Clear selection</span>
      </div>
    </div>
    <div class="scope-card-content">
      <sbnb-alert-card context="warning" *ngIf="hostError" class="mb-3">
        <ng-container *ngIf="hostError['required']">
          <div>Messages will fail unless at least one host is selected.</div>
        </ng-container>

        <ng-container *ngIf="hostError['platformWithoutHost']">
          <div>
            Some {{ hostError['platformWithoutHost'] | arrayToList }} messages will fail unless additional hosts are
            selected.
          </div>
        </ng-container>

        <ng-container *ngIf="hostError['hostWithoutPlatform']">
          <div>All selected hosts should have at least one relevant platform selected.</div>
        </ng-container>

        <ng-container *ngIf="hostError['propertyWithoutHost']">
          <div>All selected properties should have at least one relevant host selected.</div>
        </ng-container>
      </sbnb-alert-card>
      <div>
        <sbnb-search-bar placeholderText="Search..." (searchChanged)="hostsSearchUpdated($event)"></sbnb-search-bar>
      </div>
      <mat-spinner *ngIf="loadingHosts" diameter="16"></mat-spinner>

      <cdk-virtual-scroll-viewport itemSize="40" class="rules__scrollable">
        <ng-container *cdkVirtualFor="let host of filteredHosts$">
          <div
            fxLayout
            [matTooltip]="
              selectedHosts.indexOf(host.user_id + '') === -1 && !host.selectable
                ? host.platform_human + ' is not in the selected scope'
                : null
            ">
            <mat-checkbox
              [disabled]="!host.selectable"
              style="width: 100% !important"
              [checked]="(host.selectable && selectedAllHosts) || selectedHosts.indexOf(host.user_id + '') > -1"
              (change)="hostCheckboxChange(host, $event)">
              <span fxLayout fxLayoutGap="8px" fxLayoutAlign="start center">
                <img
                  [src]="host.picture"
                  class="host__img"
                  [ngClass]="{ 'opacity-50': !host.selectable }"
                  onerror="this.src='/assets/images/profile-default.png'" />
                <div class="host-platform-icon" fxLayoutAlign="center center">
                  <sbnb-icon
                    [size]="12"
                    [icon]="host.platform"
                    [ngClass]="{ 'opacity-50': !host.selectable }"></sbnb-icon>
                </div>
                <span fxFlex>{{ host.name }}</span>
              </span>
            </mat-checkbox>
            <span class='mr-4'>
              <sbnb-cs-debug-item class='mr-4' (click)='$event.stopPropagation()' [value]="host.user_id"></sbnb-cs-debug-item>
            </span>
          </div>
        </ng-container>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>

  <div
    [class.coloured-border]="!selectedAllProperties && selectedProperties.length === 0"
    [ngClass]="{
      'opacity-100 pointer-events-auto': hostsStagePassed,
      'opacity-0 pointer-events-none': !hostsStagePassed
    }"
    fxFlex
    class="scope-card">
    <div class="scope-card-header flex flex-wrap gap-3 items-center justify-between">
      <mat-checkbox [checked]="selectedAllProperties" (change)="propertyAllCheckboxChange($event)"></mat-checkbox>
      <h4>All properties</h4>
      <div class="flex-1 flex flex-wrap gap-x-3 items-end justify-end">
        <span class="night-lighter text__small" *ngIf="!selectedAllProperties">
          {{
            selectedProperties?.length - numDeletedProperties > -1
              ? selectedProperties?.length - numDeletedProperties
              : 0
          }}
          selected
        </span>
        <span role="button" class="link text__small" (click)="clearProperties()">Clear selection</span>
      </div>
    </div>
    <div class="scope-card-content">
      <div>
        <sbnb-search-bar placeholderText="Search..." (searchChanged)="propertySearchUpdated($event)"></sbnb-search-bar>
      </div>
      <cdk-virtual-scroll-viewport itemSize="40" class="rules__scrollable">
        <ng-container *cdkVirtualFor="let property of filteredProperties$">
          <div
            fxLayout
            [matTooltip]="property.listed === false ? 'This property is unlisted' : null"
            [class.property__unlisted]="property.listed === false">
            <mat-checkbox
              style="width: 100% !important"
              [checked]="(property.selectable && selectedAllProperties) || selectedProperties.indexOf(property.id) > -1"
              (change)="propertyCheckboxChange(property, $event)"
              [disabled]="!property.selectable">
              <span fxLayout fxLayoutGap="8px" fxLayoutAlign="start center">
                <img [src]="property.picture" class="property__img" />
                <span fxFlex>{{ property.name }}</span>
              </span>
            </mat-checkbox>
          </div>
        </ng-container>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
</div>
