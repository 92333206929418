import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiResponse } from '@app/shared/interfaces';
import { Pagination } from '@app/shared/interfaces/lib/pagination.interface';
import { Observable } from 'rxjs';
import {
  Currency,
  DataTooLargeMeta,
  ExportMetrics,
  MetricCurrencies,
  MetricDetailsTable,
  MetricEarlierExports,
  MetricFilterLabelPayload,
  MetricFilterLabelResponse,
  MetricFiltersResponse,
  MetricGraphs,
  MetricKey,
  MetricPayload,
  MetricStatistics,
  MetricView,
  SaveMetricViewPayload,
} from './metrics.models';

@Injectable({
  providedIn: 'root',
})
export class MetricsService {
  constructor(@Inject('apiMetricsUrl') private apiMetricsUrl: string, private http: HttpClient) { }

  loadCurrencies(): Observable<ApiResponse<MetricCurrencies>> {
    return this.http.get<ApiResponse<MetricCurrencies>>(`${this.apiMetricsUrl}/metrics/v1/currencies`);
  }

  updateDefaultCurrency(payload: Currency): Observable<ApiResponse<Currency>> {
    return this.http.post<ApiResponse<Currency>>(`${this.apiMetricsUrl}/metrics/v1/currencies`, payload);
  }

  // Reservations
  loadReservationCount(payload: MetricPayload): Observable<number> {
    return this.http.post<number>(`${this.apiMetricsUrl}/metrics/v1/exports/reservations/count`, payload);
  }

  exportReservationMetrics(payload: MetricPayload): Observable<ApiResponse<ExportMetrics>> {
    return this.http.post<ApiResponse<ExportMetrics>>(`${this.apiMetricsUrl}/metrics/v1/exports/reservations`, payload);
  }

  loadReservationViews(): Observable<ApiResponse<MetricView[], { pagination: Pagination }>> {
    return this.http.get<ApiResponse<MetricView[], { pagination: Pagination }>>(`${this.apiMetricsUrl}/metrics/v1/views/list/reservations`);
  }

  saveReservationView(payload: SaveMetricViewPayload): Observable<ApiResponse<MetricView>> {
    return this.http.post<ApiResponse<MetricView>>(`${this.apiMetricsUrl}/metrics/v1/views`, { ...payload, ...{ type: MetricKey.Reservations } });
  }

  updateReservationView(uuid: string, payload: SaveMetricViewPayload): Observable<ApiResponse<MetricView>> {
    return this.http.put<ApiResponse<MetricView>>(`${this.apiMetricsUrl}/metrics/v1/views/${uuid}`, { ...payload, ...{ type: MetricKey.Reservations } });
  }

  deleteView(uuid: string): Observable<ApiResponse<unknown>> {
    return this.http.delete<ApiResponse<unknown>>(`${this.apiMetricsUrl}/metrics/v1/views/${uuid}`);
  }

  loadReservationFilters(): Observable<ApiResponse<MetricFiltersResponse>> {
    return this.http.get<ApiResponse<MetricFiltersResponse>>(`${this.apiMetricsUrl}/metrics/v1/filters/reservations`);
  }

  loadReservationFilterLabel(key: string, payload: MetricFilterLabelPayload): Observable<ApiResponse<MetricFilterLabelResponse>> {
    return this.http.post<ApiResponse<MetricFilterLabelResponse>>(`${this.apiMetricsUrl}/metrics/v1/filters/reservations/${key}/humans`, {
      payload,
    });
  }

  loadReservationDetails(payload: MetricPayload, page?: number): Observable<ApiResponse<MetricDetailsTable[], { pagination: Pagination }>> {
    const limit = 20;
    const offset = page ? page * 20 - limit : 0;

    return this.http.post<ApiResponse<MetricDetailsTable[], { pagination: Pagination }>>(
      `${this.apiMetricsUrl}/metrics/v1/exports/reservations/details?limit=${limit}&offset=${offset}`,
      payload
    );
  }

  loadReservationGraphs(payload: MetricPayload): Observable<ApiResponse<MetricGraphs>> {
    return this.http.post<ApiResponse<MetricGraphs>>(`${this.apiMetricsUrl}/metrics/v1/exports/reservations/graphs`, payload);
  }

  loadReservationStatistics(payload: MetricPayload): Observable<ApiResponse<MetricStatistics, DataTooLargeMeta>> {
    return this.http.post<ApiResponse<MetricStatistics, DataTooLargeMeta>>(`${this.apiMetricsUrl}/metrics/v1/exports/reservations/statistics`, payload);
  }

  // Earlier Exports
  loadEarlierExports(page?: number): Observable<ApiResponse<MetricEarlierExports[], { pagination: Pagination }>> {
    const limit = 20;
    const offset = page ? page * 20 - limit : 0;
    return this.http.get<ApiResponse<MetricEarlierExports[], { pagination: Pagination }>>(
      `${this.apiMetricsUrl}/metrics/v1/exports?limit=${limit}&offset=${offset}`
    );
  }

  downloadExport(uuid: string): Observable<any> {
    return this.http.get(`${this.apiMetricsUrl}/metrics/v1/exports/${uuid}`, {
      observe: 'response',
      responseType: 'blob',
    });
  }

  // Taxes
  loadTaxesViews(): Observable<ApiResponse<MetricView[], { pagination: Pagination }>> {
    return this.http.get<ApiResponse<MetricView[], { pagination: Pagination }>>(`${this.apiMetricsUrl}/metrics/v1/views/list/taxes`);
  }

  saveTaxesView(payload: SaveMetricViewPayload): Observable<ApiResponse<MetricView>> {
    return this.http.post<ApiResponse<MetricView>>(`${this.apiMetricsUrl}/metrics/v1/views`, { ...payload, ...{ type: MetricKey.Taxes } });
  }

  updateTaxesView(uuid: string, payload: SaveMetricViewPayload): Observable<ApiResponse<MetricView>> {
    return this.http.put<ApiResponse<MetricView>>(`${this.apiMetricsUrl}/metrics/v1/views/${uuid}`, { ...payload, ...{ type: MetricKey.Taxes } });
  }

  loadTaxesCount(payload: MetricPayload): Observable<number> {
    return this.http.post<number>(`${this.apiMetricsUrl}/metrics/v1/exports/taxes/count`, payload);
  }

  exportTaxesMetrics(payload: MetricPayload): Observable<ApiResponse<ExportMetrics>> {
    return this.http.post<ApiResponse<ExportMetrics>>(`${this.apiMetricsUrl}/metrics/v1/exports/taxes`, payload);
  }

  loadTaxesFilters(): Observable<ApiResponse<MetricFiltersResponse>> {
    return this.http.get<ApiResponse<MetricFiltersResponse>>(`${this.apiMetricsUrl}/metrics/v1/filters/taxes`);
  }

  loadTaxesFilterLabel(key: string, payload: MetricFilterLabelPayload): Observable<ApiResponse<MetricFilterLabelResponse>> {
    return this.http.post<ApiResponse<MetricFilterLabelResponse>>(`${this.apiMetricsUrl}/metrics/v1/filters/taxes/${key}/humans`, {
      payload,
    });
  }

  loadTaxesDetails(payload: MetricPayload, page?: number): Observable<ApiResponse<MetricDetailsTable[], { pagination: Pagination }>> {
    const limit = 20;
    const offset = page ? page * 20 - limit : 0;

    return this.http.post<ApiResponse<MetricDetailsTable[], { pagination: Pagination }>>(
      `${this.apiMetricsUrl}/metrics/v1/exports/taxes/details?limit=${limit}&offset=${offset}`,
      payload
    );
  }

  loadTaxesSummary(payload: MetricPayload, page?: number): Observable<ApiResponse<MetricDetailsTable[], { pagination: Pagination }>> {
    const limit = 20;
    const offset = page ? page * 20 - limit : 0;

    return this.http.post<ApiResponse<MetricDetailsTable[], { pagination: Pagination }>>(
      `${this.apiMetricsUrl}/metrics/v1/exports/taxes/summary?limit=${limit}&offset=${offset}`,
      payload
    );
  }

  loadTaxesGraphs(payload: MetricPayload): Observable<ApiResponse<MetricGraphs>> {
    return this.http.post<ApiResponse<MetricGraphs>>(`${this.apiMetricsUrl}/metrics/v1/exports/taxes/graphs`, payload);
  }

  loadTaxesStatistics(payload: MetricPayload): Observable<ApiResponse<MetricStatistics, DataTooLargeMeta>> {
    return this.http.post<ApiResponse<MetricStatistics, DataTooLargeMeta>>(`${this.apiMetricsUrl}/metrics/v1/exports/taxes/statistics`, payload);
  }

  // Reviews
  loadReviewsViews(): Observable<ApiResponse<MetricView[], { pagination: Pagination }>> {
    return this.http.get<ApiResponse<MetricView[], { pagination: Pagination }>>(`${this.apiMetricsUrl}/metrics/v1/views/list/reviews`);
  }

  saveReviewsView(payload: SaveMetricViewPayload): Observable<ApiResponse<MetricView>> {
    return this.http.post<ApiResponse<MetricView>>(`${this.apiMetricsUrl}/metrics/v1/views`, { ...payload, ...{ type: MetricKey.Reviews } });
  }

  updateReviewsView(uuid: string, payload: SaveMetricViewPayload): Observable<ApiResponse<MetricView>> {
    return this.http.put<ApiResponse<MetricView>>(`${this.apiMetricsUrl}/metrics/v1/views/${uuid}`, { ...payload, ...{ type: MetricKey.Reviews } });
  }

  loadReviewsCount(payload: MetricPayload): Observable<number> {
    return this.http.post<number>(`${this.apiMetricsUrl}/metrics/v1/exports/reviews/count`, payload);
  }

  exportReviewsMetrics(payload: MetricPayload): Observable<ApiResponse<ExportMetrics>> {
    return this.http.post<ApiResponse<ExportMetrics>>(`${this.apiMetricsUrl}/metrics/v1/exports/reviews`, payload);
  }

  loadReviewsFilters(): Observable<ApiResponse<MetricFiltersResponse>> {
    return this.http.get<ApiResponse<MetricFiltersResponse>>(`${this.apiMetricsUrl}/metrics/v1/filters/reviews`);
  }

  loadReviewsFilterLabel(key: string, payload: MetricFilterLabelPayload): Observable<ApiResponse<MetricFilterLabelResponse>> {
    return this.http.post<ApiResponse<MetricFilterLabelResponse>>(`${this.apiMetricsUrl}/metrics/v1/filters/reviews/${key}/humans`, {
      payload,
    });
  }

  loadReviewsDetails(payload: MetricPayload, page?: number): Observable<ApiResponse<MetricDetailsTable[], { pagination: Pagination }>> {
    const limit = 20;
    const offset = page ? page * 20 - limit : 0;

    return this.http.post<ApiResponse<MetricDetailsTable[], { pagination: Pagination }>>(
      `${this.apiMetricsUrl}/metrics/v1/exports/reviews/details?limit=${limit}&offset=${offset}`,
      payload
    );
  }

  loadReviewsGraphs(payload: MetricPayload): Observable<ApiResponse<MetricGraphs>> {
    return this.http.post<ApiResponse<MetricGraphs>>(`${this.apiMetricsUrl}/metrics/v1/exports/reviews/graphs`, payload);
  }

  loadReviewsStatistics(payload: MetricPayload): Observable<ApiResponse<MetricStatistics, DataTooLargeMeta>> {
    return this.http.post<ApiResponse<MetricStatistics, DataTooLargeMeta>>(`${this.apiMetricsUrl}/metrics/v1/exports/reviews/statistics`, payload);
  }

  // Tasks
  loadTasksViews(): Observable<ApiResponse<MetricView[], { pagination: Pagination }>> {
    return this.http.get<ApiResponse<MetricView[], { pagination: Pagination }>>(`${this.apiMetricsUrl}/metrics/v1/views/list/tasks`);
  }

  saveTasksView(payload: SaveMetricViewPayload): Observable<ApiResponse<MetricView>> {
    return this.http.post<ApiResponse<MetricView>>(`${this.apiMetricsUrl}/metrics/v1/views`, { ...payload, ...{ type: MetricKey.Tasks } });
  }

  updateTasksView(uuid: string, payload: SaveMetricViewPayload): Observable<ApiResponse<MetricView>> {
    return this.http.put<ApiResponse<MetricView>>(`${this.apiMetricsUrl}/metrics/v1/views/${uuid}`, { ...payload, ...{ type: MetricKey.Tasks } });
  }

  loadTasksCount(payload: MetricPayload): Observable<number> {
    return this.http.post<number>(`${this.apiMetricsUrl}/metrics/v1/exports/tasks/count`, payload);
  }

  exportTasksMetrics(payload: MetricPayload): Observable<ApiResponse<ExportMetrics>> {
    return this.http.post<ApiResponse<ExportMetrics>>(`${this.apiMetricsUrl}/metrics/v1/exports/tasks`, payload);
  }

  loadTasksFilters(): Observable<ApiResponse<MetricFiltersResponse>> {
    return this.http.get<ApiResponse<MetricFiltersResponse>>(`${this.apiMetricsUrl}/metrics/v1/filters/tasks`);
  }

  loadTasksFilterLabel(key: string, payload: MetricFilterLabelPayload): Observable<ApiResponse<MetricFilterLabelResponse>> {
    return this.http.post<ApiResponse<MetricFilterLabelResponse>>(`${this.apiMetricsUrl}/metrics/v1/filters/tasks/${key}/humans`, {
      payload,
    });
  }

  loadTasksDetails(payload: MetricPayload, page?: number): Observable<ApiResponse<MetricDetailsTable[], { pagination: Pagination }>> {
    const limit = 20;
    const offset = page ? page * 20 - limit : 0;

    return this.http.post<ApiResponse<MetricDetailsTable[], { pagination: Pagination }>>(
      `${this.apiMetricsUrl}/metrics/v1/exports/tasks/details?limit=${limit}&offset=${offset}`,
      payload
    );
  }
}
