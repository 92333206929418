export enum Channel {
  Airbnb = 'airbnb',
  AirbnbOfficial = 'airbnb-official',
  Booking = 'booking',
  Vrbo = 'homeaway',
  Agoda = 'agoda',
  Manual = 'manual',
  Direct = 'direct',
}

export enum IcalChannel {
  Hopper = 'hopper.com',
  HipCamp = 'hipcamp.com',
  Houfy = 'houfy.com',
  Lodgify = 'lodgify.com',
  OwnerRez = 'ownerrez.com',
  MisterBnB = 'misterbandb.com',
  Hostfully = 'hostfully.com',
  FurnishedFinder = 'furnishedfinder.com',
  Expedia = 'expedia.com',
  Tripadvisor = 'tripadvisor.com',
}
