import { EventsMap } from 'redux-beacon';
import * as fromActions from './metrics.actions';
import { ExportMetrics, MetricKey, MetricView } from './metrics.models';
import { ApiResponse, EventType, SegmentEvent } from '@app/shared/interfaces';
import { trackEvent } from '@app/shared/analytics';

const type = EventType.Action;

function getExportParameters(type: MetricKey, response: ApiResponse<ExportMetrics>) {
  if (!response) {
    return {
      type,
    };
  }

  const filterKeys = response.data && response.data.scope ? Object.keys(response.data.scope) : null;

  return {
    type,
    filters: filterKeys ? filterKeys : null,
    filterCount: filterKeys ? filterKeys.length : null,
    oneFilePerProperty: response.data && response.data.one_file_per_property ? response.data.one_file_per_property : false,
    includeKPIs: response.data && response.data.include_kpis ? response.data.include_kpis : false,
  };
}


const ExportReservationMetricsSuccess = trackEvent((action) => ({
  type,
  name: SegmentEvent.MetricsExport,
  parameters: getExportParameters(MetricKey.Reservations, action['response'])
}));

const ExportTaxesMetricsSuccess = trackEvent((action) => ({
  type,
  name: SegmentEvent.MetricsExport,
  parameters: getExportParameters(MetricKey.Taxes, action['response'])
}));

const ExportReviewsMetricsSuccess = trackEvent((action) => ({
  type,
  name: SegmentEvent.MetricsExport,
  parameters: getExportParameters(MetricKey.Reviews, action['response'])
}));

const ExportTasksMetricsSuccess = trackEvent((action) => ({
  type,
  name: SegmentEvent.MetricsExport,
  parameters: getExportParameters(MetricKey.Tasks, action['response'])
}));

function getSaveViewParameters(type: MetricKey, response: ApiResponse<MetricView>) {
  if (!response) {
    return {
      type,
    };
  }

  return {
    type,
    filters: response.data && response.data.filters ? response.data.filters.map((filter) => filter.key) : null,
    filterCount: response.data && response.data.filters ? response.data.filters.length : null,
    publiclyAvailable: response.data && response.data.publicly_available ? response.data.publicly_available : null,
  };
}

const SaveReservationViewsSuccess = trackEvent((action) => ({
  type,
  name: SegmentEvent.SavedMetricView,
  parameters: getSaveViewParameters(MetricKey.Reservations, action['response']),
}));

const SaveTaxesViewsSuccess = trackEvent((action) => ({
  type,
  name: SegmentEvent.SavedMetricView,
  parameters: getSaveViewParameters(MetricKey.Taxes, action['response']),
}));
const SaveReviewsViewsSuccess = trackEvent((action) => ({
  type,
  name: SegmentEvent.SavedMetricView,
  parameters: getSaveViewParameters(MetricKey.Reviews, action['response']),
}));
const SaveTasksViewsSuccess = trackEvent((action) => ({
  type,
  name: SegmentEvent.SavedMetricView,
  parameters: getSaveViewParameters(MetricKey.Tasks, action['response']),
}));

export const MetricsEvents: EventsMap = {
  [fromActions.exportReservationMetricsSuccess.type]: ExportReservationMetricsSuccess,
  [fromActions.exportTaxesMetricsSuccess.type]: ExportTaxesMetricsSuccess,
  [fromActions.exportReviewsMetricsSuccess.type]: ExportReviewsMetricsSuccess,
  [fromActions.exportTasksMetricsSuccess.type]: ExportTasksMetricsSuccess,

  [fromActions.saveReservationViewsSuccess.type]: SaveReservationViewsSuccess,
  [fromActions.saveTaxesViewsSuccess.type]: SaveTaxesViewsSuccess,
  [fromActions.saveReviewsViewsSuccess.type]: SaveReviewsViewsSuccess,
  [fromActions.saveTasksViewsSuccess.type]: SaveTasksViewsSuccess,
};
