import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DonutChartComponent } from './donut-chart.component';
import { NgChartsModule } from 'ng2-charts';
import { PageLevelErrorComponentModule } from '@app/ui/page-level-error/page-level-error.component.module';

@NgModule({
  imports: [CommonModule, NgChartsModule, PageLevelErrorComponentModule],
  declarations: [DonutChartComponent],
  exports: [DonutChartComponent],
})
export class DonutChartComponentModule {}
