export enum SignupChannel {
  TripAdvisor = 'tripadvisor',
  BookingDotCom = 'booking_com',
  Vrbo = 'vrbo',
  Airbnb = 'airbnb',
  Agoda = 'agoda',
  Direct = 'direct_booking',
  Other = 'other_platform',
}

export interface Testimonial {
  platform: string;
  user: { name: string };
  title: string;
  review: string;
  review_url?: string;
}

// Note: direct is supported, but we want to show the just to give heads up message if they just seelct direct
export const SupportedChannels = [
  SignupChannel.BookingDotCom,
  SignupChannel.Vrbo,
  SignupChannel.Airbnb,
  SignupChannel.Direct,
];

export const UnsupportedChannels = [SignupChannel.TripAdvisor, SignupChannel.Agoda];

export interface SignupChannelWrapper {
  key: SignupChannel;
  enabled: boolean;
  label: string;
  asset?: string;
}
