import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebsocketService } from './websocket.service';
import { WebsocketConfig } from './websocket.interface';
import { WebsocketConfigService } from './websocket-config.service';
import { WebsocketFacade } from './websocket.facade';

@NgModule({
  imports: [CommonModule],
})
export class WebsocketModule {
  constructor(@Optional() @SkipSelf() parentModule?: WebsocketModule) {
    if (parentModule) {
      throw new Error('WebsocketModule is already loaded. Import it in the AppModule only');
    }
  }
  static forRoot(config: WebsocketConfig): ModuleWithProviders<WebsocketModule> {
    return {
      ngModule: WebsocketModule,
      providers: [
        WebsocketService,
        WebsocketFacade,
        {
          provide: WebsocketConfigService,
          useValue: config,
        },
      ],
    };
  }
}
