import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, Money, PropertyList } from '@app/shared/interfaces';
import { Filter } from '@app/shared/models/filter';
import { LaravelPagination } from '@app/shared/models/pagination-meta';
import { DemoService } from '@app/shared/services/demo/demo.service';
import { FilterService } from '@app/shared/services/filter/filter.service';
import { PropertiesService } from '@app/shared/services/properties/properties.service';
import { SegmentEvent, SegmentIoService } from '@app/shared/services/segmentIo/segment-io.service';
import { environment } from '@env/environment';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DirectSubscriptionService } from './direct-subscription.service';

export interface PayoutHistoryStatsApiResponse {
  balance?: Money;
  paid?: Money;
  scheduled?: Money;
}

export interface DirectChannelMetricsApiResponse {
  bookings: number;
  clicks: number;
  impressions: number;
  start_date: string;
  end_date: string;
}

export interface PayoutHistoryApiResponse {
  data: PayoutHistoryParent[];
  links: unknown; // we don't use this
  meta: LaravelPagination;
}

export interface PayoutHistoryParent {
  reservation_uuid: string;
  reservation_code: string;
  status: PayoutHistoryStatus;
  property_name: string;
  account_holder_name: string;
  amount: Money | null;
  payout_at: string; // "2023-08-02 00:16:13" - the new version of latest_actiity_at which will be removed
  events: PayoutHistoryChild[];
}

export enum PayoutHistoryStatus {
  SCHEDULED = 'scheduled',
  SETTLED = 'settled',
  FAILED = 'failed',
}

export interface PayoutHistoryChild {
  title: string;
  details: string | null;
  occurred_at: string; // "2023-08-02 00:16:13"
  amount: Money;
  payout_status: PayoutHistoryChildStatus | null;
  hide_amount: boolean;
}

export enum PayoutHistoryChildStatus {
  SCHEDULED = 'scheduled',
  INITIATED = 'initiated',
  CONFIRMED = 'confirmed',
  FAILED = 'failed',
  CANCELLED = 'cancelled',
}

export interface GetInstantBookSettingsAPIResponse {
  data: InstantBookSettings;
}

export interface InstantBookSettings {
  instant_book_enabled: boolean;
  instant_book_days_before_checkin: number;
}

export interface Domain {
  custom_domain?: string;
  hospitable_domain: string;
  subdomain: string;
  ssl_status?: SiteSSLStatuses;
  cname_verified?: boolean;
}

export interface GuestVetting {
  auto_accept?: boolean;
}

export interface Payments {
  merchant_of_record?: string;
}

export interface FullTemplate {
  id: string;
  name: string;
  slug: string;
  picture: string;
  description: string;
  fields: unknown;
}

export type Template = Pick<FullTemplate, 'id'>;

export interface SiteCreateRequest {
  domain: Domain;
  guest_vetting: GuestVetting;
  payments: Payments;
  properties: number[];
  include_future_properties: boolean;
  title: string;
  template?: string;
}

export interface SiteCreateResponse {
  id: string;
  title: string;
  domains: Domain;
  template: Template;
  properties: number[];
  include_future_properties: boolean;
  publish_status: SitePublishStatuses;
  preview_url: string;
  guest_vetting: GuestVetting;
  payments: Payments;
}

export enum SitePublishStatuses {
  NotPublishedYet = 'NOT_PUBLISHED_YET',
  Unpublished = 'UNPUBLISHED',
  Published = 'PUBLISHED',
}

export enum SiteSSLStatuses {
  None = 'none',
  InProgress = 'in_progress',
  Complete = 'complete',
  Failed = 'failed',
}

interface ModifyPropertiesResponse {
  status: 'queued';
}

export interface DirectWidget {
  uuid: string;
  team_id: number;
  status: DirectWidgetStatus;
  name: string;
  theme: string;
  all_properties: boolean;
  properties: number[];
  property_list?: any[];
}

export enum DirectWidgetStatus {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
}

export enum DirectChannel {
  GVR = 'gvr',
}

export type DirectWidgetCreateRequest = Pick<
  DirectWidget,
  'status' | 'name' | 'theme' | 'all_properties' | 'properties'
>;

export interface PropertyPhotoTag {
  key: string;
  label: string;
  photo?: {
    image: string;
    id: number;
    caption: string;
  };
}

export interface CancellationPolicy {
  uuid: string;
  name: string;
  default: boolean;
  properties: number[];
  property_list?: PropertyList[];
  terms: CancellationTerm[];
  unscopedProperties?: number[];

  payment_terms_status?: PaymentTermStatus;
  long_stay_days?: number | null;
  grace_period_in_hours?: number | null;
  host_only_service_fee_enabled: boolean;

  // On FE
  computed?: {
    paymentTerms: CancellationTerm[];
    cancellationTerms: CancellationTerm[];
  };
}

export enum PaymentTermStatus {
  Disabled = 'disabled',
  Manual = 'manual',
  Aligned = 'aligned',
}

export interface CancellationTerm {
  enabled: boolean;
  is_long_stay?: boolean;
  type: CancellationTermType;
  percentage: number;
  label: string;
  trigger_time?: {
    amount: number;
    units: 'hours' | 'days' | 'weeks';
  };
  days_before_checkin_restriction?: {
    amount: number;
    units: 'days';
  } | null;
  restriction?: {
    amount: number;
    units: string;
  };
}

export enum CancellationTermType {
  // Cancellation Policy Terms
  AfterBooking = 'after-accepted',
  BeforeCheckIn = 'before-check-in',
  UntilCheckIn = 'until-check-in',

  // Payment terms
  PaymentTermsAfterAccepted = 'payment-terms-after-accepted',
  PaymentTermsBeforeCheckin = 'payment-terms-before-check-in',
}

export enum PromoCodeType {
  Percentage = 'percentage',
  Fixed = 'fixed',
}

export interface PromoCode {
  uuid: string;
  enabled: boolean;
  codes: string[];
  discount_amount: number;
  discount_type: PromoCodeType;
  max_discount: number;
  restrictions: {
    min_length_of_stay: number;
    min_booking_value: number;
    max_uses: number;
    expires_at: string;
    earliest_check_in_at: string;
    latest_check_out_at: string;
    stacks_with_length_of_stay_discounts: boolean;
  };
  properties: number[];
  property_list?: PropertyList[];
  reservations: unknown[];
  currency?: string;
}

export interface PropertySearchWidget {
  uuid: string;
  enabled: boolean;
  locations: PropertySearchWidgetLocation[];
  show_map: boolean;
}

export interface PropertySearchWidgetLocation {
  name: string;
  lat: string;
  lng: string;
  children: PropertySearchWidgetLocation[];
}

export interface SiteStatistics {
  period: string;
  statistics: Array<{
    title: string;
    value: string;
    tooltip: string | null;
  }>;
}

export interface SiteMetas {
  ga_id: string | null;
  gtm_id: string | null;
  meta_pixel_id: string | null;
}

export interface PropertySite {
  site_id: string;
  property_id: number;
  url: string | null;
}

export interface PayoutMethod {
  uuid: string;
  enabled: boolean;
  all_properties: boolean;
  name: string | null;
  derived_name: string | null;
  property_scope: [];
  provider: string;
}

@Injectable({
  providedIn: 'root',
})
export class DirectService {
  constructor(
    private http: HttpClient,
    private demoService: DemoService,
    private filterService: FilterService,
    private segment: SegmentIoService,
    private propertiesService: PropertiesService,
    private directSubscriptionService: DirectSubscriptionService
  ) {
    if (this.demoService.isDemo()) {
      this.apiUrl = environment.apiUrl + '/sites';
    }
  }

  monolithApi = environment.apiUrl;
  apiUrl = environment.siteApiUrl;
  bookingApiUrl = environment.bookingApiUrl;

  getSubdomains() {
    return this.http.get(`${this.apiUrl}/domains`);
  }

  checkDomainAvailability(domain: string) {
    return this.http.get(`${this.apiUrl}/domains/${domain}`);
  }

  createSite(payload: SiteCreateRequest) {
    return this.http.post(this.apiUrl, payload);
  }

  private mergeProperties(items: any, properties: any[]) {
    return items.map((item) => {
      item.property_list = item.properties
        .map((propertyId) => {
          if (properties && properties.length > 0) {
            const property = properties.find((p) => p.id === propertyId);
            return property
              ? {
                  id: property.id,
                  name: property.name,
                  picture: property.picture,
                }
              : null;
          }

          return null;
        })
        .filter((property) => property !== null);
      return item;
    });
  }

  getPropertySites(propertyId: number) {
    return this.http.get(`${this.apiUrl}/property/${propertyId}/sites`).pipe(
      map((res: ApiResponse<PropertySite[]>) => {
        return res.data;
      })
    );
  }

  getSites() {
    const sites$ = this.http.get<{ data: any[] }>(this.apiUrl);
    const properties$ = this.propertiesService
      .getProperties({
        paginate: false,
        transformer: 'simple',
      })
      .pipe(map((res: { data: any[] }) => res.data));

    return combineLatest([sites$, properties$]).pipe(
      map(([sitesResponse, properties]) => {
        return this.mergeProperties(sitesResponse.data, properties);
      })
    );
  }

  getWidgets() {
    const widgets$ = this.http.get<{ data: DirectWidget[] }>(`${this.apiUrl}/widgets`);
    const properties$ = this.propertiesService
      .getProperties({
        paginate: false,
        transformer: 'simple',
      })
      .pipe(map((res: { data: any[] }) => res.data));

    return combineLatest([widgets$, properties$]).pipe(
      map(([widgetsResponse, properties]) => {
        return this.mergeProperties(widgetsResponse.data, properties);
      })
    );
  }

  getSite(siteId: string) {
    let headers = new HttpHeaders();
    headers = headers.set('content-type', 'application/json');

    return this.http.get(`${this.apiUrl}/${siteId}`, { headers }).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getSiteStatistics(siteId: string, period: string): Observable<SiteStatistics> {
    const params = { period };

    return this.http.get(`${this.apiUrl}/${siteId}/statistics`, { params }).pipe(
      map((res: ApiResponse<SiteStatistics>) => {
        return res.data;
      })
    );
  }

  updateSite(siteId: string, payload: { title?; guest_vetting?; payments? }): Observable<SiteCreateResponse> {
    return <Observable<SiteCreateResponse>>this.http.put(`${this.apiUrl}/${siteId}`, payload);
  }

  publishSite(siteId: string): Observable<ModifyPropertiesResponse> {
    return this.http.post<ModifyPropertiesResponse>(`${this.apiUrl}/${siteId}/publish`, {});
  }

  unpublishSite(siteId: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/${siteId}/unpublish`, {});
  }

  getSiteContent(siteId: string) {
    return this.http.get(`${this.apiUrl}/${siteId}/content`).pipe(
      map((res: any) => {
        return res.data
          .sort((a, b) => a.order - b.order)
          .reduce((r, a) => {
            r[a.group] = r[a.group] || [];
            r[a.group].push(a);
            return r;
          }, Object.create(null));
      })
    );
  }

  setSiteContent(siteId: string, content: { content: Object }) {
    // Remove groupings before updating
    let payload = [];
    Object.values(content.content).forEach((element) => {
      payload = payload.concat(element);
    });

    return this.http.put(`${this.apiUrl}/${siteId}/content`, {
      content: payload,
    });
  }

  getSiteMetas(siteId: string): Observable<SiteMetas> {
    return this.http.get(`${this.apiUrl}/${siteId}/metas`).pipe(
      map((res: ApiResponse<SiteMetas>) => {
        return res.data;
      })
    );
  }

  updateSiteMetas(
    siteId: string,
    gaId: string | null,
    gtmId: string | null,
    metaId: string | null
  ): Observable<SiteMetas> {
    return this.http
      .put(`${this.apiUrl}/${siteId}/metas`, {
        ga_id: gaId,
        gtm_id: gtmId,
        meta_pixel_id: metaId,
      })
      .pipe(
        map((res: ApiResponse<SiteMetas>) => {
          return res.data;
        })
      );
  }

  getPhotoTagsForProperty(propertyId: string) {
    return this.http.get<{ data: PropertyPhotoTag[] }>(`${this.apiUrl}/property/${propertyId}/tags`).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  setPhotoTagForProperty(propertyId: string, imageId: number, tagKey: string) {
    return this.http.put(`${this.apiUrl}/property/${propertyId}/tags`, {
      tag: tagKey,
      photo_id: imageId,
    });
  }

  saveSiteProperties(
    siteId: string,
    siteType = 'internal',
    properties: number[],
    include_future_properties: boolean
  ): Observable<ModifyPropertiesResponse> {
    return <Observable<ModifyPropertiesResponse>>this.http.put(`${this.apiUrl}/properties/${siteType}/${siteId}`, {
      properties,
      include_future_properties,
    });
  }

  deleteSite(siteId: string) {
    return this.http.delete(`${this.apiUrl}/${siteId}`);
  }

  addCustomDomainToSite(siteId: string, domain: string) {
    return this.http.post(`${this.apiUrl}/${siteId}/custom-domain`, {
      custom_domain: domain,
    });
  }

  removeCustomDomainFromSite(siteId: string) {
    return this.http.delete(`${this.apiUrl}/${siteId}/custom-domain`);
  }

  verifyDomainDNS(siteId: string): Observable<{ success: boolean }> {
    return this.http.get(`${this.apiUrl}/${siteId}/dns`).pipe(
      map((res: any) => {
        if (res && res.success) {
          return { success: true };
        }

        return { success: false };
      }),
      catchError((err) => {
        return of({ success: false });
      })
    );
  }

  fetchPayoutHistory(pageNum = 1, searchQuery: string = null, filters: Filter[]): Observable<PayoutHistoryApiResponse> {
    let url = `${this.bookingApiUrl}/payout-history?page=${pageNum}`;
    if (searchQuery) {
      url += `&query=${searchQuery}`;
    }

    const payload: any = {};

    if (filters?.length > 0) {
      payload.filters = this.filterService.transformFilters(filters);
    }

    return this.http.post<PayoutHistoryApiResponse>(url, payload).pipe(map((res) => res));
  }

  fetchPayoutHistoryStats(): Observable<PayoutHistoryStatsApiResponse> {
    const directSub = this.directSubscriptionService.getCurrentSubscriptionStatus();
    return this.http.get<PayoutHistoryStatsApiResponse>(
      `${this.bookingApiUrl}/payout-history/statistics?active_plan=${directSub?.plan}`
    );
  }

  fetchChannelMetrics(channel: DirectChannel): Observable<DirectChannelMetricsApiResponse> {
    return this.http.get<DirectChannelMetricsApiResponse>(`${this.bookingApiUrl}/channels/${channel}/metrics`);
  }

  createStripeOauthPayoutMethod(code: string) {
    return this.http
      .post(`${this.bookingApiUrl}/payout-methods/stripe-oauth`, {
        code,
      })
      .pipe(
        map((res: any) => {
          return res.data;
        }),
        catchError((err: HttpErrorResponse) => {
          this.segment.track(SegmentEvent.DirectPayoutStripeOauthFailedToConnect, {
            code,
          });
          return of({ error: true, message: err.error.error });
        })
      );
  }

  getSiteTemplates() {
    return this.http.get<{ data: FullTemplate[] }>(`${this.apiUrl}/templates`).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  switchSiteTemplate(siteId: string, newTemplateId: string) {
    return this.http.put<{ status: string }>(`${this.apiUrl}/${siteId}/switch-template`, {
      template_id: newTemplateId,
    });
  }

  createWidget(payload: DirectWidgetCreateRequest): Observable<{ data: DirectWidget }> {
    return this.http.post<{ data: DirectWidget }>(`${this.apiUrl}/widgets`, payload);
  }

  updateWidget(widgetUuid: string, payload: DirectWidgetCreateRequest) {
    return this.http.put(`${this.apiUrl}/widgets/${widgetUuid}`, payload);
  }

  deleteWidget(widgetUuid: string) {
    return this.http.delete(`${this.apiUrl}/widgets/${widgetUuid}`);
  }

  fetchCancellationPolicies(): Observable<CancellationPolicy[]> {
    const policies$ = this.http.get<{ data: CancellationPolicy[] }>(`${this.bookingApiUrl}/cancellation-policies`);

    const properties$ = this.propertiesService
      .getProperties({
        paginate: false,
        transformer: 'simple',
      })
      .pipe(map((res: { data: any[] }) => res.data));

    return combineLatest([policies$, properties$]).pipe(
      map(([policiesResponse, properties]) => {
        const mergedPolicies = this.mergeProperties(policiesResponse.data, properties);

        // For each policy, sort the terms
        mergedPolicies.forEach((policy) => {
          if (policy.terms) {
            policy.terms = this.sortCancellationTerms(policy.terms);
          }
        });

        return mergedPolicies;
      })
    );
  }

  fetchCancellationPolicy(uuid: string): Observable<CancellationPolicy> {
    return this.fetchCancellationPolicies().pipe(
      map((policies) => {
        const policy = policies.find((policy) => policy.uuid === uuid);

        // We need to gather a list of properties that DON'T have a policy explicitly set, and so will be subject to this default policy
        // So we can show these in the disabled property list
        const unscopedProperties = [];

        this.propertiesService
          .getProperties({
            paginate: false,
            transformer: 'simple',
          })
          .pipe(map((res: { data: any[] }) => res.data))
          .subscribe((properties) => {
            properties.forEach((property) => {
              let propertyIsScoped = false;

              policies.forEach((policy) => {
                if (policy.properties && policy.properties.includes(property.id)) {
                  propertyIsScoped = true;
                }
              });

              if (!propertyIsScoped) {
                unscopedProperties.push(property.id);
              }
            });
          });

        policy.unscopedProperties = unscopedProperties;

        return policy;
      })
    );
  }

  createNewCancellationPolicy(policy: CancellationPolicy): Observable<any> {
    return this.http.post<any>(`${this.bookingApiUrl}/cancellation-policies`, policy);
  }

  updateCancellationPolicy(policy: CancellationPolicy): Observable<{ data: CancellationPolicy }> {
    return this.http.patch<{ data: CancellationPolicy }>(
      `${this.bookingApiUrl}/cancellation-policies/${policy.uuid}`,
      policy
    );
  }

  deleteCancellationPolicy(uuid: string) {
    return this.http.delete(`${this.bookingApiUrl}/cancellation-policies/${uuid}`);
  }

  fetchInstantBookSettings(): Observable<InstantBookSettings> {
    return this.http.get<GetInstantBookSettingsAPIResponse>(`${this.monolithApi}/direct/instant-book`).pipe(
      map((res) => {
        if (res.data && res.data.instant_book_days_before_checkin === null) {
          res.data.instant_book_days_before_checkin = 0;
        }
        return res.data;
      })
    );
  }

  saveInstantBookSettings(settings: InstantBookSettings) {
    return this.http.put(`${this.monolithApi}/direct/instant-book`, settings);
  }

  unitToValue(unit: 'hours' | 'days' | 'weeks'): number {
    switch (unit) {
      case 'hours':
        return 60;
      case 'days':
        return 60 * 24;
      case 'weeks':
        return 60 * 24 * 7;
      default:
        return 0;
    }
  }

  sortCancellationTerms(terms: CancellationTerm[]): CancellationTerm[] {
    const result = terms.sort((a, b) => {
      // Compare by type first
      if (a.type !== b.type) {
        // If a is 'after-accepted' and b is not, a should come first (return -1)
        // If b is 'after-accepted' and a is not, b should come first (return 1)
        return a.type === 'after-accepted' || a.type === 'payment-terms-after-accepted' ? -1 : 1;
      }

      // If types are the same, then compare by time value
      const aValue = this.unitToValue(a.trigger_time.units) * a.trigger_time.amount;
      const bValue = this.unitToValue(b.trigger_time.units) * b.trigger_time.amount;

      // Use the existing time-based comparison
      return bValue - aValue;
    });

    return result;
  }

  getSiteFullURL(site: SiteCreateResponse) {
    if (site.domains.custom_domain) {
      return site.domains.custom_domain;
    } else {
      return site.domains.subdomain + '.' + site.domains.hospitable_domain;
    }
  }

  getPromoCodes(): Observable<PromoCode[]> {
    const promos$ = this.http.get(`${this.bookingApiUrl}/promotions`).pipe(
      map((res: { data: PromoCode[] }) => {
        return res.data;
      })
    );

    const properties$ = this.propertiesService
      .getProperties({
        paginate: false,
        transformer: 'simple',
      })
      .pipe(map((res: { data: any[] }) => res.data));

    return combineLatest([promos$, properties$]).pipe(
      map(([promos, properties]) => {
        return this.mergeProperties(promos, properties);
      })
    );
  }

  getPromoCode(uuid: string): Observable<PromoCode> {
    return this.http.get(`${this.bookingApiUrl}/promotions`).pipe(
      map((res: { data: PromoCode[] }) => {
        return res.data.find((promoCode) => promoCode.uuid === uuid);
      })
    );
  }

  createPromoCode(code: Omit<PromoCode, 'uuid'>): Observable<PromoCode> {
    return this.http.post<{ data: PromoCode }>(`${this.bookingApiUrl}/promotions`, code).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  updatePromoCode(code: PromoCode): Observable<PromoCode> {
    return this.http.put<{ data: PromoCode }>(`${this.bookingApiUrl}/promotions/${code.uuid}`, code).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  deletePromoCode(codeUuid: string): Observable<any> {
    return this.http.delete(`${this.bookingApiUrl}/promotions/${codeUuid}`);
  }

  enablePromoCode(codeUuid: string): Observable<any> {
    return this.http.put(`${this.bookingApiUrl}/promotions/${codeUuid}/enable`, {});
  }

  disablePromoCode(codeUuid: string): Observable<any> {
    return this.http.put(`${this.bookingApiUrl}/promotions/${codeUuid}/disable`, {});
  }

  promoCodeCheckPropertyRestrictions(promoCode: PromoCode): Observable<any[]> {
    const promoCodes$: Observable<PromoCode[]> = this.getPromoCodes();
    const propertiesNotMatchingCurrency$: Observable<any> = this.propertiesService
      .getProperties({
        filterCriteria: [
          { id: 'properties-currency', type: 'list', selectedValue: { key: promoCode.currency }, inverted: true },
        ],
        paginate: false,
        transformer: 'simple',
      })
      .pipe(map((res) => res.data));

    return combineLatest([promoCodes$, propertiesNotMatchingCurrency$]).pipe(
      map(([promoCodes, propertiesNotMatchingCurrency]) => {
        let overlappingProperties = [];

        const overlappingCodes = promoCodes.filter(
          (existingPromo) =>
            existingPromo.uuid !== promoCode.uuid && existingPromo.codes.some((code) => promoCode.codes.includes(code))
        );

        overlappingCodes.forEach((code) => {
          overlappingProperties.push(...code.properties);
        });

        // Add properties from propertiesNotMatchingCurrency$ to overlappingProperties. Dont do this for only fixed promo codes
        promoCode.discount_type === PromoCodeType.Fixed
          ? overlappingProperties.push(...propertiesNotMatchingCurrency.map((property) => property.id))
          : null;

        overlappingProperties = [...new Set(overlappingProperties)];

        overlappingProperties = overlappingProperties.map((property) => {
          const reason = overlappingCodes.some((code) => code.properties.includes(property))
            ? 'This property is already included in another promo code with the same code.'
            : 'This property does not match the currency set for the promo code.';

          return {
            id: property,
            reason,
          };
        });

        return overlappingProperties;
      })
    );
  }

  fetchPremiumTaxEstimates(propertyId: number) {
    return this.http.get(`${this.bookingApiUrl}/properties/${propertyId}/estimated-taxes`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getPropertySearchWidgetDetails(siteId: string, parentType: string): Observable<PropertySearchWidget> {
    return this.http.get(`${this.bookingApiUrl}/mps/widget/${parentType}/${siteId}`).pipe(
      map((res: { data: PropertySearchWidget }) => {
        return res.data;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  updatePropertySearchWidget(siteId: string, parentType: string, widget: PropertySearchWidget) {
    return this.http.put(`${this.bookingApiUrl}/mps/widget/${parentType}/${siteId}`, widget);
  }

  getPayoutMethods() {
    return this.http.get(`${this.monolithApi}/earnings-reports/payout-methods`).pipe(
      map((res: { data: PayoutMethod[] }) => {
        return res.data;
      })
    );
  }

  generateEarningReport(type: 'pdf' | 'csv', payoutMethodUuid?: string) {
    const params = {
      ...(payoutMethodUuid && { payout_method_uuid: payoutMethodUuid }),
    };

    return this.http.get(`${this.monolithApi}/earnings-reports/generate-earning-report/${type}`, {
      params,
      responseType: 'blob',
      observe: 'response',
    });
  }

  leaveGuestReview(reservationUuid: string, recommended: boolean, comment: string) {
    return this.http
      .post(`${this.bookingApiUrl}/reservations/${reservationUuid}/guest-reviews`, {
        recommended,
        comment,
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(this.handleGuestReviewError)
      );
  }

  private handleGuestReviewError(err) {
    if (err.status === 400) {
      return of({
        error: true,
        message: 'You have already left a review for this reservation.',
      });
    }

    return of({
      error: true,
      message: err.error.message,
    });
  }
}
