// import { Filter, LoadingState, Pagination } from '@hospitable/shared/interfaces';
import { createReducer, on, Action } from '@ngrx/store';

import * as MetricsActions from './metrics.actions';
import {
  MetricEarlierExports,
  MetricDetailsTable,
  MetricStatistics,
  MetricGraphs,
  MetricCurrencies,
  Currency,
  MetricConversionRate,
  DataTooLargeMeta,
  MetricView,
} from './metrics.models';
import { LoadingState } from '@app/shared/interfaces';
import { Pagination } from '@app/shared/interfaces/lib/pagination.interface';
import { Filter } from '@app/shared/interfaces/lib/filter.interface';
// import { Filter } from '@app/shared/models/filter';

export const METRICS_FEATURE_KEY = 'metrics';

export interface MetricsState {
  currencies: {
    loadingState: LoadingState;
    data: MetricCurrencies | null;
  };
  updateDefaultCurrency: {
    loadingState: LoadingState;
  };
  conversionRates: {
    data: MetricConversionRate[] | null;
  };
  reservationCount: {
    loadingState: LoadingState;
    data: number | null;
  };
  exportReservationMetrics: {
    loadingState: LoadingState;
  };

  reservationViews: {
    loadingState: LoadingState;
    data: MetricView[] | null;
    meta: { pagination: Pagination } | null;
    activeViewUUID: string | null;
  };

  saveReservationView: {
    loadingState: LoadingState;
  };
  updateReservationView: {
    loadingState: LoadingState;
  };
  deleteReservationView: {
    loadingState: LoadingState;
  };

  reservationFilters: {
    loadingState: LoadingState;
    data: {
      filters: Filter[] | null;
      appliedFilters: Filter[] | null;
    };
  };

  reservationDetails: {
    loadingState: LoadingState;
    data: MetricDetailsTable[] | null;
    meta: { pagination: Pagination } | null;
  };

  reservationStatistics: {
    loadingState: LoadingState;
    data: MetricStatistics | null;
    meta?: DataTooLargeMeta | null;
  };
  reservationGraphs: {
    loadingState: LoadingState;
    data: MetricGraphs | null;
  };

  earlierExports: {
    loadingState: LoadingState;
    data: MetricEarlierExports[] | null;
    meta: { pagination: Pagination } | null;
  };
  downloadExport: {
    loadingState: LoadingState;
    data: unknown | null;
  };
  // Taxes
  taxesViews: {
    loadingState: LoadingState;
    data: MetricView[] | null;
    meta: { pagination: Pagination } | null;
    activeViewUUID: string | null;
  };

  saveTaxesView: {
    loadingState: LoadingState;
  };
  updateTaxesView: {
    loadingState: LoadingState;
  };
  deleteTaxesView: {
    loadingState: LoadingState;
  };
  taxesCount: {
    loadingState: LoadingState;
    data: number | null;
  };
  exportTaxesMetrics: {
    loadingState: LoadingState;
  };
  taxesFilters: {
    loadingState: LoadingState;
    data: {
      filters: Filter[] | null;
      appliedFilters: Filter[] | null;
    };
  };
  taxesDetails: {
    loadingState: LoadingState;
    data: MetricDetailsTable[] | null;
    meta: { pagination: Pagination } | null;
  };
  taxesSummary: {
    loadingState: LoadingState;
    data: MetricDetailsTable[] | null;
    meta: { pagination: Pagination } | null;
  };
  taxesStatistics: {
    loadingState: LoadingState;
    data: MetricStatistics | null;
    meta?: DataTooLargeMeta | null;
  };
  taxesGraphs: {
    loadingState: LoadingState;
    data: MetricGraphs | null;
  };
  // Reviews
  reviewsViews: {
    loadingState: LoadingState;
    data: MetricView[] | null;
    meta: { pagination: Pagination } | null;
    activeViewUUID: string | null;
  };

  saveReviewsView: {
    loadingState: LoadingState;
  };
  updateReviewsView: {
    loadingState: LoadingState;
  };
  deleteReviewsView: {
    loadingState: LoadingState;
  };
  reviewsCount: {
    loadingState: LoadingState;
    data: number | null;
  };
  exportReviewsMetrics: {
    loadingState: LoadingState;
  };
  reviewsFilters: {
    loadingState: LoadingState;
    data: {
      filters: Filter[] | null;
      appliedFilters: Filter[] | null;
    };
  };
  reviewsDetails: {
    loadingState: LoadingState;
    data: MetricDetailsTable[] | null;
    meta: { pagination: Pagination } | null;
  };
  reviewsStatistics: {
    loadingState: LoadingState;
    data: MetricStatistics | null;
    meta?: DataTooLargeMeta | null;
  };
  reviewsGraphs: {
    loadingState: LoadingState;
    data: MetricGraphs | null;
  };

  // Tasks
  tasksViews: {
    loadingState: LoadingState;
    data: MetricView[] | null;
    meta: { pagination: Pagination } | null;
    activeViewUUID: string | null;
  };

  saveTasksView: {
    loadingState: LoadingState;
  };
  updateTasksView: {
    loadingState: LoadingState;
  };
  deleteTasksView: {
    loadingState: LoadingState;
  };
  tasksCount: {
    loadingState: LoadingState;
    data: number | null;
  };
  exportTasksMetrics: {
    loadingState: LoadingState;
  };
  tasksFilters: {
    loadingState: LoadingState;
    data: {
      filters: Filter[] | null;
      appliedFilters: Filter[] | null;
    };
  };
  tasksDetails: {
    loadingState: LoadingState;
    data: MetricDetailsTable[] | null;
    meta: { pagination: Pagination } | null;
  };
}

export interface MetricsPartialState {
  readonly [METRICS_FEATURE_KEY]: MetricsState;
}

export const initialMetricsState: MetricsState = {
  currencies: {
    loadingState: LoadingState.NotSent,
    data: null,
  },
  updateDefaultCurrency: {
    loadingState: LoadingState.NotSent,
  },
  conversionRates: {
    data: null,
  },
  reservationCount: {
    loadingState: LoadingState.NotSent,
    data: null,
  },
  exportReservationMetrics: {
    loadingState: LoadingState.NotSent,
  },
  reservationViews: {
    loadingState: LoadingState.NotSent,
    data: null,
    meta: null,
    activeViewUUID: null,
  },
  saveReservationView: {
    loadingState: LoadingState.NotSent,
  },
  updateReservationView: {
    loadingState: LoadingState.NotSent,
  },
  deleteReservationView: {
    loadingState: LoadingState.NotSent,
  },
  reservationFilters: {
    loadingState: LoadingState.NotSent,
    data: {
      filters: null,
      appliedFilters: null,
    },
  },
  reservationDetails: {
    loadingState: LoadingState.NotSent,
    data: null,
    meta: null,
  },

  reservationStatistics: {
    loadingState: LoadingState.NotSent,
    data: null,
    meta: null,
  },
  reservationGraphs: {
    loadingState: LoadingState.NotSent,
    data: null,
  },

  earlierExports: {
    loadingState: LoadingState.NotSent,
    data: null,
    meta: null,
  },
  downloadExport: {
    loadingState: LoadingState.NotSent,
    data: null,
  },
  // Taxes
  taxesViews: {
    loadingState: LoadingState.NotSent,
    data: null,
    meta: null,
    activeViewUUID: null,
  },
  saveTaxesView: {
    loadingState: LoadingState.NotSent,
  },
  updateTaxesView: {
    loadingState: LoadingState.NotSent,
  },
  deleteTaxesView: {
    loadingState: LoadingState.NotSent,
  },
  taxesCount: {
    loadingState: LoadingState.NotSent,
    data: null,
  },
  exportTaxesMetrics: {
    loadingState: LoadingState.NotSent,
  },
  taxesFilters: {
    loadingState: LoadingState.NotSent,
    data: {
      filters: null,
      appliedFilters: null,
    },
  },
  taxesDetails: {
    loadingState: LoadingState.NotSent,
    data: null,
    meta: null,
  },
  taxesSummary: {
    loadingState: LoadingState.NotSent,
    data: null,
    meta: null,
  },
  taxesStatistics: {
    loadingState: LoadingState.NotSent,
    data: null,
    meta: null,
  },
  taxesGraphs: {
    loadingState: LoadingState.NotSent,
    data: null,
  },
  // Reviews
  reviewsViews: {
    loadingState: LoadingState.NotSent,
    data: null,
    meta: null,
    activeViewUUID: null,
  },
  saveReviewsView: {
    loadingState: LoadingState.NotSent,
  },
  updateReviewsView: {
    loadingState: LoadingState.NotSent,
  },
  deleteReviewsView: {
    loadingState: LoadingState.NotSent,
  },
  reviewsCount: {
    loadingState: LoadingState.NotSent,
    data: null,
  },
  exportReviewsMetrics: {
    loadingState: LoadingState.NotSent,
  },
  reviewsFilters: {
    loadingState: LoadingState.NotSent,
    data: {
      filters: null,
      appliedFilters: null,
    },
  },
  reviewsDetails: {
    loadingState: LoadingState.NotSent,
    data: null,
    meta: null,
  },

  reviewsStatistics: {
    loadingState: LoadingState.NotSent,
    data: null,
    meta: null,
  },
  reviewsGraphs: {
    loadingState: LoadingState.NotSent,
    data: null,
  },

  // Tasks
  tasksViews: {
    loadingState: LoadingState.NotSent,
    data: null,
    meta: null,
    activeViewUUID: null,
  },
  saveTasksView: {
    loadingState: LoadingState.NotSent,
  },
  updateTasksView: {
    loadingState: LoadingState.NotSent,
  },
  deleteTasksView: {
    loadingState: LoadingState.NotSent,
  },
  tasksCount: {
    loadingState: LoadingState.NotSent,
    data: null,
  },
  exportTasksMetrics: {
    loadingState: LoadingState.NotSent,
  },
  tasksFilters: {
    loadingState: LoadingState.NotSent,
    data: {
      filters: null,
      appliedFilters: null,
    },
  },
  tasksDetails: {
    loadingState: LoadingState.NotSent,
    data: null,
    meta: null,
  },
};

const reducer = createReducer(
  initialMetricsState,
  on(MetricsActions.loadCurrencies, (state) => ({
    ...state,
    currencies: {
      ...state.currencies,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.loadCurrenciesSuccess, (state, { response }) => ({
    ...state,
    currencies: ((response) => {
      return { loadingState: LoadingState.Success, data: response.data };
    })(response),
  })),

  on(MetricsActions.loadCurrenciesFailure, (state) => ({
    ...state,
    currencies: {
      ...state.currencies,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.updateDefaultCurrency, (state) => ({
    ...state,
    updateDefaultCurrency: {
      ...state.updateDefaultCurrency,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.updateDefaultCurrencySuccess, (state, { response }) => ({
    ...state,
    updateDefaultCurrency: {
      ...state.updateDefaultCurrency,
      loadingState: LoadingState.Success,
    },
    currencies: ((response) => {
      return {
        loadingState: LoadingState.Success,
        data: {
          currencies: state.currencies.data ? state.currencies.data.currencies : ([] as Currency[]),
          default_currency: response.data && response.data.currency ? response.data.currency : null,
        },
      };
    })(response),
  })),

  on(MetricsActions.updateDefaultCurrencyFailure, (state) => ({
    ...state,
    updateDefaultCurrency: {
      ...state.updateDefaultCurrency,
      loadingState: LoadingState.Error,
    },
  })),

  on(
    MetricsActions.loadReservationStatisticsSuccess,
    MetricsActions.loadTaxesStatisticsSuccess,
    MetricsActions.loadReviewsStatisticsSuccess,
    (state, { response }) => ({
      ...state,
      conversionRates: ((response) => {
        const conversionRates = response.data && response.data.conversion_rates ? response.data.conversion_rates : [];
        return { data: conversionRates };
      })(response),
    })
  ),

  on(MetricsActions.loadReservationCount, (state) => ({
    ...state,
    reservationCount: {
      ...state.reservationCount,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.loadReservationCountSuccess, (state, { response }) => ({
    ...state,
    reservationCount: ((response) => {
      return { loadingState: LoadingState.Success, data: response };
    })(response),
  })),

  on(MetricsActions.loadReservationCountFailure, (state) => ({
    ...state,
    reservationCount: {
      ...state.reservationCount,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.exportReservationMetrics, (state) => ({
    ...state,
    exportReservationMetrics: {
      ...state.exportReservationMetrics,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.exportReservationMetricsSuccess, (state) => ({
    ...state,
    exportReservationMetrics: {
      ...state.exportReservationMetrics,
      loadingState: LoadingState.Success,
    },
  })),

  on(MetricsActions.exportReservationMetricsFailure, (state) => ({
    ...state,
    exportReservationMetrics: {
      ...state.exportReservationMetrics,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.loadReservationViews, (state) => ({
    ...state,
    reservationViews: {
      ...state.reservationViews,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.loadReservationViewsSuccess, (state, { response }) => ({
    ...state,
    reservationViews: ((response) => {
      return {
        ...state.reservationViews,
        loadingState: LoadingState.Success,
        data: response.data,
        meta: response.meta ? response.meta : null,
      };
    })(response),
  })),

  on(MetricsActions.loadReservationViewsFailure, (state) => ({
    ...state,
    reservationViews: {
      ...state.reservationViews,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.clearReservationView, (state) => ({
    ...state,
    reservationViews: {
      ...state.reservationViews,
      activeViewUUID: null,
    },
  })),

  on(MetricsActions.switchReservationView, (state, action) => ({
    ...state,
    reservationViews: {
      ...state.reservationViews,
      activeViewUUID: action.payload,
    },
  })),

  on(MetricsActions.saveReservationView, (state) => ({
    ...state,
    saveReservationView: {
      ...state.saveReservationView,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.saveReservationViewsSuccess, (state, { response }) => ({
    ...state,
    reservationViews: ((response) => {
      return {
        ...state.reservationViews,
        loadingState: LoadingState.Success,
        data: [...(state.reservationViews.data ? state.reservationViews.data : []), response.data],
        activeViewUUID: response.data.uuid,
      };
    })(response),
    saveReservationView: {
      ...state.saveReservationView,
      loadingState: LoadingState.Success,
    },
  })),

  on(MetricsActions.saveReservationViewsFailure, (state) => ({
    ...state,
    saveReservationView: {
      ...state.saveReservationView,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.updateReservationView, (state) => ({
    ...state,
    updateReservationView: {
      ...state.updateReservationView,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.updateReservationViewSuccess, (state, { response }) => ({
    ...state,
    reservationViews: ((response) => {
      const data = state.reservationViews.data ? state.reservationViews.data : [];
      const dataWithoutView = data.filter((view) => view.uuid !== response.data.uuid);

      return {
        ...state.reservationViews,
        loadingState: LoadingState.Success,
        data: [...dataWithoutView, response.data],
      };
    })(response),
    updateReservationView: {
      ...state.updateReservationView,
      loadingState: LoadingState.Success,
    },
  })),

  on(MetricsActions.updateReservationViewFailure, (state) => ({
    ...state,
    updateReservationView: {
      ...state.updateReservationView,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.deleteReservationView, (state) => ({
    ...state,
    deleteReservationView: {
      ...state.deleteReservationView,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.deleteReservationViewSuccess, (state, { uuid, response }) => ({
    ...state,
    reservationViews: ((response) => {
      const data = state.reservationViews.data ? state.reservationViews.data : [];
      const dataWithoutView = data.filter((view) => view.uuid !== uuid);

      return {
        ...state.reservationViews,
        loadingState: LoadingState.Success,
        data: dataWithoutView,
      };
    })(response),
    deleteReservationView: {
      ...state.deleteReservationView,
      loadingState: LoadingState.Success,
    },
  })),

  on(MetricsActions.deleteReservationViewFailure, (state) => ({
    ...state,
    deleteReservationView: {
      ...state.deleteReservationView,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.loadReservationFilters, (state) => ({
    ...state,
    reservationFilters: {
      ...state.reservationFilters,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.loadReservationFiltersSuccess, (state, { response }) => ({
    ...state,
    reservationFilters: ((response) => {
      return {
        loadingState: LoadingState.Success,
        data: {
          ...state.reservationFilters.data,
          filters: response.data.filters,
        },
      };
    })(response),
  })),

  on(MetricsActions.loadReservationFiltersFailure, (state) => ({
    ...state,
    reservationFilters: {
      ...state.reservationFilters,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.addReservationFilter, (state, { filter }) => ({
    ...state,
    reservationFilters: ((filter) => {
      const appliedFilters = state.reservationFilters.data.appliedFilters ?? [];

      return {
        ...state.reservationFilters,
        data: {
          ...state.reservationFilters.data,
          appliedFilters: [
            ...appliedFilters,
            ...[
              {
                ...filter,
                ...{ recently_added: true },
              },
            ],
          ],
        },
      };
    })(filter),
  })),

  on(MetricsActions.bulkApplyReservationFilters, (state, { filters }) => ({
    ...state,
    reservationFilters: ((filters) => {

      return {
        ...state.reservationFilters,
        data: {
          ...state.reservationFilters.data,
          appliedFilters: filters,
        },
      };
    })(filters),
  })),

  on(MetricsActions.applyReservationFilter, (state, { filter }) => ({
    ...state,
    reservationFilters: ((filter) => {
      const filters = state.reservationFilters.data.appliedFilters ?? [];
      const appliedFilters = filters.filter((f) => {
        return f.key !== filter.key;
      });

      return {
        ...state.reservationFilters,
        data: {
          ...state.reservationFilters.data,
          appliedFilters: [...appliedFilters, ...[filter]],
        },
      };
    })(filter),
  })),

  on(MetricsActions.loadReservationFilterLabelSuccess, (state, { filter, response }) => ({
    ...state,
    reservationFilters: ((filter, response) => {
      const filters = state.reservationFilters.data.appliedFilters ?? [];
      const appliedFilters = filters.filter((f) => {
        return f.key !== filter.key;
      });

      const { data } = response;

      const updatedFilter: Filter = {
        ...filter,
        ...{
          initial_payload_for_humans: {
            label: data.label,
            has_payload: data.has_payload,
            values: data.values,
          },
        },
      };

      return {
        ...state.reservationFilters,
        data: {
          ...state.reservationFilters.data,
          appliedFilters: [...appliedFilters, ...[updatedFilter]],
        },
      };
    })(filter, response),
  })),

  on(MetricsActions.removeReservationFilter, (state, { filter }) => ({
    ...state,
    reservationFilters: ((filter) => {
      const filters = state.reservationFilters.data.appliedFilters ?? [];
      const appliedFilters = filters.filter((f) => {
        return f.key !== filter.key;
      });

      return {
        ...state.reservationFilters,
        data: {
          ...state.reservationFilters.data,
          appliedFilters,
        },
      };
    })(filter),
  })),

  on(MetricsActions.loadReservationDetails, (state) => ({
    ...state,
    reservationDetails: {
      ...state.reservationDetails,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.loadReservationDetailsSuccess, (state, { response }) => ({
    ...state,
    reservationDetails: ((response) => {
      return { loadingState: LoadingState.Success, data: response.data, meta: response.meta ? response.meta : null };
    })(response),
  })),

  on(MetricsActions.loadReservationDetailsFailure, (state) => ({
    ...state,
    reservationDetails: {
      ...state.reservationDetails,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.loadReservationStatistics, (state) => ({
    ...state,
    reservationStatistics: {
      ...state.reservationStatistics,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.loadReservationStatisticsSuccess, (state, { response }) => ({
    ...state,
    reservationStatistics: ((response) => {
      return { loadingState: LoadingState.Success, data: response.data, meta: response.meta };
    })(response),
  })),

  on(MetricsActions.loadReservationStatisticsFailure, (state) => ({
    ...state,
    reservationStatistics: {
      ...state.reservationStatistics,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.loadReservationGraphs, (state) => ({
    ...state,
    reservationGraphs: {
      ...state.reservationGraphs,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.loadReservationGraphsSuccess, (state, { response }) => ({
    ...state,
    reservationGraphs: ((response) => {
      return { loadingState: LoadingState.Success, data: response.data };
    })(response),
  })),

  on(MetricsActions.loadReservationGraphsFailure, (state) => ({
    ...state,
    reservationGraphs: {
      ...state.reservationGraphs,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.loadEarlierExports, (state) => ({
    ...state,
    earlierExports: {
      ...state.earlierExports,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.loadEarlierExportsSuccess, (state, { response }) => ({
    ...state,
    earlierExports: ((response) => {
      return { loadingState: LoadingState.Success, data: response.data, meta: response.meta ? response.meta : null };
    })(response),
  })),

  on(MetricsActions.loadEarlierExportsFailure, (state) => ({
    ...state,
    earlierExports: {
      ...state.earlierExports,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.downloadExport, (state) => ({
    ...state,
    downloadExport: {
      ...state.downloadExport,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.downloadExportSuccess, (state) => ({
    ...state,
    downloadExport: {
      ...state.downloadExport,
      loadingState: LoadingState.Success,
    },
  })),

  on(MetricsActions.downloadExportFailure, (state) => ({
    ...state,
    downloadExport: {
      ...state.downloadExport,
      loadingState: LoadingState.Error,
    },
  })),

  // Taxes
  on(MetricsActions.loadTaxesViews, (state) => ({
    ...state,
    taxesViews: {
      ...state.taxesViews,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.loadTaxesViewsSuccess, (state, { response }) => ({
    ...state,
    taxesViews: ((response) => {
      return {
        ...state.taxesViews,
        loadingState: LoadingState.Success,
        data: response.data,
        meta: response.meta ? response.meta : null,
      };
    })(response),
  })),

  on(MetricsActions.loadTaxesViewsFailure, (state) => ({
    ...state,
    taxesViews: {
      ...state.taxesViews,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.switchTaxesView, (state, action) => ({
    ...state,
    taxesViews: {
      ...state.taxesViews,
      activeViewUUID: action.payload,
    },
  })),

  on(MetricsActions.clearTaxesView, (state) => ({
    ...state,
    taxesViews: {
      ...state.taxesViews,
      activeViewUUID: null,
    },
  })),

  on(MetricsActions.saveTaxesView, (state) => ({
    ...state,
    saveTaxesView: {
      ...state.saveTaxesView,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.saveTaxesViewsSuccess, (state, { response }) => ({
    ...state,
    taxesViews: ((response) => {
      return {
        ...state.taxesViews,
        loadingState: LoadingState.Success,
        data: [...(state.taxesViews.data ? state.taxesViews.data : []), response.data],
        activeViewUUID: response.data.uuid,
      };
    })(response),
    saveTaxesView: {
      ...state.saveTaxesView,
      loadingState: LoadingState.Success,
    },
  })),

  on(MetricsActions.saveTaxesViewsFailure, (state) => ({
    ...state,
    saveTaxesView: {
      ...state.saveTaxesView,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.updateTaxesView, (state) => ({
    ...state,
    updateTaxesView: {
      ...state.updateTaxesView,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.updateTaxesViewSuccess, (state, { response }) => ({
    ...state,
    taxesViews: ((response) => {
      const data = state.taxesViews.data ? state.taxesViews.data : [];
      const dataWithoutView = data.filter((view) => view.uuid !== response.data.uuid);

      return {
        ...state.taxesViews,
        loadingState: LoadingState.Success,
        data: [...dataWithoutView, response.data],
      };
    })(response),
    updateTaxesView: {
      ...state.updateTaxesView,
      loadingState: LoadingState.Success,
    },
  })),

  on(MetricsActions.updateTaxesViewFailure, (state) => ({
    ...state,
    updateTaxesView: {
      ...state.updateTaxesView,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.deleteTaxesView, (state) => ({
    ...state,
    deleteTaxesView: {
      ...state.deleteTaxesView,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.deleteTaxesViewSuccess, (state, { uuid, response }) => ({
    ...state,
    taxesViews: ((response) => {
      const data = state.taxesViews.data ? state.taxesViews.data : [];
      const dataWithoutView = data.filter((view) => view.uuid !== uuid);

      return {
        ...state.taxesViews,
        loadingState: LoadingState.Success,
        data: dataWithoutView,
      };
    })(response),
    deleteTaxesView: {
      ...state.deleteTaxesView,
      loadingState: LoadingState.Success,
    },
  })),

  on(MetricsActions.deleteTaxesViewFailure, (state) => ({
    ...state,
    deleteTaxesView: {
      ...state.deleteTaxesView,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.loadTaxesCount, (state) => ({
    ...state,
    taxesCount: {
      ...state.taxesCount,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.loadTaxesCountSuccess, (state, { response }) => ({
    ...state,
    taxesCount: ((response) => {
      return { loadingState: LoadingState.Success, data: response };
    })(response),
  })),

  on(MetricsActions.loadTaxesCountFailure, (state) => ({
    ...state,
    taxesCount: {
      ...state.taxesCount,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.exportTaxesMetrics, (state) => ({
    ...state,
    exportTaxesMetrics: {
      ...state.exportTaxesMetrics,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.exportTaxesMetricsSuccess, (state) => ({
    ...state,
    exportTaxesMetrics: {
      ...state.exportTaxesMetrics,
      loadingState: LoadingState.Success,
    },
  })),

  on(MetricsActions.exportTaxesMetricsFailure, (state) => ({
    ...state,
    exportTaxesMetrics: {
      ...state.exportTaxesMetrics,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.loadTaxesFilters, (state) => ({
    ...state,
    taxesFilters: {
      ...state.taxesFilters,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.loadTaxesFiltersSuccess, (state, { response }) => ({
    ...state,
    taxesFilters: ((response) => {
      return {
        loadingState: LoadingState.Success,
        data: {
          ...state.taxesFilters.data,
          filters: response.data.filters,
        },
      };
    })(response),
  })),

  on(MetricsActions.loadTaxesFiltersFailure, (state) => ({
    ...state,
    taxesFilters: {
      ...state.taxesFilters,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.addTaxesFilter, (state, { filter }) => ({
    ...state,
    taxesFilters: ((filter) => {
      const appliedFilters = state.taxesFilters.data.appliedFilters ?? [];

      return {
        ...state.taxesFilters,
        data: {
          ...state.taxesFilters.data,
          appliedFilters: [
            ...appliedFilters,
            ...[
              {
                ...filter,
                ...{ recently_added: true },
              },
            ],
          ],
        },
      };
    })(filter),
  })),

  on(MetricsActions.bulkApplyTaxesFilters, (state, { filters }) => ({
    ...state,
    taxesFilters: ((filters) => {
    
      return {
        ...state.taxesFilters,
        data: {
          ...state.taxesFilters.data,
          appliedFilters: filters,
        },
      };
    })(filters),
  })),

  on(MetricsActions.applyTaxesFilter, (state, { filter }) => ({
    ...state,
    taxesFilters: ((filter) => {
      const filters = state.taxesFilters.data.appliedFilters ?? [];
      const appliedFilters = filters.filter((f) => {
        return f.key !== filter.key;
      });

      return {
        ...state.taxesFilters,
        data: {
          ...state.taxesFilters.data,
          appliedFilters: [...appliedFilters, ...[filter]],
        },
      };
    })(filter),
  })),

  on(MetricsActions.loadTaxesFilterLabelSuccess, (state, { filter, response }) => ({
    ...state,
    taxesFilters: ((filter, response) => {
      const filters = state.taxesFilters.data.appliedFilters ?? [];
      const appliedFilters = filters.filter((f) => {
        return f.key !== filter.key;
      });

      const { data } = response;

      const updatedFilter: Filter = {
        ...filter,
        ...{
          initial_payload_for_humans: {
            label: data.label,
            has_payload: data.has_payload,
            values: data.values,
          },
        },
      };

      return {
        ...state.taxesFilters,
        data: {
          ...state.taxesFilters.data,
          appliedFilters: [...appliedFilters, ...[updatedFilter]],
        },
      };
    })(filter, response),
  })),

  on(MetricsActions.removeTaxesFilter, (state, { filter }) => ({
    ...state,
    taxesFilters: ((filter) => {
      const filters = state.taxesFilters.data.appliedFilters ?? [];
      const appliedFilters = filters.filter((f) => {
        return f.key !== filter.key;
      });

      return {
        ...state.taxesFilters,
        data: {
          ...state.taxesFilters.data,
          appliedFilters,
        },
      };
    })(filter),
  })),

  on(MetricsActions.loadTaxesSummary, (state) => ({
    ...state,
    taxesSummary: {
      ...state.taxesSummary,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.loadTaxesSummarySuccess, (state, { response }) => ({
    ...state,
    taxesSummary: ((response) => {
      return { loadingState: LoadingState.Success, data: response.data, meta: response.meta ? response.meta : null };
    })(response),
  })),

  on(MetricsActions.loadTaxesSummaryFailure, (state) => ({
    ...state,
    taxesSummary: {
      ...state.taxesSummary,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.loadTaxesDetails, (state) => ({
    ...state,
    taxesDetails: {
      ...state.taxesDetails,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.loadTaxesDetailsSuccess, (state, { response }) => ({
    ...state,
    taxesDetails: ((response) => {
      return { loadingState: LoadingState.Success, data: response.data, meta: response.meta ? response.meta : null };
    })(response),
  })),

  on(MetricsActions.loadTaxesDetailsFailure, (state) => ({
    ...state,
    taxesDetails: {
      ...state.taxesDetails,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.loadTaxesStatistics, (state) => ({
    ...state,
    taxesStatistics: {
      ...state.taxesStatistics,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.loadTaxesStatisticsSuccess, (state, { response }) => ({
    ...state,
    taxesStatistics: ((response) => {
      return { loadingState: LoadingState.Success, data: response.data, meta: response.meta };
    })(response),
  })),

  on(MetricsActions.loadTaxesStatisticsFailure, (state) => ({
    ...state,
    taxesStatistics: {
      ...state.taxesStatistics,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.loadTaxesGraphs, (state) => ({
    ...state,
    taxesGraphs: {
      ...state.taxesGraphs,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.loadTaxesGraphsSuccess, (state, { response }) => ({
    ...state,
    taxesGraphs: ((response) => {
      return { loadingState: LoadingState.Success, data: response.data };
    })(response),
  })),

  on(MetricsActions.loadTaxesGraphsFailure, (state) => ({
    ...state,
    taxesGraphs: {
      ...state.taxesGraphs,
      loadingState: LoadingState.Error,
    },
  })),

  // Reviews
  on(MetricsActions.loadReviewsViews, (state) => ({
    ...state,
    reviewsViews: {
      ...state.reviewsViews,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.loadReviewsViewsSuccess, (state, { response }) => ({
    ...state,
    reviewsViews: ((response) => {
      return {
        ...state.reviewsViews,
        loadingState: LoadingState.Success,
        data: response.data,
        meta: response.meta ? response.meta : null,
      };
    })(response),
  })),

  on(MetricsActions.loadReviewsViewsFailure, (state) => ({
    ...state,
    reviewsViews: {
      ...state.reviewsViews,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.switchReviewsView, (state, action) => ({
    ...state,
    reviewsViews: {
      ...state.reviewsViews,
      activeViewUUID: action.payload,
    },
  })),
  on(MetricsActions.clearReviewsView, (state) => ({
    ...state,
    reviewsViews: {
      ...state.reviewsViews,
      activeViewUUID: null,
    },
  })),

  on(MetricsActions.saveReviewsView, (state) => ({
    ...state,
    saveReviewsView: {
      ...state.saveReviewsView,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.saveReviewsViewsSuccess, (state, { response }) => ({
    ...state,
    reviewsViews: ((response) => {
      return {
        ...state.reviewsViews,
        loadingState: LoadingState.Success,
        data: [...(state.reviewsViews.data ? state.reviewsViews.data : []), response.data],
        activeViewUUID: response.data.uuid,
      };
    })(response),
    saveReviewsView: {
      ...state.saveReviewsView,
      loadingState: LoadingState.Success,
    },
  })),

  on(MetricsActions.saveReviewsViewsFailure, (state) => ({
    ...state,
    saveReviewsView: {
      ...state.saveReviewsView,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.updateReviewsView, (state) => ({
    ...state,
    updateReviewsView: {
      ...state.updateReviewsView,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.updateReviewsViewSuccess, (state, { response }) => ({
    ...state,
    reviewsViews: ((response) => {
      const data = state.reviewsViews.data ? state.reviewsViews.data : [];
      const dataWithoutView = data.filter((view) => view.uuid !== response.data.uuid);

      return {
        ...state.reviewsViews,
        loadingState: LoadingState.Success,
        data: [...dataWithoutView, response.data],
      };
    })(response),
    updateReviewsView: {
      ...state.updateReviewsView,
      loadingState: LoadingState.Success,
    },
  })),

  on(MetricsActions.updateReviewsViewFailure, (state) => ({
    ...state,
    updateReviewsView: {
      ...state.updateReviewsView,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.deleteReviewsView, (state) => ({
    ...state,
    deleteReviewsView: {
      ...state.deleteReviewsView,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.deleteReviewsViewSuccess, (state, { uuid, response }) => ({
    ...state,
    reviewsViews: ((response) => {
      const data = state.reviewsViews.data ? state.reviewsViews.data : [];
      const dataWithoutView = data.filter((view) => view.uuid !== uuid);

      return {
        ...state.reviewsViews,
        loadingState: LoadingState.Success,
        data: dataWithoutView,
      };
    })(response),
    deleteReviewsView: {
      ...state.deleteReviewsView,
      loadingState: LoadingState.Success,
    },
  })),

  on(MetricsActions.deleteReviewsViewFailure, (state) => ({
    ...state,
    deleteReviewsView: {
      ...state.deleteReviewsView,
      loadingState: LoadingState.Error,
    },
  })),
  on(MetricsActions.loadReviewsCount, (state) => ({
    ...state,
    reviewsCount: {
      ...state.reviewsCount,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.loadReviewsCountSuccess, (state, { response }) => ({
    ...state,
    reviewsCount: ((response) => {
      return { loadingState: LoadingState.Success, data: response };
    })(response),
  })),

  on(MetricsActions.loadReviewsCountFailure, (state) => ({
    ...state,
    reviewsCount: {
      ...state.reviewsCount,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.exportReviewsMetrics, (state) => ({
    ...state,
    exportReviewsMetrics: {
      ...state.exportReviewsMetrics,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.exportReviewsMetricsSuccess, (state) => ({
    ...state,
    exportReviewsMetrics: {
      ...state.exportReviewsMetrics,
      loadingState: LoadingState.Success,
    },
  })),

  on(MetricsActions.exportReviewsMetricsFailure, (state) => ({
    ...state,
    exportReviewsMetrics: {
      ...state.exportReviewsMetrics,
      loadingState: LoadingState.Error,
    },
  })),
  on(MetricsActions.loadReviewsFilters, (state) => ({
    ...state,
    reviewsFilters: {
      ...state.reviewsFilters,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.loadReviewsFiltersSuccess, (state, { response }) => ({
    ...state,
    reviewsFilters: ((response) => {
      return {
        loadingState: LoadingState.Success,
        data: {
          ...state.reviewsFilters.data,
          filters: response.data.filters,
        },
      };
    })(response),
  })),

  on(MetricsActions.loadReviewsFiltersFailure, (state) => ({
    ...state,
    reviewsFilters: {
      ...state.reviewsFilters,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.addReviewsFilter, (state, { filter }) => ({
    ...state,
    reviewsFilters: ((filter) => {
      const appliedFilters = state.reviewsFilters.data.appliedFilters ?? [];

      return {
        ...state.reviewsFilters,
        data: {
          ...state.reviewsFilters.data,
          appliedFilters: [
            ...appliedFilters,
            ...[
              {
                ...filter,
                ...{ recently_added: true },
              },
            ],
          ],
        },
      };
    })(filter),
  })),

  on(MetricsActions.bulkApplyReviewsFilters, (state, { filters }) => ({
    ...state,
    reviewsFilters: ((filters) => {
      
      return {
        ...state.reviewsFilters,
        data: {
          ...state.reviewsFilters.data,
          appliedFilters: filters,
        },
      };
    })(filters),
  })),

  on(MetricsActions.applyReviewsFilter, (state, { filter }) => ({
    ...state,
    reviewsFilters: ((filter) => {
      const filters = state.reviewsFilters.data.appliedFilters ?? [];
      const appliedFilters = filters.filter((f) => {
        return f.key !== filter.key;
      });

      return {
        ...state.reviewsFilters,
        data: {
          ...state.reviewsFilters.data,
          appliedFilters: [...appliedFilters, ...[filter]],
        },
      };
    })(filter),
  })),

  on(MetricsActions.loadReviewsFilterLabelSuccess, (state, { filter, response }) => ({
    ...state,
    reviewsFilters: ((filter, response) => {
      const filters = state.reviewsFilters.data.appliedFilters ?? [];
      const appliedFilters = filters.filter((f) => {
        return f.key !== filter.key;
      });

      const { data } = response;

      const updatedFilter: Filter = {
        ...filter,
        ...{
          initial_payload_for_humans: {
            label: data.label,
            has_payload: data.has_payload,
            values: data.values,
          },
        },
      };

      return {
        ...state.reviewsFilters,
        data: {
          ...state.reviewsFilters.data,
          appliedFilters: [...appliedFilters, ...[updatedFilter]],
        },
      };
    })(filter, response),
  })),

  on(MetricsActions.removeReviewsFilter, (state, { filter }) => ({
    ...state,
    reviewsFilters: ((filter) => {
      const filters = state.reviewsFilters.data.appliedFilters ?? [];
      const appliedFilters = filters.filter((f) => {
        return f.key !== filter.key;
      });

      return {
        ...state.reviewsFilters,
        data: {
          ...state.reviewsFilters.data,
          appliedFilters,
        },
      };
    })(filter),
  })),

  on(MetricsActions.loadReviewsDetails, (state) => ({
    ...state,
    reviewsDetails: {
      ...state.reviewsDetails,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.loadReviewsDetailsSuccess, (state, { response }) => ({
    ...state,
    reviewsDetails: ((response) => {
      return { loadingState: LoadingState.Success, data: response.data, meta: response.meta ? response.meta : null };
    })(response),
  })),

  on(MetricsActions.loadReviewsDetailsFailure, (state) => ({
    ...state,
    reviewsDetails: {
      ...state.reviewsDetails,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.loadReviewsStatistics, (state) => ({
    ...state,
    reviewsStatistics: {
      ...state.reviewsStatistics,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.loadReviewsStatisticsSuccess, (state, { response }) => ({
    ...state,
    reviewsStatistics: ((response) => {
      return { loadingState: LoadingState.Success, data: response.data, meta: response.meta };
    })(response),
  })),

  on(MetricsActions.loadReviewsStatisticsFailure, (state) => ({
    ...state,
    reviewsStatistics: {
      ...state.reviewsStatistics,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.loadReviewsGraphs, (state) => ({
    ...state,
    reviewsGraphs: {
      ...state.reviewsGraphs,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.loadReviewsGraphsSuccess, (state, { response }) => ({
    ...state,
    reviewsGraphs: ((response) => {
      return { loadingState: LoadingState.Success, data: response.data };
    })(response),
  })),

  on(MetricsActions.loadReviewsGraphsFailure, (state) => ({
    ...state,
    reviewsGraphs: {
      ...state.reviewsGraphs,
      loadingState: LoadingState.Error,
    },
  })),

  // Tasks
  on(MetricsActions.loadTasksViews, (state) => ({
    ...state,
    tasksViews: {
      ...state.tasksViews,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.loadTasksViewsSuccess, (state, { response }) => ({
    ...state,
    tasksViews: ((response) => {
      return {
        ...state.tasksViews,
        loadingState: LoadingState.Success,
        data: response.data,
        meta: response.meta ? response.meta : null,
      };
    })(response),
  })),

  on(MetricsActions.loadTasksViewsFailure, (state) => ({
    ...state,
    tasksViews: {
      ...state.tasksViews,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.switchTasksView, (state, action) => ({
    ...state,
    tasksViews: {
      ...state.tasksViews,
      activeViewUUID: action.payload,
    },
  })),

  on(MetricsActions.clearTasksView, (state) => ({
    ...state,
    tasksViews: {
      ...state.tasksViews,
      activeViewUUID: null,
    },
  })),

  on(MetricsActions.saveTasksView, (state) => ({
    ...state,
    saveTasksView: {
      ...state.saveTasksView,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.saveTasksViewsSuccess, (state, { response }) => ({
    ...state,
    tasksViews: ((response) => {
      return {
        ...state.tasksViews,
        loadingState: LoadingState.Success,
        data: [...(state.tasksViews.data ? state.tasksViews.data : []), response.data],
        activeViewUUID: response.data.uuid,
      };
    })(response),
    saveTasksView: {
      ...state.saveTasksView,
      loadingState: LoadingState.Success,
    },
  })),

  on(MetricsActions.saveTasksViewsFailure, (state) => ({
    ...state,
    saveTasksView: {
      ...state.saveTasksView,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.updateTasksView, (state) => ({
    ...state,
    updateTasksView: {
      ...state.updateTasksView,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.updateTasksViewSuccess, (state, { response }) => ({
    ...state,
    tasksViews: ((response) => {
      const data = state.tasksViews.data ? state.tasksViews.data : [];
      const dataWithoutView = data.filter((view) => view.uuid !== response.data.uuid);

      return {
        ...state.tasksViews,
        loadingState: LoadingState.Success,
        data: [...dataWithoutView, response.data],
      };
    })(response),
    updateTasksView: {
      ...state.updateTasksView,
      loadingState: LoadingState.Success,
    },
  })),

  on(MetricsActions.updateTasksViewFailure, (state) => ({
    ...state,
    updateTasksView: {
      ...state.updateTasksView,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.deleteTasksView, (state) => ({
    ...state,
    deleteTasksView: {
      ...state.deleteTasksView,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.deleteTasksViewSuccess, (state, { uuid, response }) => ({
    ...state,
    tasksViews: ((response) => {
      const data = state.tasksViews.data ? state.tasksViews.data : [];
      const dataWithoutView = data.filter((view) => view.uuid !== uuid);

      return {
        ...state.tasksViews,
        loadingState: LoadingState.Success,
        data: dataWithoutView,
      };
    })(response),
    deleteTasksView: {
      ...state.deleteTasksView,
      loadingState: LoadingState.Success,
    },
  })),

  on(MetricsActions.deleteTasksViewFailure, (state) => ({
    ...state,
    deleteTasksView: {
      ...state.deleteTasksView,
      loadingState: LoadingState.Error,
    },
  })),
  on(MetricsActions.loadTasksCount, (state) => ({
    ...state,
    tasksCount: {
      ...state.tasksCount,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.loadTasksCountSuccess, (state, { response }) => ({
    ...state,
    tasksCount: ((response) => {
      return { loadingState: LoadingState.Success, data: response };
    })(response),
  })),

  on(MetricsActions.loadTasksCountFailure, (state) => ({
    ...state,
    tasksCount: {
      ...state.tasksCount,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.exportTasksMetrics, (state) => ({
    ...state,
    exportTasksMetrics: {
      ...state.exportTasksMetrics,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.exportTasksMetricsSuccess, (state) => ({
    ...state,
    exportTasksMetrics: {
      ...state.exportTasksMetrics,
      loadingState: LoadingState.Success,
    },
  })),

  on(MetricsActions.exportTasksMetricsFailure, (state) => ({
    ...state,
    exportTasksMetrics: {
      ...state.exportTasksMetrics,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.loadTasksFilters, (state) => ({
    ...state,
    tasksFilters: {
      ...state.tasksFilters,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.loadTasksFiltersSuccess, (state, { response }) => ({
    ...state,
    tasksFilters: ((response) => {
      return {
        loadingState: LoadingState.Success,
        data: {
          ...state.tasksFilters.data,
          filters: response.data.filters,
        },
      };
    })(response),
  })),

  on(MetricsActions.loadTasksFiltersFailure, (state) => ({
    ...state,
    tasksFilters: {
      ...state.tasksFilters,
      loadingState: LoadingState.Error,
    },
  })),

  on(MetricsActions.addTasksFilter, (state, { filter }) => ({
    ...state,
    tasksFilters: ((filter) => {
      const appliedFilters = state.tasksFilters.data.appliedFilters ?? [];

      return {
        ...state.tasksFilters,
        data: {
          ...state.tasksFilters.data,
          appliedFilters: [
            ...appliedFilters,
            ...[
              {
                ...filter,
                ...{ recently_added: true },
              },
            ],
          ],
        },
      };
    })(filter),
  })),

  on(MetricsActions.bulkApplyTasksFilters, (state, { filters }) => ({
    ...state,
    tasksFilters: ((filters) => {
     
      return {
        ...state.tasksFilters,
        data: {
          ...state.tasksFilters.data,
          appliedFilters: filters,
        },
      };
    })(filters),
  })),

  on(MetricsActions.applyTasksFilter, (state, { filter }) => ({
    ...state,
    tasksFilters: ((filter) => {
      const filters = state.tasksFilters.data.appliedFilters ?? [];
      const appliedFilters = filters.filter((f) => {
        return f.key !== filter.key;
      });

      return {
        ...state.tasksFilters,
        data: {
          ...state.tasksFilters.data,
          appliedFilters: [...appliedFilters, ...[filter]],
        },
      };
    })(filter),
  })),

  on(MetricsActions.loadTasksFilterLabelSuccess, (state, { filter, response }) => ({
    ...state,
    tasksFilters: ((filter, response) => {
      const filters = state.tasksFilters.data.appliedFilters ?? [];
      const appliedFilters = filters.filter((f) => {
        return f.key !== filter.key;
      });

      const { data } = response;

      const updatedFilter: Filter = {
        ...filter,
        ...{
          initial_payload_for_humans: {
            label: data.label,
            has_payload: data.has_payload,
            values: data.values,
          },
        },
      };

      return {
        ...state.tasksFilters,
        data: {
          ...state.tasksFilters.data,
          appliedFilters: [...appliedFilters, ...[updatedFilter]],
        },
      };
    })(filter, response),
  })),

  on(MetricsActions.removeTasksFilter, (state, { filter }) => ({
    ...state,
    tasksFilters: ((filter) => {
      const filters = state.tasksFilters.data.appliedFilters ?? [];
      const appliedFilters = filters.filter((f) => {
        return f.key !== filter.key;
      });

      return {
        ...state.tasksFilters,
        data: {
          ...state.tasksFilters.data,
          appliedFilters,
        },
      };
    })(filter),
  })),

  on(MetricsActions.loadTasksDetails, (state) => ({
    ...state,
    tasksDetails: {
      ...state.tasksDetails,
      loadingState: LoadingState.Pending,
    },
  })),

  on(MetricsActions.loadTasksDetailsSuccess, (state, { response }) => ({
    ...state,
    tasksDetails: ((response) => {
      return { loadingState: LoadingState.Success, data: response.data, meta: response.meta ? response.meta : null };
    })(response),
  })),

  on(MetricsActions.loadTasksDetailsFailure, (state) => ({
    ...state,
    tasksDetails: {
      ...state.tasksDetails,
      loadingState: LoadingState.Error,
    },
  }))
);

export function metricsReducer(state: MetricsState | undefined, action: Action) {
  return reducer(state, action);
}
