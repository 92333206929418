import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BadgeMonoComponent } from './badge.component';

@NgModule({
  imports: [CommonModule],
  declarations: [BadgeMonoComponent],
  exports: [BadgeMonoComponent],
})
export class BadgeMonoComponentModule { }
